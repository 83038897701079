import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import {
  changeStatePqrsd,
  getAvaliablesActionsPqrsd,
  addObservationPqrsd,
  addFilesPqrs,
} from '../../../api/pqrs';
import {
  PqrsdAvaliableActionsDto,
  ChangeStatusPqrsdDto,
  CreateObservationDto,
} from '../../../api/pqrs/types';
import { EnumPqrsdConditions } from '../../../constants/PqrsdConditions';
import { delay } from '../../../utilitys/delay';
import GiveupAction from './giveupAction';
import ExtensionDaysAction from './extensionDaysAction';
import AddInformationAction from './addInformationAction';
import RequestAppealForReversalAction from './requestAppealForReversalAction';
import { useTranslation } from 'react-i18next';

const usteStyles = makeStyles((theme) => ({
  colorPrimary: {
    color: theme.palette.primary.main,
  },
}));

interface IActionsPqrsdProps {
  idDocument: string;
  onLoading: (isLoading: boolean) => void;
  onShowMessage: (isError: boolean) => void;
  reloadInformation: () => void;
  reload: number;
}

const ActionsPqrsd = ({
  idDocument,
  onLoading,
  onShowMessage,
  reloadInformation,
  reload,
}: IActionsPqrsdProps) => {
  const classes = usteStyles();
  const [actions, setActions] = useState<PqrsdAvaliableActionsDto>();
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const result = await getAvaliablesActionsPqrsd(idDocument);
      setActions(result);
    };
    fetch();
  }, [idDocument, reload]);

  const onGiveUp = async () =>
    await changeState(EnumPqrsdConditions.VOLUNTARY_RETIREMENT);

  const onAddDays = async (days: number) =>
    await changeState(EnumPqrsdConditions.ADD_CITIZEN_EXTENSION_DAYS, days);

  const onAddInformation = async (files: File[], observation: string) => {
    onLoading(true);
    const result = await addObservationPqrsd({
      documentId: idDocument,
      observation: observation,
    } as CreateObservationDto);
    onLoading(false);

    if (!result) {
      onShowMessage(true);
      return;
    }
    await changeState(EnumPqrsdConditions.PENDING_INFORMATION_DATE)
    addFilesPqrs(idDocument, files);
    onShowMessage(false);
    await delay(2000);
    reloadInformation();
  };

  const changeState = async (condition: EnumPqrsdConditions, days?: number) => {
    onLoading(true);
    const result = await changeStatePqrsd({
      documentId: idDocument,
      conditionalCode: condition,
      daysAdded: days,
    } as ChangeStatusPqrsdDto);
    onLoading(false);
    if (!result) {
      onLoading(false);
      onShowMessage(true);
      return;
    }
    onShowMessage(false);
    await delay(2000);
    reloadInformation();
  };

  const showActions =
    actions &&
    (actions.canAddExtensionTime ||
      actions.canGiveUp ||
      actions.canCorrectInformation ||
      actions.canRequestRecourse);

  return (
    <div className='p-5'>
      {showActions && (
        <Fragment>
          <div className='flex justify-center'>
            <span className={clsx(classes.colorPrimary, 'text-2xl font-bold')}>
              {t('pages.pqrs.actions.title')}
            </span>
          </div>

          <div className='flex justify-center space-x-3 mt-5'>
            {actions?.canAddExtensionTime && (
              <ExtensionDaysAction addDays={onAddDays} />
            )}
            {actions?.canGiveUp && <GiveupAction onGiveUp={onGiveUp} />}
            {actions?.canCorrectInformation && (
              <AddInformationAction addInformation={onAddInformation} />
            )}
            {actions?.canRequestRecourse && <RequestAppealForReversalAction />}
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default ActionsPqrsd;
