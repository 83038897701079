import { useState } from 'react';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, DialogTitle, makeStyles, Typography, Icon } from '@material-ui/core';
import { ButtonCaptcha, Loading, TextField } from '../../components';
import { useTranslation } from 'react-i18next';
import useValidator from '../../hooks/validation/useValidator/useValidator';
import { LoginVUVApi } from '../../api/account';
import { handleOnChangeValueForm } from '../../utilitys/makeChange';
import { ILoginState } from './types';
import { validations } from './validations';
import { delay } from '../../utilitys/delay';
import { Link, useLocation } from 'react-router-dom';
import RouteNavigation from '../../constants/RouteNavigation';
import { ErrorDisplays } from '..';
import { IErrorApiBase } from '../../api/_base/types';
import { TypeTextField } from '../../components/TextField';
import { useHistory } from 'react-router-dom';

const useStyle = makeStyles((theme) => ({
  backgroundPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
  },
}));

interface ILoginModalProps {
  open: boolean;
  onClose: () => void;
  onChangeStatusAuth: (token: string) => void;
  disableBackdropClick: boolean;
}
const initalState = {
  nickName: '',
  password: '',
};

const LoginModal = ({
  open,
  onClose,
  onChangeStatusAuth,
  disableBackdropClick
}: ILoginModalProps) => {
  const classes = useStyle();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorValidation, setErrorValidation] = useState<
    IErrorApiBase[] | undefined
  >();
  const [stateLogin, setStateLogin] = useState<ILoginState>(initalState);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const handleOnSaved = async () => {
    setIsLoading(true);

    const [suceess, result] = await LoginVUVApi({
      nickName: stateLogin.nickName,
      password: stateLogin.password,
    });

     await delay(2000);

    if (suceess) {
      setStateLogin(initalState);
      onClose();
      onChangeStatusAuth(result as string);
      if (location.pathname.toLocaleLowerCase() === `/${RouteNavigation.FocerLogin.toLocaleLowerCase()}`) {
        history.goBack();
      } else {
        window.location.replace(window.location.origin);
      }
    } else {
      setErrorValidation(result as IErrorApiBase[]);
    }
    setIsLoading(false);
  };

  const handleOnClose = () => {
    setStateLogin(initalState);
    onClose();
  }

  const [stateFormError, startValidations] = useValidator<ILoginState>(
    handleOnSaved
  );

  const Copyright = () => {
    return (
      <Typography variant='body2' color='textPrimary' align='center'>
        {t('copyright')} {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  };

  const handleOnChange = (nameStateProp: string) => (value: string | Date) => {
    const newState = handleOnChangeValueForm<ILoginState>(
      nameStateProp,
      value,
      stateLogin
    );
    setStateLogin(newState);
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOnClose}
        disableBackdropClick={disableBackdropClick}
      >
        <DialogTitle
          className={clsx(classes.backgroundPrimary, 'text-center font-bold')}
        >
          {
            <>
              {t('login.title')}
              <div
                className="absolute top-1 right-1 cursor-pointer text-white hover:text-red-300"
                onClick={() => handleOnClose()}
              >
                <Icon>cancel</Icon>
              </div>
            </>
          }
        </DialogTitle>
        <DialogContent className='flex flex-col m-8'>
          <form noValidate className='space-y-3'>
            <TextField
              isRequired
              label={t('login.nickName')}
              isOutlined
              autoComplete='nickName'
              value={stateLogin.nickName}
              error={stateFormError?.nickName}
              onChange={handleOnChange('nickName')}
            />
            <TextField
              isOutlined
              isRequired
              label={t('login.password')}
              type={TypeTextField.password}
              value={stateLogin.password}
              error={stateFormError?.password}
              onChange={handleOnChange('password')}
              autoComplete='current-password'
            />
            <ButtonCaptcha
              onClick={() => startValidations(stateLogin, validations)}
              textButton={t('login.signIn')}
              fullWidth
            />
            <div className='flex justify-center underline font-body'>
              <Link
                onClick={() => handleOnClose()}
                to={`/${RouteNavigation.UserRegister}`}
              >
                {t('login.userRegister')}
              </Link>
            </div>
            <div className='flex justify-center underline font-body'>
              <Link
                onClick={() => handleOnClose()}
                to={`/${RouteNavigation.RecoverPassword}`}
              >
                {t('login.recoverPassword')}
              </Link>
            </div>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </DialogContent>
        <Loading loading={isLoading} />
      </Dialog>
      <ErrorDisplays errors={errorValidation} module='useRegister' />
    </div>
  );
};

export default LoginModal;
