import React, { useState, useEffect } from 'react';
import { PagePrincipal } from '../../containers';
import { useTranslation } from 'react-i18next';
import { getManaulsByLangApi } from '../../api/manuals';
import { ManualDto } from '../../dto/admin/types';
import Panel from './panel';

const Manuals = () => {
  const { t, i18n } = useTranslation();
  const [manuals, setManuals] = useState<ManualDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
      const result = await getManaulsByLangApi();
      setManuals(result);
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <PagePrincipal title={t('pages.manuals.title')}>
      <div className='flex flex-col justify-center space-y-5 md:flex-row p-0 xl:pl-12 space-x-5 lg:space-y-0'>
        <div>
          <Panel manuals={manuals} />
        </div>
      </div>
    </PagePrincipal>
  );
};

export default Manuals;
