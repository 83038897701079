import clsx from 'clsx';
import { Drawer, makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { UserNavbarHeader } from '../..';
import MenuOptions from '../HeaderVertical/menu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    padding: 0,
    margin: 0,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    // height: '100vh',
  },
  contentMobile: {
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  bodyChildren: {
    // height: '80vh',
  },
}));

interface IDrawerMenuProps {
  isOpen: boolean;
  isMobile: boolean;
  onCloseDrawer: () => void;
  children?: React.ReactNode;
  showUserNavbar?: boolean;
  onChangeStatusAuth: (token: string) => void;
}
const DrawerMenu = ({
  showUserNavbar,
  isOpen,
  isMobile,
  onCloseDrawer,
  children,
  onChangeStatusAuth
}: IDrawerMenuProps) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Drawer
        className={classes.drawer}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor='left'
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={onCloseDrawer}
      >
        {showUserNavbar && <UserNavbarHeader onChangeStatusAuth={onChangeStatusAuth} />}
        <div className={classes.drawerHeader}></div>
        <MenuOptions />
      </Drawer>
      {children && (
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: isOpen,
            [classes.contentMobile]: isMobile,
          })}
        >
          <div className={classes.drawerHeader} />
          <div className={classes.bodyChildren}>{children}</div>
        </main>
      )}
    </Fragment>
  );
};
export default DrawerMenu;
