import { PolicyDto } from '../../dto/admin/types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy, uniqueId } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { DangerouslySetInnerHTML } from '../../components';
import { getUrl } from '../../utilitys/url';

interface IPanelProp {
  politics: PolicyDto[];
}

const Panel = ({ politics }: IPanelProp) => {
  const { t } = useTranslation();

  const onDownloadPdf = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Fragment>
      {sortBy(politics, (p) => p.order).map((p) => (
        <div key={uniqueId()} className='m-7'>
          <Accordion key={p.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1bh-content${p.id}`}
              id={`panel1bh-header${p.id}`}
            >
              <div className='w-full flex justify-between '>
                <div className='font-bold text-xl w-full text-center font-title'>
                  {p.title}
                </div>
                {p.document && (
                  <Tooltip
                    title={
                      <Typography variant='body2'>
                        {t('pages.normativityOffice.tooltipPdf')}
                      </Typography>
                    }
                    placement='top'
                  >
                    <div
                      className='space-x-3 flex text-red-700'
                      onClick={() => onDownloadPdf(getUrl(p.document))}
                    >
                      <PictureAsPdfIcon fontSize='large' />
                    </div>
                  </Tooltip>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='mt-1 w-full flex flex-col p-20 pt-0 font-body'>
                <span className='text-lg font-bold mt-3'>
                  {t('pages.normativityOffice.labelType')}:
                </span>
                <span className='text-lg'>{p.type}</span>
                <span className='text-lg font-bold mt-3'>
                  {t('pages.normativityOffice.labelDescription')}
                </span>
                <br />
                <DangerouslySetInnerHTML text={p.description} />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </Fragment>
  );
};
export default Panel;
