import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';

import { getUrl } from '../utilitys/url';
import { DocumentInfoDto } from '../dto/Process';

interface IProcessDocumentsProps {
    documentsUrl: DocumentInfoDto[];
}

const ProcessDocuments = ({ documentsUrl } : IProcessDocumentsProps) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const onClickMenu = async (doc: string) => {
        window.open(getUrl(doc), '_blank');
        handleClose();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <Button
                onClick={ handleMenu }
                variant='contained'
                color='secondary'
                endIcon={<InsertDriveFileIcon />}
            >
                {t('pages.processHome.attachement')}
            </Button>
            <Menu
                id='menu-docs'
                anchorEl={ anchorEl }
                getContentAnchorEl={ null }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                open={open}
                onClose={ handleClose }
                keepMounted
            >
            {
                documentsUrl.map(docInfo => {
                    return (
                        <MenuItem
                            key={docInfo.originalFileName}
                            onClick={() => onClickMenu(docInfo.url) }
                        >
                            <Typography variant='inherit'>{docInfo.originalFileName}</Typography>
                        </MenuItem>
                    )
                })
            }
            </Menu>
        </Fragment>
    )
}

export default ProcessDocuments;
