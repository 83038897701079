import { getLocalStorage } from '../../utilitys/localStorage';
import { STORAGE_TOKEN } from '../constants';
import { IErrorApi, IResponseApi } from './types';
import fileDownload from 'js-file-download';

const buildHeader = () => {
  const token = getLocalStorage<string>(STORAGE_TOKEN);
  return {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : '',
    'Access-Control-Allow-Origin': '*',
  };
};

const buildHeaderWithOutContentType = () => {
  const token = getLocalStorage<string>(STORAGE_TOKEN);
  return {
    Authorization: token ? `Bearer ${token}` : '',
    'Access-Control-Allow-Origin': '*',
  };
};

export const postData = async <T, M>(
  url: string,
  data: T
): Promise<IResponseApi<M>> => {
  try {
    let result: M;

    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: buildHeader(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return buildError(response);
    }

    result = (await response.json()) as M;
    return { hasError: false, data: result } as IResponseApi<M>;
  } catch (error) {
    return buildError(error);
  }
};

export const postFormData = async <M>(
  url: string,
  data: FormData
): Promise<IResponseApi<M>> => {
  try {
    let result: M;
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: buildHeaderWithOutContentType(),
      body: data,
    });

    if (!response.ok) {
      return buildError(response);
    }

    result = (await response.json()) as M;
    return { hasError: false, data: result } as IResponseApi<M>;
  } catch (error) {
    return buildError(error);
  }
};

export const postDataWithOutResponse = async <T>(
  url: string,
  data: T
): Promise<IResponseApi<boolean>> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: buildHeader(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return buildError(response);
    }

    return { hasError: false, data: true } as IResponseApi<boolean>;
  } catch (error) {
    return buildError(error);
  }
};

export const putData = async <T, M>(
  url: string,
  data: T
): Promise<IResponseApi<M>> => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      cache: 'no-cache',
      headers: buildHeader(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return buildError(response);
    }

    const result = (await response.json()) as M;

    return { hasError: false, data: result } as IResponseApi<M>;
  } catch (error) {
    return buildError(error);
  }
};

export const putDataWithOutResponse = async <T>(
  url: string,
  data: T
): Promise<IResponseApi<boolean>> => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      cache: 'no-cache',
      headers: buildHeader(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return buildError(response);
    }

    return { hasError: false, data: true } as IResponseApi<boolean>;
  } catch (error) {
    return buildError(error);
  }
};

export const getData = async <T>(url: string): Promise<IResponseApi<T>> => {
  try {
    let result: T;

    const response = await fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      headers: buildHeader(),
    });

    if (!response.ok) {
      return buildError(response);
    }
    result = (await response.json()) as T;
    return { hasError: false, data: result } as IResponseApi<T>;
  } catch (error) {
    return buildError(error);
  }
};

export const getFile = async(url: string,  data: any, fileName: string): Promise<IResponseApi<boolean>> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: buildHeader(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return buildError(response);
    }
    
    const blob = (await response.blob()) as File;
    fileDownload(blob, fileName);

    return { hasError: false, data: true } as IResponseApi<boolean>;

  } catch (error) {
    return buildError(error);
  }
};

const buildError = async <T>(response: any): Promise<IResponseApi<T>> => {
  try {
    if (response.status === 400) {
      const errorModel = (await response.json()) as IErrorApi;
      return {
        hasError: true,
        error: errorModel,
        data: undefined,
      } as IResponseApi<T>;
    }
    return {
      hasError: true,
      error: undefined,
      data: undefined,
    } as IResponseApi<T>;
  } catch (error) {
    console.error(error);
    return {
      hasError: true,
      error: undefined,
      data: undefined,
    } as IResponseApi<T>;
  }
};
