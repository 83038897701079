import RouteNavigation from '../constants/RouteNavigation';
import { IUserDtoJwt } from '../dto/UserDto';
import { INavigationItem } from '../navigation/types';
import { RoutesApp } from '../routes';

export const hasUserAccess = (
  url: string,
  userAuth: IUserDtoJwt | null | undefined
): boolean => {
  if (
    url === `/${RouteNavigation.NoAccess.toLocaleLowerCase()}` ||
    url === `/${RouteNavigation.FocerLogin.toLocaleLowerCase()}` ||
    url === `/${RouteNavigation.UserChangePassword.toLocaleLowerCase()}`
  ) {
    return true;
  }

  const route = getRouteAccess(url);
  if (!route) return false;
  else if (route.allowAnonymous) return true;

  const userRoles: string[] = userAuth?.roles || [];
  const userPage: string[] = route?.rolesAccesss || [];

  const withAcces = userPage.some((r) => userRoles.includes(r));
  if (withAcces) return true;
  else return false;
};

export const hasUserAccessMenu = (
  menuItem: INavigationItem,
  userAuth: IUserDtoJwt | null | undefined
): boolean => {
  if (menuItem.allowAnonymous) return true;
  const userRoles: string[] = userAuth?.roles || [];
  const userPage: string[] = menuItem?.rolesAccesss || [];

  const withAcces = userPage.some((r) => userRoles.includes(r));
  if (withAcces) return true;
  else return false;
};

const getRouteAccess = (url: string) => {
  if (url === RouteNavigation.Home) {
    return RoutesApp.find((p) => p.url === `${url}`);
  }
  var route = RoutesApp.find((p) => `/${p.url}` === `${url}`);
  if (route) return route;
  return gerRoutesAppBasic().find((p) => `${url}`.startsWith(`/${p.url}`));
};

const gerRoutesAppBasic = () => {
  const routes = [...RoutesApp];
  const basics = routes.map((p) => {
    const _current = { ...p };
    _current.url = p.url.split('/:')[0];
    return _current;
  });

  return basics;
};
