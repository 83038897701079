import React, { memo, forwardRef, useImperativeHandle, useState } from 'react';
import { TextField } from '../../components';
import { TypeTextField } from '../../components/TextField';
import {
  HandleContractMetaDataChild,
  ResultContractMetaDataChild,
  TypeControl,
} from './types';

interface ITextFieldMemoProps {
  ref: any;
  id: number;
  label?: string;
  isRequired?: boolean;
  error?: string;
  value?: string | Date | number;
  type?: TypeTextField;
  autoFocus?: boolean;
  isOutlined?: boolean;
  maxLength?: number;
  textHelp?: string;
}

const TextFieldMemo = memo(
  forwardRef(
    (
      props: ITextFieldMemoProps,
      ref: React.Ref<HandleContractMetaDataChild | undefined>
    ) => {
      const { isRequired, type, label, value, error, autoFocus, id, isOutlined, maxLength, textHelp } = props;
      const [valueLocal, setValueLocal] = useState<
        string | Date | number | undefined
      >(value ? value : '');

      const onChange = (value: string) => {
        setValueLocal(value);
      };

      useImperativeHandle(ref, () => ({
        getData() {
          return {
            id,
            value: valueLocal,
            text: '',
            typeElement: TypeControl.text,
            label: label,
          } as ResultContractMetaDataChild;
        },
      }));
      return (
        <TextField
          isOutlined={isOutlined}
          isRequired={isRequired}
          type={type}
          label={label}
          value={valueLocal}
          error={error}
          autoFocus={autoFocus}
          onChange={onChange}
          maxLength={maxLength}
          textHelp={textHelp}
        />
      );
    }
  )
);

export default TextFieldMemo;
