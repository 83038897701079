import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Container, Paper, Typography } from '@material-ui/core';
import { DocumentMetaDataValues, PagePrincipal } from '../../../containers';
import { Alert, Loading } from '../../../components';
import { useTranslation } from 'react-i18next';
import {
  getInformationDocument,
  getInformationDocumentAccesCode,
} from '../../../api/pqrs';
import { DocumentInformationDto } from '../../../dto/DocumentInformationDto';
import DownLoadDocuments from '../downLoadDocuments';
import Observations from '../observations';
import ActionsPqrsd from '../actions';
import { IAlertTypes } from '../../../components/Alert';
import { PqrsdBasicDto } from '../../../api/pqrs/types';
import { getPqrsdByDocId } from '../../../api/pqrs/index';
import { typeDocument } from '../../portfolio/myDocuments/types';
import { userContext } from '../../../hooks/contexts/userContext';
import RouteNavigation from '../../../constants/RouteNavigation';

interface IParams {
  document: string;
  accessCode?: string;
}

const PqrsTrackingDocument = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlertError, setShowAlertError] = useState<boolean>(false);
  const [documentInformation, setDocumentInformation] =
    useState<DocumentInformationDto | undefined>();
  const { document, accessCode } = useParams<IParams>();
  const [reload, setReload] = useState<number>(0);
  const [ pqrsd, setPqrsd ] = useState<PqrsdBasicDto | undefined>();
  const { userAuth } = useContext(userContext);
  const history = useHistory();

  const getInformation = useCallback(async () => {
    setLoading(true);
    let result: DocumentInformationDto | undefined;
    if (accessCode){
      result = await getInformationDocumentAccesCode(document, accessCode);
    } else {
      if (!userAuth) {
        history.push(`/${RouteNavigation.pqrsTracking}`);
        return;
      }
      result = await getInformationDocument(document);
    }
    setDocumentInformation(result);

    if (result?.docIdEncrypted){
      let resultPqrsd = await getPqrsdByDocId(result.docIdEncrypted);
      if (userAuth && !accessCode){
        if (resultPqrsd && resultPqrsd.creatorExternalUser !== userAuth.id) {
          history.push(`/${RouteNavigation.pqrsTracking}`);
          return;
        }
      }
      setPqrsd(resultPqrsd);
    }

    setLoading(false);
  }, [accessCode, document, userAuth, history]);

  useEffect(() => {
    getInformation();
  }, [getInformation, i18n.language]);

  const onSetAlertMessage = (isError: boolean) => {
    if (isError) setShowAlertError(true);
    else setShowAlert(true);
  };

  const reloadInformation = () => {
    getInformation();
    setReload(new Date().getTime());
  };

  return (
    <div>
      <PagePrincipal>
        <Container component={Paper}>
          <div className='pt-5 pb-5'>
            <div className='text-center space-y-4 mb-4'>
              <Typography variant='h4' color='primary'>
                {document}
              </Typography>
              {pqrsd?.codeState && (
                <Typography variant='h6' color='primary'>
                  {t('pages.pqrs.tracking.labelStatePqrs')}:{' '}
                  <span className='italic font-bold'>
                    {t(`pqrsState.${pqrsd?.codeState}`)}
                  </span>
                </Typography>
              )}
            </div>
          </div>
          <div className='pt-5 pb-5'>
            <div className='mb-4 flex justify-between'>
              {documentInformation?.docId && (
                <Fragment>
                  <div>
                    <Observations
                      docId={documentInformation.docIdEncrypted!}
                      documentNumber={documentInformation.documentNumber}
                      reload={reload}
                    />
                  </div>
                  <div>
                    <DownLoadDocuments
                      docId={documentInformation.docIdEncrypted!}
                      documentNumber={documentInformation.documentNumber}
                      accessCode={accessCode}
                      reload={reload}
                      type={typeDocument.pqrs}
                    />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2 pb-16'>
            {documentInformation?.data && (
              <DocumentMetaDataValues metaData={documentInformation.data} />
            )}
          </div>
          {documentInformation && (
            <ActionsPqrsd
              idDocument={documentInformation.docIdEncrypted}
              onLoading={(isLoading) => {
                setLoading(isLoading);
              }}
              onShowMessage={onSetAlertMessage}
              reloadInformation={reloadInformation}
              reload={reload}
            />
          )}
        </Container>
      </PagePrincipal>
      <Alert
        open={showAlert}
        type={IAlertTypes.success}
        onClickClose={() => {
          setShowAlert(false);
        }}
      >
        <span className='text-lg'>
          {t('pages.pqrs.actions.messageSuccess')}
        </span>
      </Alert>
      <Alert
        open={showAlertError}
        type={IAlertTypes.error}
        onClickClose={() => {
          setShowAlertError(false);
        }}
      >
        <span className='text-lg'>
          {t('pages.pqrs.actions.messageError')}
        </span>
      </Alert>
      <Loading loading={loading} />
    </div>
  );
};
export default PqrsTrackingDocument;
