import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { isUrlInternal } from '../../../utilitys/url';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  textColorPrimary: {
    color: theme.palette.text.primary,
  },
  textColorSecondary: {
    color: theme.palette.secondary.main,
  },
}));

interface ITitleProps {
  title: string;
  url?: string;
  center?: boolean;
}

const Title = ({ title, url, center }: ITitleProps) => {
  const classes = useStyles();
  const history = useHistory();

  const redirect = () => {
    if (isUrlInternal(url!)) history.push(url!);
    else  window.open(url, '_blank');
  };

  return (
    <div
      className={clsx(
        classes.textColorSecondary,
        `text-3xl py-5 font-bold font-title ${center ? 'text-center' : ''}`
      )}
    >
      {url ? (
        <span className='underline cursor-pointer' onClick={redirect}>
          {title}
        </span>
      ) : (
        <p>{title}</p>
      )}
        </div>
  );
};
export default Title;
