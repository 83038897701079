import { IJwtDto } from '../dto/jwtDto';

export const parseJwt = (token: string) : IJwtDto => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  var dto = JSON.parse(jsonPayload) as IJwtDto; 
  var parse = JSON.parse(jsonPayload) as any;
  dto.mustChangePassword = parse.mustChangePassword === 'True' || parse.mustChangePassword === 'true';
  return dto
};
