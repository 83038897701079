import { Steppers } from '../../../containers';
import React from 'react';
import { IUseValidatorResult } from '../../../hooks/validation/useValidator/useValidator';
import { useTranslation } from 'react-i18next';
import { ContactMetaDataState } from '../../../containers/ContactMetaData/types';
import { IValidatorField } from '../../../hooks/validation/useValidator/types';
import BasicInformation from '../common/basicInformation';
import GeneralInformation from '../common/generalInformation';
import MetaDataInformation from '../common/metaDataInformation';
import LoginInformation from '../common/loginInformation';
import { IFormState } from '../common/types';

interface IFormProps {
  formState: IFormState;
  formStateError: IUseValidatorResult;
  onUpdateState: (state: IFormState) => void;
  startValidations: (step: number, nextStep: number) => void;
  onSubmit: () => void;
  onValidationMetaData: (
    hasError: boolean,
    data: ContactMetaDataState,
    validations: IValidatorField[]
  ) => void;
  validateMetaData: number;
  currentStep: number;
  showMetaData: boolean;
}

const Form = ({
  formState,
  formStateError,
  validateMetaData,
  currentStep,
  onUpdateState,
  startValidations,
  onSubmit,
  onValidationMetaData,
  showMetaData,
}: IFormProps) => {
  const { t } = useTranslation();

  const onChange = (propName: string) => (value: any) => {
    const formStateClone = { ...formState, [propName]: value };
    onUpdateState(formStateClone);
  };

  const handleOnChangeStep = (activeStep: number, nextStep: number) =>
    startValidations(activeStep, nextStep);

  const builSteps = () => {
    const steps = [
      {
        title: t('pages.user.register.step1Tilte'),
        body: (
          <BasicInformation
            formState={formState}
            formStateError={formStateError}
            onChange={onChange}
          />
        ),
      },
      {
        title: t('pages.user.register.step2Tilte'),
        body: (
          <GeneralInformation
            formState={formState}
            formStateError={formStateError}
            onChange={onChange}
          />
        ),
      },
    ];
    if (showMetaData) {
      steps.push({
        title: t('pages.user.register.step3Tilte'),
        body: (
          <MetaDataInformation
            formState={formState}
            formStateError={formStateError}
            onChange={onChange}
            validateMetaData={validateMetaData}
            onValidationMetaData={onValidationMetaData}
          />
        ),
      });
    }

    steps.push({
      title: t('pages.user.register.confirmation'),
      body: (
        <LoginInformation
          formState={formState}
          formStateError={formStateError}
          onChange={onChange}
        />
      ),
    });

    return steps;
  };

  return (
    <div className='grid grid-cols-2 gap-4'>
      <div className='col-span-2'>
        <Steppers
          validerCaptcha
          steps={builSteps()}
          onConfirmer={onSubmit}
          onChangeStep={handleOnChangeStep}
          currentStep={currentStep}
        />
      </div>
    </div>
  );
};
export default Form;
