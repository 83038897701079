import React, { Fragment } from 'react';
import { sortBy, uniqueId } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { getEmbedVideoUrl, getUrl } from '../../utilitys/url';
import { ManualDto } from '../../dto/admin/types';
import { useTranslation } from 'react-i18next';

interface IPanelProps {
  manuals: ManualDto[];
}

const Panel = ({ manuals }: IPanelProps) => {
  const { t } = useTranslation();

  const onDownloadPdf = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Fragment>
      {sortBy(manuals, (p) => p.title).map((p) => (
        <div key={uniqueId()} className='m-7'>
          <Accordion key={p.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel1bh-content${p.id}`}
              id={`panel1bh-header${p.id}`}
            >
              <div className='w-full flex justify-between '>
                <div className='font-bold text-xl w-full text-center font-title'>
                  {p.title}
                </div>
                {p.documentUrl && (
                  <Tooltip
                    title={
                      <Typography variant='body2'>
                        {t('pages.manuals.tooltipPdf')}
                      </Typography>
                    }
                    placement='top'
                  >
                    <div
                      className='space-x-3 flex text-red-700'
                      onClick={() => onDownloadPdf(getUrl(p.documentUrl))}
                    >
                      <PictureAsPdfIcon fontSize='large' />
                    </div>
                  </Tooltip>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='w-60 float-left text-center'>
                <Icon color='primary' style={{ fontSize: '7rem' }}>
                  {p.icon}
                </Icon>
              </div>
              <div className='mt-1 w-full flex flex-col p-20 pt-0 font-body'>
                <br />
                {p.description}
              </div>
            </AccordionDetails>
            {
              p.videoUrl
              &&
              (
                <AccordionDetails>
                  <iframe className="w-full h-72 md:h-96 mx-5" title={ p.title } src={ getEmbedVideoUrl(p.videoUrl) }></iframe>
                </AccordionDetails>
              )
            }
          </Accordion>
        </div>
      ))}
    </Fragment>
  );
};
export default Panel;
