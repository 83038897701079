import Roles from "../constants/Roles";
import RouteNavigation from "../constants/RouteNavigation";
import { IRoutesAccess } from "../containers/Authorization/types";
import { NotificationsPage } from '../pages';

export const routesNotification: IRoutesAccess[] = [
    {
        url: RouteNavigation.notifications,
        component: NotificationsPage,
        allowAnonymous: false,
        rolesAccesss: [Roles.GuestVUV],
    }
];
