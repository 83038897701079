import _, { isEmpty } from 'lodash';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import contextApp from '../hooks/contexts/contextApp';
import { ThemeType } from '../constants/Theme';
import { SplashScreen } from '../containers';
import { delay } from '../utilitys/delay';
import { difference } from '../utilitys/object';

interface ISelectorThemeProps {
  fontSize: number;
  children: React.ReactNode;
  themeSelected?: string;
}

const SelectorTheme = ({
  children,
  themeSelected,
  fontSize,
}: ISelectorThemeProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  const contextApplication = useContext(contextApp);
  const [theme, setTheme] = useState<Theme | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const isDarkTheme = themeSelected === ThemeType.Dark;

  useEffect(() => {
    const applyFonts = (): void => {
      const root = document.documentElement;
      root.style.setProperty('font-size', `${fontSize}px`);
    };
    applyFonts();
  }, [fontSize]);

  const buildThemeConfig = useCallback((): Theme => {
    let data = {
      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 768,
          lg: 1024,
          xl: 1280,
          '2xl': 1536
        },
      },
      typography: {
        color: '#333333',
        fontSize: 14,
        fontFamily: 'Work Sans, sans-serif',
        h4: {
          fontFamily: 'Montserrat, sans-serif',
          textDecoration: isDarkTheme ? contextApplication.colors.dark?.underlinedTitle && 'underline' : contextApplication.colors.light?.underlinedTitle && 'underline',
          textDecorationColor: isDarkTheme ? contextApplication.colors.dark?.underlined.primary : contextApplication.colors.light?.underlined.primary,
          textDecorationThickness: '5px',
          textUnderlineOffset: '5px'
        },
      },
      palette: {
        type: isDarkTheme ? 'light' : 'dark',
        grey: {
          300: isDarkTheme ? '#212121' : '#f2f2f2',
        },
      },
      overrides: {
        MuiButton: {
          root: {
            borderRadius: '4em'
          }
        }
      }
    } as any;

    if (
      contextApplication.colors &&
      contextApplication.colors.dark &&
      contextApplication.colors.light
    ) {
      data = {
        ...data,
        ...{
          palette: {
            primary: {
              main: isDarkTheme
                ? contextApplication.colors.dark?.primary
                : contextApplication.colors.light?.primary,
            },
            secondary: {
              main: isDarkTheme
                ? contextApplication.colors.dark?.secondary
                : contextApplication.colors.light?.secondary,
            },
            background: {
              paper: isDarkTheme
                ? contextApplication.colors.dark?.background?.secondary
                : contextApplication.colors.light?.background?.secondary,
              default: isDarkTheme
                ? contextApplication.colors.dark?.background?.primary
                : contextApplication.colors.light?.background?.primary,
            },
            text: {
              primary: isDarkTheme
                ? contextApplication.colors.dark?.text.primary
                : contextApplication.colors.light?.text.primary,
              secondary: isDarkTheme
                ? contextApplication.colors.dark?.text.secondary
                : contextApplication.colors.light?.text.secondary,
            },
            info: {
              main: isDarkTheme
              ? contextApplication.colors.dark?.title.primary
              : contextApplication.colors.light?.title.primary,
            },
            warning: {
              main: isDarkTheme
              ? contextApplication.colors.dark?.underlined.primary
              : contextApplication.colors.light?.underlined.primary,
            }
          },
        },
      };
    }

    const theme = createMuiTheme(
      _.merge(data, { typography: { fontSize } }, {})
    );
    return theme;
  }, [contextApplication.colors, fontSize, isDarkTheme]);

  useEffect(() => {
    const loadTheme = async () => {
      const newTheme = buildThemeConfig();
      const hasDifference = difference(
        theme ? theme.palette : ({} as any),
        newTheme.palette as any
      );
      if (!isEmpty(hasDifference)) {
        setLoading(true);
      }
      setTheme(newTheme);
      await delay(1000);
      setLoading(false);
    };
    loadTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, themeSelected, contextApplication, buildThemeConfig]);

  return (
    <Fragment>
      {loading && <SplashScreen />}
      {theme && <ThemeProvider theme={theme}>{children}</ThemeProvider>}
    </Fragment>
  );
};

export default React.memo(SelectorTheme);
