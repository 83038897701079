import { API_BASE } from '../constants';
import { getData, postDataWithOutResponse } from '../_base';
import { NotificationDto } from '../../dto/NotificationDto';

const base = `${API_BASE}/v1/Notifications`;

const url = {
    getNotifications: `${base}/GetMyNotifications`,
    getNotificationsByRead: `${base}/GetMyNotificationsByRead`,
    setAsRead: `${base}/SetAsRead`
};

export const getMyNotifications = async (): Promise<NotificationDto[] | undefined> => {
    const _url = url.getNotifications;
    const result = await getData<NotificationDto[]>(_url);
    if (result && !result.hasError && result.data) return result.data;
    else return undefined;
};

export const getMyNotificationsByRead = async (
    isRead: boolean = false
): Promise<NotificationDto[] | undefined> => {
    const _url = `${url.getNotificationsByRead}/${isRead}`;
    const result = await getData<NotificationDto[]>(_url);
    if (result && !result.hasError && result.data) return result.data;
    else return undefined;
};


export const setNotificationAsRead = async (
    dto: NotificationDto
) => {
    const _url = `${url.setAsRead}`;
    await postDataWithOutResponse(_url, dto);
};

