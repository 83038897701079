import { DocumentMetaDataState, TypeControl } from '../../../containers/DocumentMetaData/types';
import { DigitalDocumentRequest, MetadataDocumentTypeRequest } from '../../../api/document/types';

export const mapToRequest = (
  procedureId: number,
  subject: string,
  processGuidId: string,
  documentTypeId: number,
  metaData: DocumentMetaDataState,
  signatureBase64: string,
): DigitalDocumentRequest => {
  const request: DigitalDocumentRequest = {
    procedureId,
    subject,
    documentTypeId: documentTypeId,
    processGuidId,
    signatureBase64: signatureBase64?.split(`,`)[1],
    metaData: [],
  };

  Object.keys(metaData).forEach((key) => {
    if (metaData[`${key}`].type !== TypeControl.file) {
      request.metaData!.push({
        dicSec: +metaData[`${key}`].dicSec,
        value: getValue(metaData[`${key}`].value),
        attributeFormId: metaData[`${key}`].attributeFormId
      } as MetadataDocumentTypeRequest);
    } else {
      request.metaData!.push({
        dicSec: +metaData[`${key}`].dicSec,
        attributeFormId: metaData[`${key}`].attributeFormId
      } as MetadataDocumentTypeRequest);
    }
  });
  return request;
};

const getValue = (value: any) => {
  if(Array.isArray(value)){
    return value.join(',');
  }
  return value;
 }

 
export const mapStateToDtoFiles = (
  metaData: DocumentMetaDataState,
): File[] => {
  let Files: File[] = [];
  const keys = Object.keys(metaData);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (metaData[`${key}`].type === TypeControl.file) {
      const files = metaData[`${key}`].value as File[];
      Files = Files.concat(files);
    }
  }
  return Files;
};
