import { navigationHome } from './home';
import { navigationProcess } from './process';
import { navigationPqrs } from './pqrs';
import { navigationOutSite } from './outSite';
import { navigationPortfolio } from './portfolio';
import { navigationSite } from './site';
import { navigationAccount } from './account';
import { INavigationItem } from './types';
import { navigationNotification } from './notification';

const navigationConfig: INavigationItem[] = [
  { ...navigationHome },
  { ...navigationProcess },
  { ...navigationPqrs },
  { ...navigationOutSite },
  { ...navigationPortfolio },
  { ...navigationSite },
  { ...navigationAccount },
  { ...navigationNotification }
];

export default navigationConfig;
