import {
  ContextAppDto,
  contextAppImage,
  ContextAppColors,
  ContextFooter,
} from '../dto/ContextAppDto';
import { getUrl } from './url';

export const buildContext = (context: ContextAppDto[]): ContextAppDto[] => {
  const final = [...context].map((p) => {
    const mapped = p;
    mapped.icoUrl = getUrl(mapped.icoUrl);
    mapped.imagesHome = mapImagesHome(p);
    mapped.topBarHeader.urlImg = getUrl(mapped.topBarHeader.urlImg);
    mapped.relayCenter.urlImg = getUrl(mapped.relayCenter.urlImg);
    mapped.urlLogoHeader = getUrl(mapped.urlLogoHeader);
    mapped.colors.light = mapColors(mapped.colors.light, false);
    mapped.colors.dark = mapColors(mapped.colors.dark, true);
    mapped.footer = mapFooter(mapped.footer);
    return mapped;
  });
  return final;
};

const mapImagesHome = (context: ContextAppDto): contextAppImage[] => {
  if (!context.imagesHome) return [] as contextAppImage[];
  return context.imagesHome.map((p) => {
    p.url = getUrl(p.url);
    return p;
  });
};

const mapColors = (
  colorContext: ContextAppColors | undefined,
  themDark: boolean
): ContextAppColors => {
  const mapColor: ContextAppColors = {
    primary: '',
    secondary: '',
    background: {
      primary: '',
      secondary: '',
    },
    text: {
      primary: '',
      secondary: '',
    },
    title: {
      primary: '',
      secondary: ''
    },
    underlined: {
      primary: '',
      secondary: ''
    },
    underlinedTitle: false
  };

  if (!colorContext?.background?.primary)
    mapColor.background!.primary = themDark ? '#303030' : '#F7F7F7';
  else mapColor.background!.primary = colorContext.background.primary;

  if (!colorContext?.background?.secondary)
    mapColor.background!.secondary = themDark ? '#424242' : '#FFFFFF';
  else mapColor.background!.secondary = colorContext.background.secondary;

  if (!colorContext?.primary) mapColor.primary = themDark ? '#192d3e' : '#36c';
  else mapColor.primary = colorContext.primary;

  if (!colorContext?.secondary)
    mapColor.secondary = themDark ? '#ffa31a' : '#317c0c';
  else mapColor.secondary = colorContext.secondary;

  if (!colorContext?.text?.primary)
    mapColor.text.primary = themDark ? '#fff' : '#333333';
  else mapColor.text.primary = colorContext.text.primary;

  if (!colorContext?.text?.secondary)
    mapColor.text.secondary = themDark ? 'rgba(255, 255, 255, 0.7)' : '#fff';
  else mapColor.text.secondary = colorContext.text.secondary;

  if (!colorContext?.title?.primary)
    mapColor.title.primary = themDark ? '#fff' : '#333333';
  else mapColor.title.primary = colorContext.title.primary;

  if (!colorContext?.underlined?.primary)
    mapColor.underlined.primary = themDark ? 'rgba(255, 255, 255, 0.7)' : '#fff';
  else mapColor.underlined.primary = colorContext.underlined.primary;

  mapColor.underlinedTitle = colorContext?.underlinedTitle || false;

  return mapColor;
};

const mapFooter = (footer: ContextFooter | undefined): ContextFooter =>  {
  if(!footer)  {
    return {
      type: 0,
      highlight: false
    }
  }
  footer.image1 = footer.image1 && getUrl(footer.image1);
  footer.image2 = footer.image2 && getUrl(footer.image2);

  return footer;
}
