import React, { useState, useEffect } from 'react';
import { PagePrincipal } from '../../containers';
import { useTranslation } from 'react-i18next';
import { TechnicalRequirementsDto } from '../../dto/admin/types';
import { getTechnicalRequirementByLangApi } from '../../api/technicalRequirement';
import { uniqueId } from 'lodash';
import Panel from './panel';
import { Container } from '@material-ui/core';

const TechnicalRequirements = () => {
  const { t, i18n } = useTranslation();
  const [requirements, setRequirements] = useState<TechnicalRequirementsDto[]>(
    []
  );

  useEffect(() => {
    const callApi = async () => {
      const result = await getTechnicalRequirementByLangApi();
      setRequirements(result);
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <PagePrincipal
      title={t('pages.technicalRequirements.title')}
      subTitle={t('pages.technicalRequirements.subTitle')}
    >
      <Container>
        {requirements.map((p) => (
          <div key={uniqueId()} className='m-7'>
            <Panel data={p} />
          </div>
        ))}
      </Container>
    </PagePrincipal>
  );
};
export default TechnicalRequirements;
