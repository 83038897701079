export const getLocalStorage = <T>(key: string): T | undefined => {
  try {
    const value = localStorage.getItem(key);
    if (!value) return undefined;
    return JSON.parse(value) as T;
  } catch {
    return undefined;
  }
};

export const setLocalStorage = <T>(key: string, data: T) => {
  if (!data) localStorage.setItem(key, '');
  else localStorage.setItem(key, JSON.stringify(data));
};
