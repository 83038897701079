import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersPanel, SearchBar } from '../../../components';
import FiltrePrimary from '../filtres/filtresPrimary';
import FiltresSecondary from '../filtres/filtresSecondary';
import { ISearchFiltres } from '../types';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

interface IFiltreProps {
  types: string[];
  onFitre: (filtres: ISearchFiltres) => void;
}

const initialStateFiltre = {
  title: undefined,
  types: undefined,
  expeditionDateStart: undefined,
  expeditionDateEnd: undefined,
  publicationDateStart: undefined,
  publicationDateEnd: undefined,
  description: undefined,
};

const Filtre = ({ types, onFitre }: IFiltreProps) => {
  const { t } = useTranslation();
  const [isFiltrePrimary, setIsFiltrePrimary] = useState<boolean>(false);
  const [state, setState] = useState<ISearchFiltres>(initialStateFiltre);
  const handleChangeCheckFiltre = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    reset();
    setIsFiltrePrimary(event.target.checked);
  };

  const onSearch = (value: string) => {
    const clone = { ...state } as ISearchFiltres;
    clone.title = value;
    onFitre(clone);
    setState(clone);
  };

  const onSelectType = (types: string[] | undefined) => {
    const clone = { ...state } as ISearchFiltres;
    clone.types = types;
    onFitre(clone);
    setState(clone);
  };

  const onChangeDate = (
    isExpeditionDate: boolean,
    startDate?: Date,
    endDate?: Date
  ) => {
    const clone = { ...state } as ISearchFiltres;
    if (isExpeditionDate) {
      clone.expeditionDateStart = startDate;
      clone.expeditionDateEnd = endDate;
    } else {
      clone.publicationDateStart = startDate;
      clone.publicationDateEnd = endDate;
    }
    onFitre(clone);
    setState(clone);
  };

  const onChangeDescription = (value: string) => {
    const clone = { ...state } as ISearchFiltres;
    clone.description = value;
    onFitre(clone);
    setState(clone);
  };

  const reset = () => {
    onFitre(initialStateFiltre);
    setState(initialStateFiltre);
  };

  return (
    <FiltersPanel>
      <List>
        <ListItem button onClick={reset}>
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary={t('pages.politics.reset')} />
        </ListItem>
      </List>
      <SearchBar placeholder={t('components.filtersPanel.search')} onSearch={onSearch} />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={isFiltrePrimary}
            onChange={handleChangeCheckFiltre}
            color='primary'
          />
        }
        label={t('pages.normativityOffice.labelFiltre')}
      />
      {!isFiltrePrimary ? (
        <FiltrePrimary types={types} onSelectType={onSelectType} values={state?.types || []} />
      ) : (
        <FiltresSecondary
          types={types}
          onChangeDescription={onChangeDescription}
          onChangeTypes={onSelectType}
          onChangeDate={onChangeDate}
          state={state}
        />
      )}
    </FiltersPanel>
  );
};
export default Filtre;
