import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import * as dateFns from 'date-fns';
import { uniqueId } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { AutocompleteStyled } from '../../components';
import { KeyCodes } from '../../constants/KeyCode';

interface ICalendarMonthProps {
  initialDate?: Date;
  onChangeDate: (date: Date) => void;
}

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.text.primary,
  },
  bakgroundPaper: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const CalendarMonth = ({
  initialDate,
  onChangeDate,
}: ICalendarMonthProps) => {
  const classes = useStyles();
  const [currentMonthYear, setCurrentMonthYear] = useState(
    !initialDate ? new Date() : initialDate
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!initialDate) {
      onChangeDate(new Date());
    }
  }, [initialDate, onChangeDate]);

  const getYears = (): string[] => {
    const years: string[] = [];
    for (let index = 2099; index > 1950; index--) {
      years.push(`${index}`);
    }
    return years;
  };

  const changeMonth = (monthAdd: number) => {
    const result = dateFns.addMonths(currentMonthYear, monthAdd);
    setCurrentMonthYear(result);
  };

  const selectDate = (day: number) => {
    const date = dateFns.setDate(currentMonthYear, day);
    setCurrentMonthYear(date);
    onChangeDate(date);
  };

  const changeYear = (e: any, value: any | null) => {
    if (!value) return;
    const result = dateFns.setYear(currentMonthYear, +value);
    setCurrentMonthYear(result);
  };

  const renderHeader = () => (
    <div className='flex justify-between items-center mb-2'>
      <div>
        <div className='flex space-x-3 content-center items-center'>
          <span
            className={clsx(
              'text-lg font-bold capitalize',
              classes.textPrimary
            )}
          >
            {t(`month.${currentMonthYear.getMonth()}`)}
          </span>
          <AutocompleteStyled
            options={getYears()}
            value={`${dateFns.getYear(currentMonthYear)}`}
            onChange={changeYear}
            renderInput={() => <></>}
          />
        </div>
      </div>
      <div>
        <button
          type='button'
          className='transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full'
          onClick={() => {
            changeMonth(-1);
          }}
        >
          <ArrowBackIosIcon />
        </button>
        <button
          type='button'
          className='transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full'
          onClick={() => {
            changeMonth(1);
          }}
        >
          <ArrowForwardIosIcon />
        </button>
      </div>
    </div>
  );

  const getClassName = (day: Date, month: Date) => {
    if (
      initialDate &&
      dateFns.isSameDay(day, initialDate) &&
      dateFns.isSameMonth(day, initialDate) &&
      dateFns.isSameYear(day, initialDate)
    ){
      return ' bg-blue-200 cursor-default';
    }
    if (!dateFns.isSameMonth(day, month))
      return `bg-transparent text-transparent cursor-default `;
    return `hover:bg-blue-200 cursor-pointer ${classes.textPrimary}`;
  };

  const renderCells = () => {
    const monthStart = dateFns.startOfMonth(currentMonthYear);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;

    const onKeyDown = (formattedDate: number) => (e: any) => {
      if (e.keyCode === KeyCodes.Enter) selectDate(+formattedDate);
    }

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = dateFns.format(day, dateFormat);
        days.push(
          <div
            key={uniqueId()}
            className={clsx(
              'flex justify-center items-center h-10 w-10 text-sm rounded-full border border-transparent ',
              getClassName(day, monthStart)
            )}
            onClick={() => {
              selectDate(+formattedDate);
            }}
            tabIndex={0}
            onKeyDown={onKeyDown(+formattedDate)}
          >
            {formattedDate}
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div key={uniqueId()} className='flex flex-wrap'>
          {days}
        </div>
      );
      days = [];
    }
    return <div className=''>{rows}</div>;
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          key={uniqueId()}
          className={clsx(
            'flex justify-center items-center h-10 w-10 cursor-pointer text-sm rounded-full capitalize truncate',
            classes.textPrimary
          )}
        >
          {t(`dayOfWeek.${i}`).toString().substr(0, 3)}
        </div>
      );
    }

    return <div className='flex flex-wrap'>{days}</div>;
  };

  return (
    <div className={clsx(classes.bakgroundPaper, 'mt-12 rounded-lg shadow p-4 top-0 left-0 w-80')}>
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};
export default CalendarMonth;
