import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { renderImg, renderText } from './utility';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  textColorPrimary: {
    color: theme.palette.text.primary,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
}));

interface IPanelProps {
  title: string;
  description1?: string;
  description2?: string;
  description3?: string;
  description4?: string;
  icon: string;
  url1?: string;
}

const Panel = ({
  title,
  icon,
  description1,
  description2,
  description3,
  description4,
  url1,
}: IPanelProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className='w-full pl-5 pt-5'>
      <div
        className={clsx(
          classes.root,
          'relative flex flex-col items-center justify-around rounded-2xl '
        )}
        style={{
          minHeight: '20rem',
        }}
      >
        <div className='w-full h-full rounded-xl '></div>
        <h3
          className={clsx(
            classes.textColorPrimary,
            'text-2xl text-center font-semibold font-title'
          )}
        >
          {title}
        </h3>
        <div className=''>{renderImg(icon, '7rem', classes.secondaryColor)}</div>
        <div className={clsx(classes.textColorPrimary, 'text-sm text-center font-body')}>
          {renderText(history, description1, url1)}
          <br />
          {renderText(history, description2, undefined)}
          <br />
          {renderText(history, description3, undefined)}
          <br />
          {renderText(history, description4, undefined)}
        </div>
      </div>
    </div>
  );
};
export default Panel;
