import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface IFiltersPanelProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundPrincipal: {
      backgroundColor: theme.palette.primary.main,
    },
    textSecondary: {
      color: theme.palette.text.secondary,
    },
  })
);

const FiltersPanel = ({ children }: IFiltersPanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper>
      <div className='rounded-lg shadow-xl'>
        <div
          className={clsx(
            classes.backgroundPrincipal,
            classes.textSecondary,
            'text-center text-xl w-full h-12 p-2 font-semibold font-title'
          )}
        >
          {t('components.filtersPanel.title')}
        </div>
        <div className='p-5'>{children}</div>
      </div>
    </Paper>
  );
};
export default FiltersPanel;
