import { IRoutesAccess } from '../containers/Authorization/types';
import RouteNavigation from '../constants/RouteNavigation';
import Roles from '../constants/Roles';
import {
  PqrsdAuthenticatedPage,
  PqrsTrackingPage,
  PqrsdAnonymePage,
  PqrsdNonRegPage,
  PqrsdDescriptionPage,
  PqrsTrackingDocumentPage,
} from '../pages';
import PqrsdTrackingReg from '../pages/pqrsd/tracking/trackingReg';

export const routesPqrsd: IRoutesAccess[] = [
  {
    url: `${RouteNavigation.pqrsInformation}/:typeId`,
    component: PqrsdDescriptionPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.pqrsReg,
    component: PqrsdAuthenticatedPage,
    allowAnonymous: false,
    rolesAccesss: [Roles.GuestVUV],
  },
  {
    url: RouteNavigation.pqrsdAnonyme,
    component: PqrsdAnonymePage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.pqrsdNonReg,
    component: PqrsdNonRegPage,
    allowAnonymous: true,
  },
  {
    url: `${RouteNavigation.pqrsTrackingDocument}/:document/:accessCode`,
    component: PqrsTrackingDocumentPage,
    allowAnonymous: true,
  },
  {
    url: `${RouteNavigation.pqrsTrackingDocument}/:document`,
    component: PqrsTrackingDocumentPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.pqrsTracking,
    component: PqrsTrackingPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.pqrsTrackingReg,
    component: PqrsdTrackingReg,
    allowAnonymous: false,
    rolesAccesss: [Roles.GuestVUV],
  },
];
