import React, { useState } from 'react';
import clsx from 'clsx';
import * as dateFns from 'date-fns';
import { uniqueId } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Month } from '../../constants/Time';

interface ICalendarMonthProps {
  month: Month;
  year: number;
  holiDays: Date[];
}

const useStyles = makeStyles((theme) => ({
  bakgroundPrincipal: {
    backgroundColor: theme.palette.primary.main,
  },
  bakgroundSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  bakgroundPaper: {
    backgroundColor: theme.palette.background.paper,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}));

const CalendarMonth = ({
  month,
  year,
  holiDays,
}: ICalendarMonthProps) => {
  const classes = useStyles();
  const [currentMonthYear] = useState(
    new Date(year, month)
  );
  const { t } = useTranslation();

  const renderHeader = () => (
    <div className='flex flex-wrap mt-3 mb-5 justify-center'>
      <div className={clsx('text-2xl', classes.textPrimary)}>
        <span className='capitalize'>
          {t(`month.${currentMonthYear.getMonth()}`)}
        </span>
      </div>
    </div>
  );

  const isHoliDay = (date: Date) =>
    holiDays.some((p) => p.toDateString() === date.toDateString());

  const getClassName = (day: Date, month: Date) => {
    if (!dateFns.isSameMonth(day, month))
      return 'border border-transparent bg-transparent text-transparent cursor-default ';
    if (isHoliDay(day)) return `bg-gray-300 cursor-pointer ${classes.textPrimary}`;
    return `hover:bg-blue-200 cursor-pointer  ${classes.textPrimary}`;
  };

  const renderCells = () => {
    const monthStart = dateFns.startOfMonth(currentMonthYear);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        days.push(
          <div
            key={uniqueId()}
            className={clsx(
              'flex justify-center items-center h-10 w-10 text-sm rounded-full  ',
              getClassName(day, monthStart)
            )}
          >
            {formattedDate}
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div key={uniqueId()} className={clsx(classes.bakgroundPaper, 'flex flex-wrap')}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className=''>{rows}</div>;
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          key={uniqueId()}
          className={clsx(
            'flex justify-center items-center h-10 w-10 cursor-pointer text-sm rounded-full capitalize truncate',
            classes.textPrimary
          )}
        >
          {t(`dayOfWeek.${i}`).toString().substr(0, 3)}
        </div>
      );
    }

    return <div className='flex flex-wrap'>{days}</div>;
  };

  return (
    <div className={clsx(classes.bakgroundPaper, 'mt-12 rounded-lg shadow p-4 top-0 left-0 w-80')}>
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};
export default CalendarMonth;
