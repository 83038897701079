import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getByLanguageIdAndType } from '../../../api/pqrs';
import { PagePrincipal } from '../../../containers';
import { PqrsInformation } from '../../../dto/pqrs';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useTranslation } from 'react-i18next';
import { EnumTypePQRS } from '../../../constants/PqrsType';
import RouteNavigation from '../../../constants/RouteNavigation';
import { BasicModal, DangerouslySetInnerHTML } from '../../../components';
import { getUrl } from '../../../utilitys/url';

interface IParams {
  typeId: string;
}

const initialState: PqrsInformation = {
  title: '',
  description: '',
  file: '',
};

const PqrsdDescription = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { typeId } = useParams<IParams>();
  const history = useHistory();
  const [modalOpenValidation, setOpenModal] = useState(true);

  const [pqrsInformation, setPqrsInformation] = useState<PqrsInformation>(initialState);

  useEffect(() => {
    const fetch = async () => {
      const pqrsInfo = await getByLanguageIdAndType(+typeId);
      if (pqrsInfo) setPqrsInformation(pqrsInfo);
    };
    fetch();
    setOpenModal(true);
  }, [typeId, language]);

  const onClickAttachement = () =>
    window.open(getUrl(pqrsInformation.file), '_blank');

  const onClickStart = () => {
    switch (+typeId) {
      case EnumTypePQRS.Anonymous:
        history.push(`/${RouteNavigation.pqrsdAnonyme}`);
        break;
      case EnumTypePQRS.NotRegistered:
        history.push(`/${RouteNavigation.pqrsdNonReg}`);
        break;
      case EnumTypePQRS.Registered:
        history.push(`/${RouteNavigation.pqrsReg}`);
        break;
      default:
        break;
    }
  };

  const redirectProcess = () => {
    history.push(`/${RouteNavigation.Process}`);
  };

  return (
    <PagePrincipal withContainer title={pqrsInformation.title}>
      {pqrsInformation.file && (
        <div className='float-right'>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onClickAttachement}
            endIcon={<InsertDriveFileIcon />}
          >
            {t('pages.pqrs.description.attachement')}
          </Button>
        </div>
      )}
      <div className='w-full text-center mt-10'>
        <Button variant='contained' color='secondary' onClick={onClickStart}>
          {t('pages.pqrs.description.start')}
        </Button>
      </div>
      <br />
      <br />
      <div className='mt-1 p-10'>
        <DangerouslySetInnerHTML text={pqrsInformation.description} />
      </div>
      <br />

      <BasicModal
        open={modalOpenValidation}
        title={t('pages.pqrs.validationCurrentPage.title')}
        showAcceptButton
        showCancelButton
        titleButtonAccept={t('pages.pqrs.validationCurrentPage.titleButtonAccept')}
        titleButtonCancel={t('pages.pqrs.validationCurrentPage.titleButtonCancel')}
        onClickCancel={() => { redirectProcess(); }}
        onClickAccept={() => { setOpenModal(false); }}>
        <div>
          <div className='text-xl'>{t('pages.pqrs.validationCurrentPage.subTitle')}</div>
          <br />
          <span className='text-xl'>{t('pages.pqrs.validationCurrentPage.paragraph1')}</span>
          <br />
          <span className='text-xl'>{t('pages.pqrs.validationCurrentPage.paragraph2')}</span>
        </div>
      </BasicModal>
    </PagePrincipal>
  );
};
export default PqrsdDescription;
