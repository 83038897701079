import {
  Select,
  MenuItem,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { IConfigurationProps } from './types';
import { LanguageDto } from '../../dto/admin/types';
import { getLanguageApi } from '../../api/AdmInquiries';
import { STORAGE_LANGUAGE } from '../../constants/App';
import HelpIcon from '@material-ui/icons/Help';
import { useHistory } from 'react-router-dom';
import RouteNavigation from '../../constants/RouteNavigation';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { setLocalStorage } from '../../utilitys/localStorage';
import { ThemeType } from '../../constants/Theme';

const useStyles = makeStyles({
  menuButton: {
    marginRight: 36,
  },
});

const Configuration = ({
  onChangeTheme,
  themeSelected,
  showTheme,
}: IConfigurationProps) => {
  const [languages, setLanguage] = useState<LanguageDto[]>();
  const [languageSelected, setLanguageSelected] = useState<LanguageDto>();
  const history = useHistory();
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const result = await getLanguageApi();
      setLanguage(result);
    };
    fetch();
  }, []);

  const handleonChangeLanguage = (event: any) => {
    const lng = languages?.find(
      (p) =>
        p.code.toString().toLowerCase() ===
        event.target.value.toString().toLowerCase()
    );
    if (!lng) return;
    setLanguageSelected(lng);
    i18n.changeLanguage(lng.code.toLowerCase());
    setLocalStorage(STORAGE_LANGUAGE, lng);
  };

  const handleOnChangeTheme = (theme: string) => {
    if (onChangeTheme) onChangeTheme(theme);
  };

  const renderBouttonsTheme = (): React.ReactNode => {
    return (
      <Fragment>
        {themeSelected === ThemeType.Default ? (
          <IconButton
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={() => handleOnChangeTheme(ThemeType.Dark)}
          >
            <Brightness4Icon />
          </IconButton>
        ) : (
          <IconButton
            className={classes.menuButton}
            color='inherit'
            aria-label='darkTheme'
            onClick={() => handleOnChangeTheme(ThemeType.Default)}
          >
            <Brightness7Icon />
          </IconButton>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Tooltip
        title={
          <Typography variant='body2'>
            {t(`components.configuration.tooltipHelp`)}
          </Typography>
        }
        placement='bottom'
      >
        <IconButton
          aria-label='help'
          size='small'
          onClick={() => history.push(`/${RouteNavigation.Questions}`)}
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          <Typography variant='body2'>
            {t(`components.configuration.tooltipLanguages`)}
          </Typography>
        }
        placement='left'
      >
        <Select
          variant='outlined'
          defaultValue='es'
          value={
            languageSelected
              ? languageSelected?.code.toLowerCase()
              : getCurrentLanguageDto()?.code.toLowerCase()
          }
          onChange={handleonChangeLanguage}
        >
          <MenuItem value='es'>Español</MenuItem>
          {languages
            ?.filter((p) => p.name.toUpperCase() !== 'ESPAÑOL')
            .map((p) => (
              <MenuItem key={p.id} value={p.code.toLowerCase()}>
                {p.name}
              </MenuItem>
            ))}
        </Select>
      </Tooltip>
      {showTheme && renderBouttonsTheme()}
      
    </Fragment>
  );
};
export default Configuration;
