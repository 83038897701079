import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    background: `${theme.palette.primary.main}`,
  },
}));

const Skeleton = () => {
  const classes = useStyles();
  return (
    <div className='shadow rounded-md p-4 max-w-sm w-full mx-auto'>
      <div className='animate-pulse flex space-x-4'>
        <div
          className={clsx(
            classes.colorPrimary,
            'rounded-full  h-12 w-12'
          )}
        ></div>
        <div className='flex-1 space-y-4 py-1'>
          <div
            className={clsx(
              classes.colorPrimary,
              'h-4  rounded w-3/4'
            )}
          ></div>
          <div className='space-y-2'>
            <div className={clsx(classes.colorPrimary, 'h-4  rounded')}></div>
            <div
              className={clsx(
                classes.colorPrimary,
                'h-4  rounded w-5/6'
              )}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Skeleton;
