import { InformationCustomTableDto } from '../../dto/admin/types';

export enum TypeControl {
  text = 'T',
  currency = 'M',
  number = 'N',
  email = 'C',
  email2 = '@',
  text2 = 'A',
  date = 'F',
  list = 'L',
}

export interface ContactMetaDataState {
  [key: number]: {
    type: TypeControl,
    value: string,
    text?: string
    label: string;
  }
}

export interface ContactMetaDataTablesInformation {
  [key: number]: InformationCustomTableDto[]
}

export interface ResultContractMetaDataChild {
  id: number,
  value: any;
  text: string;
  typeElement: TypeControl;
  label: string;
}

export interface HandleContractMetaDataChild {
  getData: () => ResultContractMetaDataChild;
}