import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IValidatorField } from './types';
import { assignError, validateIsPropNested } from './utility';
import { validateRequired, validateRegex, validateWhere, validateWhereRegex } from './validators';

export interface IUseValidatorResult {
  [Key: string]: string;
}

function useValidator<T extends object>(
  hadleNextAction?: () => void
): [IUseValidatorResult, (currentState: T, validations: IValidatorField[]) => boolean] {
  const [errors, setErrors] = useState<IUseValidatorResult>({});
  const { t } = useTranslation();
  const startValidations = (currentState: T,validations: IValidatorField[]): boolean => {
    const result: IUseValidatorResult = {};
    for (let i = 0; i < validations.length; i++) {
      const validation: IValidatorField = validations[i];
      const hasProp: boolean = currentState.hasOwnProperty(validation.field);
      
      const hasNestedProp: boolean = validateIsPropNested(validation.field, {
        ...currentState,
      });

      if (!hasProp && !hasNestedProp) {
        console.error(
          `useValidator, property ${validation.field} does not exist int the object.`
        );
        continue;
      }
      
      if (!validateRequired(validation, currentState)) {
        assignError(result, hasNestedProp, validation.field, validation.validations.message || t('message.validation.fieldRequired'));
        continue;
      }

      if (!validateRegex(validation, currentState)) {
        assignError(result, hasNestedProp, validation.field, validation.validations.pattern!.message);
        continue;
      }

      if (!validateWhere(validation, currentState)) {
        assignError(result, hasNestedProp, validation.field, validation.validations.where?.message || t('message.validation.fieldRequired'));
        continue;
      }

      if (!validateWhereRegex(validation, currentState)) {
        assignError(result, hasNestedProp, validation.field, validation.validations.where?.pattern?.message || 'error Regex');
        continue;
      }
    }

    setErrors(result);

    if (isEmpty(result) && hadleNextAction) {
      hadleNextAction();
    }
    return isEmpty(result);
  };

  return [errors, startValidations];
}





export default useValidator;
