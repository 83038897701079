import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { makeStyles } from '@material-ui/core';

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.secondary.main,
    zIndex: 1,
    fontSize: 18,
  },
}));

const QontoStepIcon = (props: StepIconProps) => {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
};

export default QontoStepIcon;
