import React, { useEffect, useState } from 'react';
import { getHoliDaysApi } from '../../api/AdmInquiries';
import CalendarMonth from './calendarMonth';
import { Month } from '../../constants/Time';

interface ICalendarProps {
  year: number;
}

const Calendar = ({ year }: ICalendarProps) => {
  const [holiDays, setHoliDays] = useState<Date[]>([]);

  useEffect(() => {
    const callApi = async () => {
      const result = await getHoliDaysApi();
      const dates = result.map((p) => new Date(p));
      setHoliDays(dates);
    };
    callApi();
  }, []);

  return (
    <div className='w-full text-center'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-3'>
        <div className='col-1'>
          <CalendarMonth
            year={year}
            month={Month.January}
            holiDays={holiDays}
          />
        </div>
        <div className='col-1'>
          <CalendarMonth
            year={year}
            month={Month.February}
            holiDays={holiDays}
          />
        </div>
        <div className='col-1'>
          <CalendarMonth year={year} month={Month.March} holiDays={holiDays} />
        </div>
        <div className='col-1'>
          <CalendarMonth year={year} month={Month.April} holiDays={holiDays} />
        </div>

        <div className='col-1'>
          <CalendarMonth year={year} month={Month.May} holiDays={holiDays} />
        </div>
        <div className='col-1'>
          <CalendarMonth year={year} month={Month.June} holiDays={holiDays} />
        </div>
        <div className='col-1'>
          <CalendarMonth year={year} month={Month.July} holiDays={holiDays} />
        </div>
        <div className='col-1'>
          <CalendarMonth year={year} month={Month.August} holiDays={holiDays} />
        </div>

        <div className='col-1'>
          <CalendarMonth
            year={year}
            month={Month.September}
            holiDays={holiDays}
          />
        </div>
        <div className='col-1'>
          <CalendarMonth
            year={year}
            month={Month.October}
            holiDays={holiDays}
          />
        </div>
        <div className='col-1'>
          <CalendarMonth
            year={year}
            month={Month.November}
            holiDays={holiDays}
          />
        </div>
        <div className='col-1'>
          <CalendarMonth
            year={year}
            month={Month.December}
            holiDays={holiDays}
          />
        </div>
      </div>
    </div>
  );
};
export default Calendar;
