import { isEmpty, sortBy, uniqueId } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInformativeTextByLangApi } from '../../api/informativeText';
import CarouselImages, {
  ICarouselImagesItem,
} from '../../components/CarouselImages';
import { DynamicControlTemplate, PagePrincipal } from '../../containers';
import { InformativeTextDto } from '../../dto/DynamicControlDto';
import contextApp from '../../hooks/contexts/contextApp';

const Home = () => {
  const { i18n } = useTranslation();
  const ctxApp = useContext(contextApp);
  const [informativesText, setInformativesText] = useState<
    InformativeTextDto[]
  >([]);

  useEffect(() => {
    const callApi = async () => {
      const result = await getInformativeTextByLangApi();
      setInformativesText(result);
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const buildImages = (): ICarouselImagesItem[] =>
    ctxApp.imagesHome.map(
      (p) =>
        ({
          id: uniqueId(),
          urlImage: p.url,
          name: p.title,
        } as ICarouselImagesItem)
    );

  const images = buildImages();

  return (
    <PagePrincipal>
      <div className=''>
        {!isEmpty(images) && (
          <CarouselImages data={images} className='h-auto rounded-xl' />
        )}
        <div className='mt-5'>
          <div className='w-full flex flex-wrap'>
            {sortBy(informativesText, (p) => p.order).map((p) => (
              <DynamicControlTemplate key={uniqueId()} configuration={p} />
            ))}
          </div>
        </div>
      </div>
    </PagePrincipal>
  );
};
export default Home;
