import { SetStateAction } from 'react';
import { Dispatch } from 'react';
import { useEffect, useState } from 'react';
import { getLocalStorage, setLocalStorage}  from '../../utilitys/localStorage';

export const useStateWithLocalStorage = <T>(
  localStorageKey: string
): [any | undefined, Dispatch<SetStateAction<T | undefined>>] => {
  const currentValue = getLocalStorage(localStorageKey) as T | undefined;
  const [value, setValue] = useState<T | undefined>(currentValue);

  useEffect(() => {
    setLocalStorage(localStorageKey, value);
  }, [localStorageKey, value]);

  return [value, setValue];
};
