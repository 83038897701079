import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicModal, FileUpload, TextField } from '../../../components';

interface IAddInformationActionProps {
  addInformation: (files: File[], observation: string) => void;
}

const acceptExtesionFiles =
  '.jpg, .jpeg, .png, .pdf, .mp3, .mp4, .ppt, .pptx, .xls, .xlsx, .doc, .docx';

const AddInformationAction = ({
  addInformation,
}: IAddInformationActionProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [observation, setObservation] = useState<string>();

  const onClickAccept = () => {
    if (!observation) return;
    setOpen(false);
    addInformation(files, observation);
  };

  const onLoadFiles = (files: File[], hasErrorValidation: boolean) => {
    setFiles(files);
  };

  const onChange = (value: string) => setObservation(value);

  return (
    <div>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        color='primary'
        variant='contained'
      >
        {t('pages.pqrs.actions.addInformation.title')}
      </Button>
      <BasicModal
        open={open}
        title={t('pages.pqrs.actions.addInformation.title')}
        showAcceptButton
        showCancelButton
        titleButtonCancel={t('pages.pqrs.actions.addInformation.btnNon')}
        titleButtonAccept={t('pages.pqrs.actions.addInformation.btnYes')}
        onClickCancel={() => {
          setOpen(false);
        }}
        onClickAccept={onClickAccept}
      >
        <div className='text-lg italic flex-row justify-center'>
          {t('pages.pqrs.actions.addInformation.message')}

          <div className='p-5 space-y-4 space-x-4'>
            <FileUpload
              files={files}
              maxFiles={10}
              maxSizeTotalMB={50}
              isMultiple
              label='Anexos'
              isRequired
              acceptExtesion={acceptExtesionFiles}
              onLoadFiles={onLoadFiles}
            />

            <TextField
              maxLength={390}
              isOutlined
              isRequired
              label={t('pages.pqrs.actions.addInformation.lblInformation')}
              value={observation}
              onChange={onChange}
              multiline
              autoFocus={false}
            />
          </div>
        </div>
      </BasicModal>
    </div>
  );
};
export default AddInformationAction;
