import { INavigationItem } from './types';
import RouteNavigation from '../constants/RouteNavigation';

export const navigationNotification: INavigationItem = {
    id: '7',
    keyTranslate: 'notifications',
    allowAnonymous: true,
    icon: 'notifications',
    url: RouteNavigation.notifications,
    hide: true
  };