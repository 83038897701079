import React from 'react';
import { orderBy } from 'lodash';
import { AutocompleteStyled, TextField } from '../../../components';
import { RangeDates } from '../../../components';
import { useTranslation } from 'react-i18next';
import { ISearchFiltres } from '../types';

interface IFiltreProps {
  types: string[];
  onChangeDescription: (value: string) => void;
  onChangeTypes: (types: string[] | undefined) => void;
  onChangeDate: (
    isExpeditionDate: boolean,
    startDate?: Date,
    endDate?: Date
  ) => void;
  state: ISearchFiltres;
}

const FiltreSecondary = ({
  types,
  onChangeTypes,
  onChangeDate,
  onChangeDescription,
  state
}: IFiltreProps) => {
  const { t } = useTranslation();
  const onChangeType = (e: any, value: any | null) => onChangeTypes(value);

  const onChangeDateLocal =
    (isExpeditionDate: boolean) => (startDate?: Date, endDate?: Date) => {
      onChangeDate(isExpeditionDate, startDate, endDate);
    };

  return (
    <div className='space-y-3'>
      <AutocompleteStyled
        multiple
        isOutlined
        options={orderBy(types)}
        label={t('pages.normativityOffice.labelType')}
        onChange={onChangeType}
        getOptionLabel={(option: any) => option}
        renderInput={() => <></>}
        value={state.types || []}
      />
      <RangeDates
        label={t('pages.normativityOffice.labelExpeditionDate')}
        labelFrom={t('pages.normativityOffice.labelDateFrom')}
        labelTo={t('pages.normativityOffice.labelDateTo')}
        onChangeDate={onChangeDateLocal(true)}
        startDate={state.expeditionDateStart}
        endDate={state.expeditionDateEnd}
      />
      <RangeDates
        label={t('pages.normativityOffice.labelPublicationDate')}
        labelFrom={t('pages.normativityOffice.labelDateFrom')}
        labelTo={t('pages.normativityOffice.labelDateTo')}
        onChangeDate={onChangeDateLocal(false)}
        startDate={state.publicationDateStart}
        endDate={state.publicationDateEnd}
      />
      <div>
        <TextField
          maxLength={100}
          isOutlined
          label={t('pages.normativityOffice.labelDescription')}
          onChange={onChangeDescription}
          value={state.description || ''}
        />
      </div>
    </div>
  );
};
export default FiltreSecondary;
