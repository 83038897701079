import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DocumentMetaData, ErrorDisplays, PagePrincipal } from '../../../containers';
import { BasicModal, ButtonCaptcha, Loading } from '../../../components';
import { getProcessByIdApi } from '../../../api/process';
import { ProcessDto } from '../../../dto/Process';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { DocumentTypeDto } from '../../../dto/admin/types';
import { getDocumetTypeIdByProcessGuidIdApi } from '../../../api/AdmInquiries';
import { DocumentMetaDataState } from '../../../containers/DocumentMetaData/types';
import { IValidatorField } from '../../../hooks/validation/useValidator/types';
import { mapToRequest, mapStateToDtoFiles } from './mapper';
import { saveDigitalDocument } from '../../../api/document';
import { DigitalDocumentResponse } from '../../../api/document/types';
import RouteNavigation from '../../../constants/RouteNavigation';
import { addAttachment } from '../../../api/attachment';
import { SignatureModal } from '../../../containers';
import { IErrorApiBase } from '../../../api/_base/types';
import { getProcessInformation } from '../../../api/backOffice';
import { BpmProcessInformationDto } from '../../../api/backOffice/dto';

interface IBpmProcessParams {
  processId: string;
  id: string;
}

const BpmProcess = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [proccessInfo, setProccessInfo] = useState<ProcessDto | undefined>();
  const [processBpmInfo, setProcessBpmInfo] =useState<BpmProcessInformationDto>(); 
  const [documentType, setDocumentType] = useState<
    DocumentTypeDto | undefined
  >();
  const { processId, id } = useParams<IBpmProcessParams>();
  const [validateData, setValidateData] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [isModalSingnatureOpen, setIsModalSingnatureOpen] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<DocumentMetaDataState>();
  const [errorsValidation, setErrorsValidation] = useState<IErrorApiBase[] | undefined>(undefined);
  const [documentInformation, setdocumentInformation] = useState<
    DigitalDocumentResponse | undefined
  >();

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const proccess = await getProcessByIdApi(+id);
      const documentType = await getDocumetTypeIdByProcessGuidIdApi(processId);
      const processBpm = await getProcessInformation(processId)
      setProccessInfo(proccess);
      setDocumentType(documentType);
      setProcessBpmInfo(processBpm);
      setIsLoading(false);
    };
    fetch();
  }, [processId, id]);

  const onGotToProcess = () => {
    history.goBack();
  };

  const onSave = () => {
    setIsLoading(true);
    setValidateData(validateData + 1);
  };

  const onValidationMetaData = async (
    hasError: boolean,
    data: DocumentMetaDataState,
    validations: IValidatorField[]
  ) => {
     if (hasError || !documentType){
      setIsLoading(false);
      return;
    }
    setMetadata(data);
    documentType.allowExternalSignature
      ? setIsModalSingnatureOpen(true)
      : createDocument(data);
  };

  const redirectProcess = () => {
    history.push(`/${RouteNavigation.Process}/`);
  };

  const createDocument = async (metadaDocument: DocumentMetaDataState, singatureBase64?: string) =>{
    if (!proccessInfo || !documentType) return;
    setIsLoading(true);
    const request = mapToRequest(
      proccessInfo.id,
      proccessInfo.title,
      processId,
      documentType.id,
      metadaDocument,
      singatureBase64 ?? '',
    );
    const files = mapStateToDtoFiles(metadaDocument);
    const document = await saveDigitalDocument(request);
    if (document) {
      await addAttachment(document?.docIdEncrypted, files);
      setdocumentInformation(document);
      setAlertOpen(true);
    }
    setIsLoading(false);
  }

  const acceptSignature = (signatureBase64: string) => {
    if (!metadata) return;
    setIsModalSingnatureOpen(false);
    !signatureBase64
      ? setErrorsValidation([{ message: 'singature_required', fieldName: '' }])
      : createDocument(metadata, signatureBase64);
  };

  return (
    <PagePrincipal withContainer title={proccessInfo?.title}>
      <div className='mb-4'>
        <Button
          variant='outlined'
          color='primary'
          onClick={onGotToProcess}
          startIcon={<ArrowBackIosIcon />}
        >
          {t('pages.processHome.back')}
        </Button>
      </div>

      {documentType && (
        <>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2 pb-16'>
            <DocumentMetaData
              documentTypeId={documentType.id}
              validateData={validateData}
              onValidationMetaData={onValidationMetaData}
              processBpmInformation={processBpmInfo}
            />
          </div>
          <div className='flex justify-center mb-8'>
            <div className='w-96'>
              <ButtonCaptcha
                fullWidth
                textButton={t('pages.pqrs.common.save')}
                onClick={onSave}
              />
            </div>
          </div>
        </>
      )}
      <br />
      <Loading loading={isLoading} />
      <ErrorDisplays errors={errorsValidation} module='bpm' />
      <SignatureModal
        onClickAccept={acceptSignature}
        onClickCancel={() => {
          setIsModalSingnatureOpen(false);
          setIsLoading(false);
        }}
        isModalOpen={isModalSingnatureOpen}
      />
      <BasicModal
        open={alertOpen}
        title={t('pages.bpm.modalSuccess.title')}
        showAcceptButton
        titleButtonAccept={t('pages.bpm.modalSuccess.btnAccept')}
        onClickAccept={redirectProcess}
      >
        <div>
          <div className='text-xl'>
            {t('pages.bpm.modalSuccess.messageSuccess1')}{' '}
            <span className='text-2xl font-bold text-black'>
              {documentInformation?.radicationNumber}
            </span>
          </div>
        </div>
      </BasicModal>
    </PagePrincipal>
  );
};
export default BpmProcess;
