import { regexPassword } from '../../../constants/Regex';
import { IValidatorField } from '../../../hooks/validation/useValidator/types';

export const validations: IValidatorField[] = [
  {
    field: 'oldPassword',
    validations: {
      required: true,
    },
  },
  {
    field: 'newPassword1',
    validations: {
      required: true,
      pattern: {
        message: 'invalidformatPassword',
        value: regexPassword,
      },
    },
  },
  {
    field: 'newPassword2',
    validations: {
      required: true,
      pattern: {
        message: 'invalidformatPassword',
        value: regexPassword,
      },
    },
  },
];
