import React from 'react';
import { Icon } from '@material-ui/core';
import { isUrlInternal, isExternal, getUrl } from '../../utilitys/url';

export const renderImg = (img: string, size?: string, className?: string) => {
  if (isUrlInternal(img) || isExternal(img)) {
    return (
      <img
        src={getUrl(img)}
        style={{ fontSize: size }}
        alt='imagen'
        className={className}
      />
    );
  } else {
    return (
      <Icon style={{ fontSize: size }} className={className}>
        {img}
      </Icon>
    );
  }
};

export const renderText = (history: any, text?: string, urlText?: string) => {
  if (!text) return null;
  if (!urlText) return text;

  if (isUrlInternal(urlText))
    return <p onClick={() => history.push({ urlText })}>{text}</p>;

  return (
    <a className='underline' href={urlText}>
      {text}
    </a>
  );
};
