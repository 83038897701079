import { IValidatorField } from '../../hooks/validation/useValidator/types';

export const validations: IValidatorField[] = [
  {
    field: 'id',
    validations: {
      required: true,
    },
  },
  {
    field: 'password',
    validations: {
      required: true,
    },
  },
];

