import { useState, useEffect } from 'react';
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging
} from 'devextreme-react/data-grid';
import { PagePrincipal } from '../../containers';
import { useTranslation } from 'react-i18next';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';
import { getMyNotifications } from '../../api/notifications';
import { NotificationDto } from '../../dto/NotificationDto';
import RouteNavigation from '../../constants/RouteNavigation';
import { useHistory } from 'react-router-dom';
import { NotificationSourceEnum } from '../../constants/NotificationSourceEnum';
import { BasicModal } from '../../components';
const allowedPageSizes = [5, 10, 50, 100];

const Notifications = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [notifSelected, setNotifSelected] = useState<NotificationDto>();
    const [notifications, setNotifications] = useState<NotificationDto[] | undefined>([]);
  
    useEffect(() => {
        const fetch = async () => {
          const data = await getMyNotifications();
          setNotifications(data || []);
        }
        fetch();
    }, [setNotifications]);

    const handleClickRedirect = (source: NotificationSourceEnum, redirectInfo: string) => {
        const route = source === NotificationSourceEnum.pqrsd ? RouteNavigation.pqrsTrackingDocument : `${RouteNavigation.PortfolioMyExpedients}/1`;
        history.push(`/${route}/${redirectInfo}`);
    }

    const handleClickDescription = (notifDto: NotificationDto) => {
        setNotifSelected(notifDto);
        setOpenModal(true);
    }

    const renderGridCellOriginalDocRad = ({ data }: any) => {
        const { originalDocRad, source, redirectInfo } = data;
        return (
            <p
                onClick={() => handleClickRedirect(source, redirectInfo)}
                className="text-blue-400 hover:text-blue-500 cursor-pointer"
            >
                {originalDocRad}
            </p>
        );
    }

    const renderGridCellCaseNumber = ({ data }: any) => {
        const { originalDocRad, caseNumber, source, redirectInfo } = data;
        if (originalDocRad) return caseNumber;
        return (
            <p
                onClick={() => handleClickRedirect(source, redirectInfo)}
                className="text-blue-400 hover:text-blue-500 cursor-pointer"
            >
                {caseNumber}
            </p>
        );
    }

    const renderGridCellDescription = ({ data }: any) => {
        const { description } = data;

        if (description.length > 50) {
            return  (
                <p>
                    {description.slice(0,50)}...
                    <span
                        onClick={() => handleClickDescription(data)}
                        className='text-blue-400 hover:text-blue-500 cursor-pointer'
                    >
                        Más info
                    </span>
                </p>
            );
        }
        return <p>{description}</p>;
    }

    return (
        <PagePrincipal title={t('pages.notifications.title')}>
            <DataGrid
                dataSource={notifications}
                columnAutoWidth={true}
                allowColumnResizing={true}
                showBorders={true}
                columnHidingEnabled
            >
                <HeaderFilter visible />
                <Paging defaultPageSize={10} />
                <Pager
                    visible
                    showPageSizeSelector
                    allowedPageSizes={allowedPageSizes}
                />
                <FilterRow visible />
                <Column
                    dataField='originalDocRad'
                    dataType='string'
                    cellRender={renderGridCellOriginalDocRad}
                    caption={t('pages.notifications.table.columns.originalDocRad')}
                />
                <Column
                    dataField='caseNumber'
                    dataType='string'
                    cellRender={renderGridCellCaseNumber}
                    caption={t('pages.notifications.table.columns.caseNumber')}
                />
                <Column
                    dataField='description'
                    dataType='string'
                    cellRender={renderGridCellDescription}
                    caption={t('pages.notifications.table.columns.description')}
                />
                <Column
                    dataField='creationDate'
                    dataType='date'
                    caption={t('pages.notifications.table.columns.creationDate')}
                    format='dd/MM/yyyy hh:mm'
                />
                <Column
                    dataField='requestType'
                    dataType='string'
                    caption={t('pages.notifications.table.columns.requestType')}
                />
                <Column
                    dataField='stateDesc'
                    dataType='string'
                    caption={t('pages.notifications.table.columns.stateDesc')}
                />
            </DataGrid>

            <BasicModal
                open={openModal}
                title={`${t('pages.notifications.table.columns.description')}: ${notifSelected?.originalDocRad || notifSelected?.caseNumber}`}
                showAcceptButton
                titleButtonAccept={t('pages.bpm.modalSuccess.btnAccept')}
                onClickAccept={() => setOpenModal(false)}
                maxWidth={'sm'}
            >
                <div className='text-xl'>
                    <p>{ notifSelected?.description }</p>
                </div>
            </BasicModal>
        </PagePrincipal>
    );
};
export default Notifications;