import { useMediaQuery } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface ICaptchaProps {
  onChange: (isCaptchaOk: boolean) => void;
}
const Captcha = ({ onChange }: ICaptchaProps) => {
  const handleOnChangeCaptcha = async (token: string | null) =>
    onChange(token !== null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ReCAPTCHA
      size={isMobile ? 'compact' : 'normal'}
      sitekey={process.env.REACT_APP_PUBLIC_CAPTCHA_SITE_KEY!}
      onChange={handleOnChangeCaptcha}
    />
  );
};
export default Captcha;
