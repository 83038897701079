import { Fragment, useEffect, useRef, useState } from 'react';
import { BasicModal, TimerCountDown } from '../../components';
import { RefreshTokenApi } from '../../api/account';
import { useIdleTimer } from 'react-idle-timer';
import { useStateWithLocalStorage } from '../../hooks/state/useStateWithLocalStorage';
import { parseJwt } from '../../utilitys/jwt';
import { STORAGE_TOKEN } from '../../api/constants';
import { HandleTimerCountDown } from '../../components/TimerCountDown';
import { addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface ISessionValidatorProps {
  onChangeStatusAuth: (token: string) => void;
  children: any;
}

const TIME_COUNT_DOWN_SECONDS = 120;
const TIME_OUT_INACTIVITY_SECONDS = 600;

const SessionValidator = ({
  onChangeStatusAuth,
  children,
}: ISessionValidatorProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useStateWithLocalStorage(STORAGE_TOKEN);
  const claimsToken = token ? parseJwt(token) : undefined;
  const refTimer = useRef(null);

  const handleOnIdle = (event: any) => {
    if(token){
      setIsOpen(true);
      if (refTimer.current)
        (refTimer.current! as HandleTimerCountDown).startTimer(
          TIME_COUNT_DOWN_SECONDS
        );
    }
  };

  const counterFinished = () => {
    setIsOpen(false);
    onChangeStatusAuth('');
  };

  const timer = useIdleTimer({
    timeout: TIME_OUT_INACTIVITY_SECONDS * 1000,
    onIdle: handleOnIdle,
    debounce: 500,
    stopOnIdle: true,
    startManually: true,
  });

  const onRefresToken = async () => {
    const [sucess, newToken] = await RefreshTokenApi();
    if (sucess) setToken(newToken as string);
  };

  if (token) {
    timer.start();
  }
  
  const onAccept = () => {
    if (refTimer.current)
      (refTimer.current! as HandleTimerCountDown).stop();
    setIsOpen(false);
    onRefresToken();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (claimsToken) {
        const expiredTimeToken = claimsToken.exp * 1000;

        if (expiredTimeToken <= Date.now()) {
          clearInterval(interval);
          onChangeStatusAuth('');
        }

        const tokenExpiration2Min = new Date(
          addMinutes(expiredTimeToken, -2)
        ).getTime();
        const currentDateMinus1Min = new Date(
          addMinutes(Date.now(), -1)
        ).getTime();

        const tokenExpiresIn2Minutes = tokenExpiration2Min <= Date.now();
        const lastActivityUserWasInTheLastMinute =
          timer.getLastActiveTime() >= currentDateMinus1Min;

        if (tokenExpiresIn2Minutes && lastActivityUserWasInTheLastMinute) {
          onRefresToken();
        }
      }
    }, 1000);

    if (!claimsToken) clearInterval(interval);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimsToken]);

  return (
    <Fragment>
      {children}
      <BasicModal
        open={isOpen}
        title={t('components.sessionValidator.title')}
        showAcceptButton
        titleButtonAccept={t('components.sessionValidator.btnAccept')}
        onClickAccept={onAccept}
      >
        <div>
          <span className='text-2xl font-bold text-black flex'>
            {t('components.sessionValidator.message')}:
            <div className='pl-3'>
              <TimerCountDown
                ref={refTimer}
                counterFinished={counterFinished}
              />
            </div>
          </span>
        </div>
      </BasicModal>
    </Fragment>
  );
};
export default SessionValidator;
