import { isEmpty, isNumber, get } from 'lodash';
import { EnumValidatorType, IValidatorField } from './types';

export const validateRequired = <T extends object>(
  validation: IValidatorField,
  currentState: T
) : boolean => {
  if (validation.validations.required === false) {
    return true;
  }

  var value = get(currentState, validation.field);
  if (isNumber(value) && value <= 0) {
    return false;
  }
  if (!value) {
    return false;
  }

  if (Array.isArray(value)) {
    if (value.length < 1) {
       return false;
    }
  }

  return true;
};

export const validateRegex = <T extends object>(
  validation: IValidatorField,
  currentState: T
) : boolean => {
  if (isEmpty(validation.validations.pattern)) return true;
  const value = get(currentState, validation.field);
  const regex = validation.validations.pattern!.value;
  return regex.test(value);
};

export const validateWhere = <T extends object>(
  validation: IValidatorField,
  currentState: T
) : boolean => {
  if (!validation.validations.where) return true;
  const {
    where: { fieldParent, value, required, pattern, validationType },
  } = validation.validations;
  
  const valueFiledtoParent = get(currentState, fieldParent);

  if(validationType === EnumValidatorType.Value && valueFiledtoParent !== value) return true;
  if(validationType === EnumValidatorType.Length && `${valueFiledtoParent}`.length < value) return true;
 
  const validationCustom: IValidatorField = {
    field: validation.field,
    validations: {
      required,
      pattern
    },
  };
  return validateRequired(validationCustom, currentState);
};

export const validateWhereRegex= <T extends object>(
  validation: IValidatorField,
  currentState: T
) : boolean => {

  if (!validation.validations.where) return true;
  const {
    where: { fieldParent, value, required, pattern, validationType },
  } = validation.validations;
  
  const valueFiledtoParent = get(currentState, fieldParent);
  
  if(validationType === EnumValidatorType.Value && valueFiledtoParent !== value) return true;
  if(validationType === EnumValidatorType.Length && `${valueFiledtoParent}`.length < value) return true;

  const validationCustom: IValidatorField = {
    field: validation.field,
    validations: {
      required,
      pattern
    },
  };

  return validateRegex(validationCustom, currentState);
};

