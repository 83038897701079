import React, { memo, forwardRef, useImperativeHandle, useState } from 'react';
import { TextFieldDate } from '../../components';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import { convertDateToString, convertToDate } from '../../utilitys/date';
import {
  HandleDocumentMetaDataChild,
  ResultDocumentMetaDataChild,
  TypeControl,
} from './types';

interface ITextFieldMemoProps {
  ref: any;
  error?: string;
  value?: string;
  metaData: MetaDataConfigurationDocumentaryTypeDto;
}

const DateTextBoxMemo = memo(
  forwardRef(
    (
      props: ITextFieldMemoProps,
      ref: React.Ref<HandleDocumentMetaDataChild | undefined>
    ) => {
      const {
        value,
        error,
        metaData: {
          isRequired,
          label,
          id,
          disabled,
          initial,
          dicSec,
          attributeFormId,
          isEditable
        },
      } = props;
      const [valueLocal, setValueLocal] = useState<string>(value ? value : convertDateToString(new Date()));

      const onChange = (value: string) => {
        setValueLocal(value);
      };

      useImperativeHandle(ref, () => ({
        getData() {
          return {
            id,
            value: valueLocal,
            text: '',
            typeElement: TypeControl.text,
            label: label,
            initial: initial,
            dicSec: dicSec,
            attributeFormId,
          } as ResultDocumentMetaDataChild;
        },
      }));
      return (
        <TextFieldDate
          disabled={disabled || !isEditable}
          isOutlined
          isRequired={isRequired}
          label={label || ''}
          value={convertToDate(valueLocal)}
          error={error}
          onChangeValueFormatISO={onChange}
          
        />
      );
    }
  )
);

export default DateTextBoxMemo;
