import React from 'react';
import { Icon } from '@material-ui/core';

const isExternal = (img: string) =>
  img.startsWith('http') || img.startsWith('https');

const isInternal = (img: string) => img.startsWith('/');

export const renderImg = (img: string, size?: string, className?: string) => {
  if (isInternal(img) || isExternal(img)) {
    return <img src={img} style={{ fontSize: size }} alt='imagen' className={className} />;
  } else {
    return <Icon style={{ fontSize: size }} className={className}>{img}</Icon>;
  }
};

export const renderText = (history: any, text?: string, urlText?: string) => {
  if (!text) return null;
  if (!urlText) return text;
  
  if (isInternal(urlText))
    return <span onClick={() => history.push({ urlText })}>{text}</span>;

  return (
    <a className='underline text-blue-600' href={urlText} target='_blank' rel='noreferrer'>
      {text}
    </a>
  );
};
