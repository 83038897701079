import React, { useContext, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { PagePrincipal } from '../../../containers';
import { Container, Paper } from '@material-ui/core';
import { Alert, ButtonCaptcha, TextField } from '../../../components';
import { useTranslation } from 'react-i18next';
import useValidator from '../../../hooks/validation/useValidator/useValidator';
import { IFormState } from './types';
import { validations } from './validations';
import { IAlertTypes } from '../../../components/Alert';
import { useAlert } from '../../../hooks/state/useAlert';
import { changePasswordVUVApi } from '../../../api/user';
import { userContext } from '../../../hooks/contexts/userContext';
import { useStateWithLocalStorage } from '../../../hooks/state/useStateWithLocalStorage';
import { STORAGE_TOKEN } from '../../../api/constants';
import { TypeTextField } from '../../../components/TextField';
import { RefreshTokenApi } from '../../../api/account';

const ChangePassword = () => {
  const { t } = useTranslation();
  const [isVisible, openAlert, hideAlert] = useAlert(false);
  const [isVisibleError, openAlertError, hideAlertError] = useAlert(false);
  const { setCurrentUser } = useContext(userContext);
  const [,setToken] = useStateWithLocalStorage<string>(STORAGE_TOKEN);
  const [formState, setFormState] = useState<IFormState>({
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
  });

  const onChange = (propName: string) => (value: any) => {
    const formStateClone = { ...formState, [propName]: value };
    setFormState(formStateClone);
  };

  const validationsChangePw = () => {
    if (!startValidations(formState, validations)) return;
    if (formState.newPassword1 !== formState.newPassword2) {
      openAlertError();
      return;
    }
    submit();
  };

  const submit = async () => {
    const resultChangePw = await changePasswordVUVApi(
      formState.oldPassword,
      formState.newPassword2
    );
    const [suceess, result] = await RefreshTokenApi();
    if (!resultChangePw || !suceess) {
      openAlert();
      setCurrentUser(undefined);
      setToken('');
      window.location.replace(window.location.origin);
      return;
    }
    setToken(result as string);
    window.location.replace(window.location.origin);
  };

  const [formStateError, startValidations] = useValidator<IFormState>();

  const errorNotEqualsPs = () => (isVisibleError ? '   ' : '');

  return (
    <PagePrincipal>
      <div>
        <div className='text-center'>
          <Typography variant='h4' color='primary'>
            {t('pages.user.changePw.title')}
          </Typography>
          <br />
          <br />
          <Container component={Paper} className='p-20 text-left'>
            <div className='w-full flex flex-col items-center '>
              <div className='w-96 space-y-4'>
                <TextField
                  isRequired
                  isOutlined
                  type={TypeTextField.password}
                  label={t(`pages.user.changePw.oldPassword`)}
                  value={formState.oldPassword}
                  error={formStateError?.oldPassword}
                  onChange={onChange('oldPassword')}
                />
                <TextField
                  isRequired
                  isOutlined
                  type={TypeTextField.password}
                  label={t(`pages.user.changePw.newPassword1`)}
                  value={formState.newPassword1}
                  error={formStateError?.newPassword1 || errorNotEqualsPs()}
                  onChange={onChange('newPassword1')}
                />
                <TextField
                  isRequired
                  isOutlined
                  type={TypeTextField.password}
                  label={t(`pages.user.changePw.newPassword2`)}
                  value={formState.newPassword2}
                  error={formStateError?.newPassword2 || errorNotEqualsPs()}
                  onChange={onChange('newPassword2')}
                />
                <label className='italic text-sm pt-4'>
                  {t('pages.user.changePw.formatPw')}
                </label>
                <ButtonCaptcha
                  onClick={() => validationsChangePw()}
                  textButton={t('pages.user.changePw.textButton')}
                />
              </div>
            </div>
          </Container>
        </div>
        <div className='pl-40 pr-40'></div>
      </div>
      <Alert
        open={isVisible}
        type={IAlertTypes.success}
        onClickClose={hideAlert}
      >
        <span className='text-lg'>
          {t('pages.user.changePw.messageSuccess')}
        </span>
      </Alert>
      <Alert
        open={isVisibleError}
        type={IAlertTypes.error}
        onClickClose={hideAlertError}
      >
        <span className='text-lg'>
          {t('pages.user.changePw.errorMessageNotEqual')}
        </span>
      </Alert>
    </PagePrincipal>
  );
};
export default ChangePassword;
