import { IRoutesAccess } from '../containers/Authorization/types';
import RouteNavigation from '../constants/RouteNavigation';
import {
  ManualsPage, NormativityOfficePage, PoliticsPage, SearchPage, TechnicalRequirements,
} from '../pages';

export const routesOurSite: IRoutesAccess[] = [
  {
    url: RouteNavigation.OurSiteRegulation,
    component: NormativityOfficePage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.OurSiteTechnicalRequirements,
    component: TechnicalRequirements,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.OurSitePolitics,
    component: PoliticsPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.OurSiteManual,
    component: ManualsPage,
    allowAnonymous: true,
  },
  {
    url: `${RouteNavigation.OurSearch}/:text`,
    component: SearchPage,
    allowAnonymous: true,
  }
];
