import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Configuration } from '../..';
import { CurrentTime, SearchBar } from '../../../components';
import Menu from './menu';
import { useTranslation } from 'react-i18next';
import DrawerMenu from '../Common/DrawerMenu';
import contextApp from '../../../hooks/contexts/contextApp';
import { useHistory } from 'react-router-dom';
import RouteNavigation from '../../../constants/RouteNavigation';
import TopBar from '../Common/topBar';

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    background: `${theme.palette.primary.main}`,
  },
  backgroundDefault: {
    background: `${theme.palette.background.default}`,
  },
  backgroundPaper: {
    background: `${theme.palette.background.paper}`,
  },
  text: {
    color: `${theme.palette.text.primary}`,
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

interface IHeaderHorizontalProps {
  themeSelected: string;
  onChangeTheme: (theme: string) => void;
  onChangeStatusAuth: (token: string) => void;
  onOpenLogin: () => void;
}

const HeaderHorizontal = ({
  themeSelected,
  onChangeTheme,
  onChangeStatusAuth,
  onOpenLogin,
}: IHeaderHorizontalProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const contextApplication = useContext(contextApp);

  const handleDrawerToogleMobile = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const onSearch = (value: string) => {
    if(!value) return;
    history.replace(`/${RouteNavigation.OurSearch}/${value}`);
  };

  return (
    <div>
      <TopBar />
      <div
        className={clsx(
          classes.backgroundDefault,
          'flex w-full h-10 p-2 flex-row-reverse'
        )}
      >
        <Configuration
          themeSelected={themeSelected}
          onChangeTheme={onChangeTheme}
        />
      </div>

      <Toolbar className={clsx(classes.backgroundDefault)} >
        <div className='w-full grid grid-cols-1 sm:grid-cols-4 gap-2 mb-3 px-3'>
          <div className='sm:col-span-3 h-auto flex justify-center'>
              <img
                src={`${contextApplication.urlLogoHeader}`}
                alt='logo'
                className='flex flex-col w-min'
              />
          </div>

          <div className="sm:col-span-1">
            <div className='flex flex-row justify-center h-full'>
              <div className='mr-5 flex items-center'>
                <SearchBar
                  placeholder={t('navigation.searchbar')}
                  onSearch={onSearch}
                />
              </div>

              <div className="flex items-center">
                <div className='flex flex-col text-right'>
                  <CurrentTime
                    classNameTime={clsx(
                      classes.text,
                      'text-2xl font-bold'
                    )}
                    classNameDate={clsx(classes.text)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
        <br />
      </Toolbar>
      <Menu
        onClickMenu={() => handleDrawerToogleMobile(true)}
        onClickOpenLogin={() => onOpenLogin()}
        onChangeStatusAuth={onChangeStatusAuth}
      />
      <DrawerMenu
        showUserNavbar
        isMobile
        isOpen={open}
        onCloseDrawer={() => handleDrawerToogleMobile(false)}
        onChangeStatusAuth={onChangeStatusAuth}
      />
    </div>
  );
};

export default HeaderHorizontal;
