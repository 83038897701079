import { useEffect, useState } from 'react';
import { AccordionProcess, PagePrincipal } from '../../../containers';
import { useTranslation } from 'react-i18next';
import { getProcessByLangApi } from '../../../api/process';
import { ProcessDto, ProcessTagDto } from '../../../dto/Process';
import i18n from '../../../i18n';
import Filtre from './filtres';
import { Pagination } from '../../../components';
import { PageSizeSelector } from '../../../components/PageSizeSelector';

export default function ProcessHome() {
  const { t } = useTranslation();
  const [processList, setProcessList] = useState<ProcessDto[]>([]);
  const [processFiltred, setProcessFiltred] = useState<ProcessDto[]>([]);
  const [processPaginated, setProcessPaginated] = useState<ProcessDto[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    const fetch = async () => {
      const process = await getProcessByLangApi();
      setProcessList(process);
      setProcessFiltred(process);
      setCurrentPage(1);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onFiltre = (search: string, process: ProcessTagDto | undefined) => {
    if (!process && !search) {
      setProcessFiltred(processList);
      return;
    }
    let listFiltred: ProcessDto[] = [...processList];

    if (process && process.father) {
      listFiltred = listFiltred.filter((p) =>
        p.tags.some(
          (x) =>
            x.father.toLowerCase() === process.father.toLowerCase() &&
            (process.son ? x.son.toLowerCase() === process.son.toLowerCase() : true)
        )
      );
    }

    if (search) {
      listFiltred = [...listFiltred].filter(
        (p) => 
          p.title.toLowerCase().includes(search.toLowerCase()) ||
          p.cost.toLowerCase().includes(search.toLowerCase()) ||
          p.duration.toLowerCase().includes(search.toLowerCase())
      );
    }
    setProcessFiltred(listFiltred);
    setCurrentPage(1);
  };

  const paginationChange = (page: number) => setCurrentPage(page);

  const pageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  }

  useEffect(() => {
    let listPaginated: ProcessDto[] = [...processFiltred];
    listPaginated = [...listPaginated].slice((currentPage - 1) * pageSize, currentPage * pageSize);
    setProcessPaginated(listPaginated);
  }, [currentPage, pageSize, processFiltred]);

  return (
    <PagePrincipal title={t('pages.processHome.title')}>
      <div className='flex flex-col sm:items-center md:items-start space-y-5 md:flex-row p-0 space-x-5 space-y-5 '>
        <div className='w-96 h-full max-w-sm' >
          <Filtre
            tags={processList.map((p) => p.tags).flat()}
            onFiltre={onFiltre}
          />
        </div>
        <div className='w-full h-full'>
          <div className="flex justify-between">
            {
              (processFiltred.length > pageSize) ?
              (
                <Pagination 
                  page={currentPage}
                  total={processFiltred.length}
                  recordsPerPage={pageSize}
                  onChangePage={paginationChange}
                />
              ) : <div></div>
            }
            <PageSizeSelector
              pageSize={pageSize}
              options={[5,10,25,50]}
              onChangeSize={pageSizeChange}
            />
          </div>

          {
            processPaginated.length === 0 &&
            (
              <div className='mt-3 w-full grid justify-items-center'>
                <div className='text-lg font-title'>{t('pages.processHome.emptyData')}</div>
              </div>
            )
          }

          {processPaginated.map((p) => (
            <AccordionProcess
              idBpm={p.idBpm}
              id={p.id}
              key={p.id}
              title={p.title}
              duration={p.duration}
              price={p.cost}
              description={p.description}
              isAccessibleByUser={p.isAccessibleByUser}
              documentsUrl={p.documentsUrl}
              idDocumentType={p.idDocumentType}
            />
          ))}
        </div>
      </div>
    </PagePrincipal>
  );
}
