import { API_BASE } from '../constants';
import { getData } from '../_base';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { TechnicalRequirementsDto } from '../../dto/admin/types';

const base = `${API_BASE}/v1/TechnicalRequirements/TechnicalRequirements`;

const url = {
  getByLanguage: `${base}`,
};

export const getTechnicalRequirementByLangApi = async (): Promise<
  TechnicalRequirementsDto[]
> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getByLanguage}/${lngDto.id}`;
  const result = await getData<TechnicalRequirementsDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data;
  else return [];
};
