import React, { useContext } from 'react';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { IconButton, ListItemIcon, makeStyles, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useTranslation } from 'react-i18next';
import RouteNavigation from '../../../constants/RouteNavigation';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useHistory } from 'react-router-dom';
import { userContext } from '../../../hooks/contexts/userContext';
import NotificationBell from '../../../containers/Notifications';
import contextApp from '../../../hooks/contexts/contextApp';

interface IUserMenuProps {
  onLogOut: () => void;
  showInformation?: boolean;
}

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.text.primary,
  },
}));


const UserMenu = ({ onLogOut, showInformation }: IUserMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { userAuth } = useContext(userContext);
  const history = useHistory();
  const contextApplication = useContext(contextApp);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickMenu = (url?: string) => {
    setAnchorEl(null);
    if (url) history.push(`/${url}`);
  };

  return (
    <div className='h-full flex justify-end items-center'>
      <div className="mr-7">
        <NotificationBell />
      </div>

      {showInformation && (
        <div className='flex flex-col'>
          <span className={clsx(classes.textPrimary,'italic space-x-2 text-base mt-3 mr-2')}>
            {t('components.userHeader.wellcome')}
          </span>
          <span className={clsx(classes.textPrimary,'italic space-x-2 text-base mr-2')}>
            {userAuth?.fullName}
          </span>
        </div>
      )}
      <IconButton
        color='inherit'
        size='medium'
        onClick={handleMenu}
        className='h-full'
      >
        <AccountCircle fontSize='large' color='primary' />
      </IconButton>
      <Menu
        id='menu-appbar'
        style={{ position: contextApplication.layout === 0 ? 'fixed' : 'absolute' }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onClickMenu(RouteNavigation.UserAccount)}>
          <ListItemIcon
            onClick={() => onClickMenu(RouteNavigation.UserAccount)}
          >
            <SettingsIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>
           {t('components.userHeader.account')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => onClickMenu(RouteNavigation.UserChangePassword)}
        >
          <ListItemIcon
            onClick={() => onClickMenu(RouteNavigation.UserChangePassword)}
          >
            <LockOpenIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>
            {t('components.userHeader.recoveryPw')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => onLogOut()}>
          <ListItemIcon onClick={() => onLogOut()}>
            <LockOpenIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>
            {t('components.userHeader.logout')}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};
export default UserMenu;
