import React, { Fragment } from 'react';
import clsx from 'clsx';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, TextField } from '@material-ui/core';
import { Required, TooltipHelp } from '.';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    '& .MuiFormLabel-root ': {
      color: theme.palette.text.primary,
      marginBottom: '20px',
    },
    '& .MuiInputBase-root ': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  text: {
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  },
}));

interface IAutocompleteStyledProps {
  onChange: (e: any, value: any | null) => void;
  label?: string;
  isOutlined?: boolean;
  isRequired?: boolean;
  error?: string;
  textHelp?: string;
}

export default function AutocompleteStyled<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: IAutocompleteStyledProps &
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element {
  const classes = useStyles();
  const hasError = !isEmpty(props.error);
  const { isRequired, isOutlined, textHelp, ...extraprops } = props;

  return (
    <Fragment>
      <Autocomplete
        {...extraprops}
        className={clsx(classes.root)}
        autoHighlight
        renderInput={(params) => (
          <Fragment>
            {props.label && (
              <div className='h-10'>
                <InputLabel className={classes.text}>
                  {props.label} {isRequired && <Required />}
                  {textHelp && <TooltipHelp text={textHelp} />}
                </InputLabel>
              </div>
            )}
            <TextField
              {...params}
              error={hasError}
              variant={isOutlined ? 'outlined' : 'standard'}
              placeholder=''
              className={classes.root}
            />
          </Fragment>
        )}
      />
      {hasError && (
        <span className='font-bold text-red-600 italic ml-5'>
          {props.error}
        </span>
      )}
    </Fragment>
  );
}
