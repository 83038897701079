export const hexToRGB = (color: string, opacity?: number) => {
  let r = '',
    g = '',
    b = '';
  if (color.length === 4) {
    r = '0x' + color[1] + color[1];
    g = '0x' + color[2] + color[2];
    b = '0x' + color[3] + color[3];
  } else if (color.length === 7) {
    r = '0x' + color[1] + color[2];
    g = '0x' + color[3] + color[4];
    b = '0x' + color[5] + color[6];
  }
  const colorRGB = `rgb(${+r},${+g},${+b},${opacity ? opacity : 1})`;
  return  colorRGB;
};
