import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
// import CancelIcon from '@material-ui/icons/Cancel';
import { KeyCodes } from '../constants/KeyCode';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderBottom: `1px ${theme.palette.primary.main} solid`,
  },
  input: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

interface ISearchBarProps {
  placeholder?: string;
  onSearch: (value: string) => void;
}

const SearchBar = ({ placeholder, onSearch }: ISearchBarProps) => {
  const [value, setValue] = useState<string>('');
  const classes = useStyles();

  const keyPress = (e: any) => {
    if (e.keyCode === KeyCodes.Enter) {
      setValue('')
      onSearch(e.target.value);
    }
  };

  const onChange = (e: any) => {
    setValue(e?.target?.value);
    if (!e?.target?.value){
      setValue('');
      onSearch('');
    } 
  };

  return (
    <Paper className={classes.root}>
      <TextField
        className={classes.input}
        placeholder={placeholder}
        onKeyDown={keyPress}
        onChange={onChange}
        value={value}
      />
      <IconButton
        type='button'
        color='primary'
        className={classes.iconButton}
        aria-label='search'
        onClick={() => {
          onSearch(value ? value : '');
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
