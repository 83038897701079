import { ContactMetaDataDto } from '../../dto/ContactDto';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { API_ECM } from '../constants';
import { getData } from '../_base';

const base = `${API_ECM}/v2`;

const url = {
  getMetaData: `${base}/Contact/MetaData`,
  hasMetaData: `${base}/Contact/HasMetaData`,
}

export const getMetaDataApi = async (
  personTypeId: number 
): Promise<ContactMetaDataDto[]> => {
  const lngDto = getCurrentLanguageDto();
    const _url = `${url.getMetaData}/${lngDto.id}/${personTypeId}`;
    const result = await getData<ContactMetaDataDto[]>(_url);
    if (result && !result.hasError && result.data ) return result.data;
    else return [];
};

export const hasMetaData = async (
  personTypeId: number 
): Promise<boolean> => {
    const _url = `${url.hasMetaData}/${personTypeId}`;
    const result = await getData<boolean>(_url);
    if (result && !result.hasError && result.data ) return result.data;
    else return false;
};
