import { useCallback, useState } from 'react';
import { IUserDtoJwt } from '../../dto/UserDto';
import { UserContext } from '../contexts/userContext';

const useUser = (): UserContext => {
  const [userAuth, setUserValue] = useState<IUserDtoJwt | undefined | null>();

  const setCurrentUser = useCallback((value: IUserDtoJwt| undefined | null): void => {
    setUserValue(value);
  }, []);

  return {
    userAuth,
    setCurrentUser,
  };
};

export default useUser;