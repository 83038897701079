import React, { Fragment, useContext } from 'react';
import clsx from 'clsx';
import { Button, Icon, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { IHeaderProps } from '../types';
import { Drawer, useMediaQuery, useTheme } from '@material-ui/core';
import { Configuration, UserNavbarHeader } from '../..';
import MenuOptions from './menu';
import { userContext } from '../../../hooks/contexts/userContext';
import { CurrentTime, SearchBar } from '../../../components';
import contextApp from '../../../hooks/contexts/contextApp';
import { useTranslation } from 'react-i18next';
import TopBar from '../Common/topBar';
import RouteNavigation from '../../../constants/RouteNavigation';
import UserMenu from '../Common/userMenu';
import { useHistory } from 'react-router';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    padding: 0,
    margin: 0,
    height: '180px',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    height: '100vh',
  },
  contentMobile: {
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  bodyChildren: {
    height: '100vh',
  },
  text: {
    color: `${theme.palette.text.primary}`,
  },
  backgroundDefault: {
    background: `${theme.palette.background.default}`,
  },
}));

const HeaderVertical = ({
  onChangeStatusAuth,
  onChangeTheme,
  children,
  themeSelected,
  onOpenLogin,
}: IHeaderProps) => {
  const classes = useStyles();
  const { userAuth } = useContext(userContext);
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const contextApplication = useContext(contextApp);
  const history = useHistory();
  const { t } = useTranslation();
  const handleDrawerToogle = () => {
    setOpen(!open);
  };

  const handleDrawerToogleMobile = () => {
    setOpen(false);
  };

  const onClickItem = (url: string, isExtUrl: boolean) => {
    if (isExtUrl) {
      window.location.replace(url);
    }
    if (url) history.push(`/${url}`);
  };

  const onSearch = (value: string) => {
    if (!value) return;
    history.replace(`/${RouteNavigation.OurSearch}/${value}`);
  };

  return (
    <div className={classes.root}>
      <AppBar
        color='inherit'
        className={clsx({
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar disableGutters>
          <div className='invisible absolute ml-10 md:visible'>
            <IconButton
              edge='start'
              onClick={handleDrawerToogle}
              color='inherit'
              aria-label='menu'
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className='w-full flex flex-col'>
            <TopBar />
            <div className='flex'>
              <div className='flex w-full h-10 p-2 flex-row-reverse'>
                <Configuration
                  themeSelected={themeSelected}
                  onChangeTheme={onChangeTheme}
                />
              </div>
            </div>

            <div className='w-full flex flex-col md:flex-row space-x-10 justify-between md:justify-center mb-4'>
              <img
                src={`${contextApplication.urlLogoHeader}`}
                alt='logo'
                className='flex flex-col w-min max-w-md'
              />
              <div>
                <div className='flex'>
                  <div className='flex flex-row justify-center h-full'>
                    <div className='mr-5 flex items-center'>
                      <SearchBar
                        placeholder={t('navigation.searchbar')}
                        onSearch={onSearch}
                      />
                    </div>

                    <div className='flex items-center'>
                      <div className='flex flex-col text-right'>
                        <CurrentTime
                          classNameTime={clsx(
                            classes.text,
                            'text-2xl font-bold'
                          )}
                          classNameDate={clsx(classes.text)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={clsx(
                'flex justify-end text-2xl',
                isMobile ? 'mt-0' : '-mt-16'
              )}
            >
              {isMobile ? (
                <div className='w-full flex justify-around'>
                  <Button
                    aria-controls={`simple_menu_menu`}
                    aria-haspopup='true'
                    onClick={handleDrawerToogle}
                    variant='text'
                    className={clsx('font-bold visible sm:invisible')}
                    startIcon={<Icon>menu</Icon>}
                  >
                    {t('navigation.menu')}
                  </Button>
                  {!userAuth ? (
                    <Fragment>
                      <IconButton
                        aria-label='login'
                        color='secondary'
                        size='small'
                        onClick={() => {
                          onOpenLogin();
                        }}
                      >
                        <Icon className=''>person</Icon>
                      </IconButton>
                      <IconButton
                        aria-label='register'
                        color='secondary'
                        size='small'
                        onClick={() =>
                          onClickItem(RouteNavigation.UserRegister, false)
                        }
                      >
                        <Icon>app_registration</Icon>
                      </IconButton>
                    </Fragment>
                  ) : (
                    <UserMenu
                      onLogOut={() => {
                        onChangeStatusAuth('');
                      }}
                    />
                  )}
                </div>
              ) : (
                <Fragment>
                  {!userAuth ? (
                    <Fragment>
                      <IconButton
                        aria-label='login'
                        color='secondary'
                        onClick={onOpenLogin}
                      >
                        <Icon fontSize='large'>person</Icon>
                      </IconButton>
                      <IconButton
                        aria-label='register'
                        color='secondary'
                        onClick={() =>
                          onClickItem(RouteNavigation.UserRegister, false)
                        }
                      >
                        <Icon fontSize='large'>app_registration</Icon>
                      </IconButton>
                    </Fragment>
                  ) : (
                    <UserMenu
                      onLogOut={() => {
                        onChangeStatusAuth('');
                      }}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToogleMobile}
      >
        <UserNavbarHeader onChangeStatusAuth={onChangeStatusAuth} />
        <MenuOptions />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
          [classes.contentMobile]: isMobile,
        })}
      >
        <div className={classes.drawerHeader} />
        <div className={classes.bodyChildren}>{children}</div>
      </main>
    </div>
  );
};

export default HeaderVertical;
