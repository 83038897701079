import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { default as TextFieldM } from '@material-ui/core/TextField';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useState } from 'react';
import { TooltipHelp } from '.';
import Required from './Required';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
export enum TypeTextField {
  number,
  text,
  currency,
  password,
}

interface ITextFieldProps {
  label?: string;
  isRequired?: boolean;
  error?: string;
  value?: string | Date | number;
  type?: TypeTextField;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  isOutlined?: boolean;
  placeholder?: string;
  centerText?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  textHelp?: string;
  maxLength?: number;
  onChange?: (value: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  text: {
    width: '100%',
    color: theme.palette.text.primary,
  },
}));

const TextField = ({
  isRequired,
  label,
  error,
  value,
  type,
  autoFocus,
  multiline,
  rows,
  isOutlined,
  placeholder,
  centerText,
  disabled,
  autoComplete,
  onChange,
  textHelp,
  maxLength,
}: ITextFieldProps) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const hadleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (maxLength && event.target.value.length > maxLength) return;
    if (onChange) onChange(event.target.value);
  };

  const hasError = !isEmpty(error);

  const getType = () => {
    switch (type) {
      case TypeTextField.currency:
        return 'number';
      case TypeTextField.number:
        return 'number';
      case TypeTextField.password:
        return showPassword ? 'text' : 'password';
      case TypeTextField.text:
        return 'text';
      default:
        return 'text';
    }
  };

  const getButtonVisibility = () => (
    <div className='cursor-pointer'>
      {showPassword ? (
        <IconButton onClick={() => setShowPassword(false)}>
          <VisibilityOffIcon />
        </IconButton>
      ) : (
        <IconButton onClick={() => setShowPassword(true)}>
          <VisibilityIcon />
        </IconButton>
      )}
    </div>
  );

  return (
    <Fragment>
      {label && (
        <div className='h-10'>
          <InputLabel className={classes.text}>
            {label} {isRequired && <Required />}
            {textHelp && <TooltipHelp text={textHelp} />}
          </InputLabel>
        </div>
      )}
      {isOutlined ? (
        <TextFieldM
          variant='outlined'
          type={getType()}
          error={hasError}
          className={classes.root}
          value={value}
          onChange={hadleOnChange}
          autoFocus={autoFocus}
          multiline={multiline}
          fullWidth
          rows={rows}
          placeholder={placeholder}
          inputProps={{
            maxLength: maxLength,
            style: { textAlign: centerText ? 'center' : 'initial' },
          }}
          disabled={disabled}
          autoComplete={autoComplete}
          InputProps={{
            className: classes.text,
            startAdornment:
              type === TypeTextField.currency ? <span>$</span> : undefined,
            endAdornment:
              type === TypeTextField.password
                ? getButtonVisibility()
                : undefined,
          }}
        />
      ) : (
        <TextFieldM
          variant='standard'
          type={getType()}
          error={hasError}
          className={classes.root}
          value={value}
          onChange={hadleOnChange}
          autoFocus={autoFocus}
          multiline={multiline}
          fullWidth
          rows={rows}
          placeholder={placeholder}
          inputProps={{
            maxLength: maxLength,
            style: { textAlign: centerText ? 'center' : 'inherit' },
          }}
          disabled={disabled}
          autoComplete={autoComplete}
          InputProps={{
            className: classes.text,
            startAdornment:
              type === TypeTextField.currency ? <span>$</span> : undefined,
            endAdornment:
              type === TypeTextField.password
                ? getButtonVisibility()
                : undefined,
          }}
        />
      )}

      {hasError && (
        <span className='font-bold text-red-600 italic ml-5'>{error}</span>
      )}
    </Fragment>
  );
};

export default TextField;
