import { Button, Container, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getProcessByLangApi } from '../../../api/process';
import { PagePrincipal } from '../../../containers';
import { ProcessDto } from '../../../dto/Process';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { DangerouslySetInnerHTML } from '../../../components';
import RouteNavigation from '../../../constants/RouteNavigation';
import { userContext } from '../../../hooks/contexts/userContext';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ProcessDocuments from '../../../components/ProcessDocuments';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface IProcessDetailParams {
  processId: string;
}

const ProcessDetail = () => {
  const { t } = useTranslation();
  const { processId } = useParams<IProcessDetailParams>();
  const [process, setProcess] = useState<ProcessDto>();
  const history = useHistory();
  const { userAuth } = useContext(userContext);
  const [hasProcessDocuments, setHasProcessDocuments] = useState<Boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      const processList = await getProcessByLangApi();
      const currentProcess = processList.find(
        (p) => p.id.toString() === processId
      );
      setProcess(currentProcess);
      if (process?.documentsUrl && process.documentsUrl.length > 0) setHasProcessDocuments(true);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, processId, process?.documentsUrl]);

  const OnStartProcess = () => {
    if (process) {
      const path = userAuth ? `/${RouteNavigation.ProcessBpm}/${process?.idBpm}/${process.id}` : `/${RouteNavigation.FocerLogin}`;
      history.push(path);
    }
  };

  return (
    <PagePrincipal>
      <div className='grid grid-cols-5 mt-5 mb-16'>
        <div className='col-span-1'></div>
        <div className='col-span-3 space-y-6'>
          <Button
            onClick={() => { history.push(`/${RouteNavigation.Process}`); }}
            variant='contained'
            color='primary'
            startIcon={<ArrowBackIcon />}
          >
            {t(`pages.search.modules.PROCEDURES`)}
          </Button>
          <div className='text-center'>
            <Typography variant='h4' color='primary'>
              {process?.title}
            </Typography>

            <Typography variant='h5' color='secondary'>
              <div className='space-x-5 flex justify-center'>
                <QueryBuilderIcon className='mt-1' />
                <span>{process?.duration}</span>
                <LocalAtmIcon className='mt-1' />
                <span> {process?.cost}</span>
              </div>
            </Typography>
            <br />
          </div>

          <Container>
            <div className='w-full'>
              {!userAuth || process?.isAccessibleByUser ? (
                <div className={`flex mb-8 ${hasProcessDocuments ? 'justify-between' : 'justify-end'}`}>
                  {(process && hasProcessDocuments) && <ProcessDocuments documentsUrl={process.documentsUrl} /> && process?.idDocumentType != null}
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={OnStartProcess}
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    {t('pages.processHome.start')}
                  </Button>
                </div>
              ) : process?.idDocumentType == null ? '' : (
                <>
                  {(process && hasProcessDocuments) && <ProcessDocuments documentsUrl={process.documentsUrl} />}
                  <div className='font-bold italic text-lg text-red-500 text-center my-5' >
                    {t('pages.processHome.processItIsNotAccessibleByUser')}
                  </div>
                </>
              )}
            </div>
            {process?.description && (
              <div className='h-full mt-1 p-4 max-w-sm md:max-w-md lg:max-w-full'>
                <DangerouslySetInnerHTML text={process.description} />
              </div>
            )}
            {/* <div className='text-lg pb-4 text-justify' >{process?.text}</div> */}
          </Container>
        </div>
      </div>
    </PagePrincipal>
  );
};
export default ProcessDetail;
