import React from 'react';
import Section from '../common/section';
import Descriptions from '../common/description';
import Title from '../common/title';
import { getUrl } from '../../../utilitys/url';

interface IPanel2Props {
  title: string;
  tagLeft: string;
  tagRight: string;
  description: string;
  description2: string;
  img: string;
  url?: string;
}

const Panel2 = ({
  title,
  tagLeft,
  tagRight,
  description,
  description2,
  img,
  url,
}: IPanel2Props) => {
  return (
    <Section tagRight={tagRight} tagLeft={tagLeft}>
      <Title title={title} url={url} center></Title>
      <img
        className='float-left mt-2 ml-0 mb-4 mr-10 max-h-56'
        src={getUrl(img)}
        alt='imagen'
      />
      <Descriptions description1={description} description2={description2} />
    </Section>
  );
};
export default Panel2;
