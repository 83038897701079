import React from 'react';
interface IDescriptionsProps {
  description1: string;
  description2: string;
  description3: string;
  description4: string;
}
const Descriptions = ({ description1, description2, description3, description4 }: IDescriptionsProps) => {
  return (
    <div className='pt-5 font-body'>
      <pre className='pb-5 whitespace-pre-line font-sans text-justify text-lg font-body'>{description1}</pre>
      <pre className='pb-5 whitespace-pre-line  font-sans text-justify text-lg font-body'>{description2}</pre>
      <pre className='pb-5 whitespace-pre-line  font-sans text-justify text-lg font-body'>{description3}</pre>
      <pre className='pb-5 whitespace-pre-line  font-sans text-justify text-lg font-body'>{description4}</pre>
    </div>
  );
};
export default Descriptions;
