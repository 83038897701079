import React, { memo, forwardRef, useImperativeHandle, useState } from 'react';
import { TextField } from '../../components';
import { TypeTextField } from '../../components/TextField';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import {
  HandleDocumentMetaDataChild,
  ResultDocumentMetaDataChild,
  TypeControl,
  TypeControlData,
} from './types';

interface ITextFieldMemoProps {
  ref: any;
  error?: string;
  value?: string | Date | number;
  autoFocus?: boolean;
  textHelp?: string;
  metaData: MetaDataConfigurationDocumentaryTypeDto;
}

const TextFieldMemo = memo(
  forwardRef(
    (
      props: ITextFieldMemoProps,
      ref: React.Ref<HandleDocumentMetaDataChild | undefined>
    ) => {
      const {
        value,
        error,
        autoFocus,
        metaData: { isRequired, dataType, label, id, toolTip, size, disabled, initial, dicSec, attributeFormId, isEditable },
      } = props;
      const [valueLocal, setValueLocal] = useState<
        string | Date | number | undefined
      >(value ? value : '');

      const onChange = (value: string) => {
        setValueLocal(value);
      };

      useImperativeHandle(ref, () => ({
        getData() {
          return {
            id,
            value: valueLocal,
            text: '',
            typeElement: TypeControl.text,
            label: label,
            initial: initial,
            dicSec: dicSec,
            attributeFormId,
          } as ResultDocumentMetaDataChild;
        },
      }));

      return (
        <TextField
          disabled={disabled || !isEditable}
          maxLength={size ? size : 500}
          textHelp={toolTip}
          isOutlined
          isRequired={isRequired}
          type={
            dataType === TypeControlData.number ? 
            TypeTextField.number : 
            dataType === TypeControlData.currency
              ? TypeTextField.currency
              : undefined
          }
          label={label}
          value={valueLocal}
          error={error}
          autoFocus={autoFocus}
          onChange={onChange}
        />
      );
    }
  )
);

export default TextFieldMemo;
