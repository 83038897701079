import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  createRef,
} from 'react';
import { ContactMetaDataDto } from '../../dto/ContactDto';
import { getMetaDataApi } from '../../api/contact';
import { sortBy } from 'lodash';
import {
  ContactMetaDataState,
  HandleContractMetaDataChild,
  TypeControl,
} from './types';
import { IValidatorField } from '../../hooks/validation/useValidator/types';
import useValidator from '../../hooks/validation/useValidator/useValidator';
import { regexEmail } from '../../constants/Regex';
import AutocompleteCustomTable from './autocompleteCustomTableMemo';
import DateTextBoxMemo from './dateTextBoxMemo';
import TextFieldMemo from './textFieldMemo';
import { useTranslation } from 'react-i18next';
import { TypeTextField } from '../../components/TextField';

interface IContactMetaDataProps {
  state: ContactMetaDataState,
  personTypeId: number;
  validateData: number;
  onValidationMetaData?: (hasError: boolean, data: ContactMetaDataState, validations: IValidatorField[])  => void;
  isOutlined?: boolean;
}

const ContactMetaData = ({
  state,
  personTypeId,
  validateData,
  onValidationMetaData,
  isOutlined,
}: IContactMetaDataProps) => {
  const { i18n, t } = useTranslation();
  const [elRefs, setElRefs] = React.useState<any>([]);
  const [stateLocal, setState] = useState<ContactMetaDataState>(state);
  const [metaData, setMetaData] = useState<ContactMetaDataDto[]>([]);
  const [validations, setValidations] = useState<IValidatorField[]>([]);
  const [stateError, startValidations] = useValidator<ContactMetaDataState>();

  useEffect(() => {
    setState(state);
  }, [state]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getMetaDataApi(personTypeId);
      const refControls = data.map((control) => createRef());
      setElRefs(refControls);
      setMetaData(sortBy(data, (p) => p.order));
    };
    fetch();
  }, [personTypeId, i18n.language]);

  const buildRuleValidations = useCallback(() => {
    const rules = metaData
      .filter((p) => p.isRequired)
      .map((p) => {
        const validation: IValidatorField = {
          field: `${p.id}.value`,
          validations: {
            required: true,
          },
        };
        if (
          p.dataType === TypeControl.email ||
          p.dataType === TypeControl.email2
        ) {
          validation.validations.pattern = {
            value: regexEmail,
            message: t('message.validation.invalidEmail'),
          };
        }
        return validation;
      });
    setValidations(rules);
  }, [metaData, t]);

  useEffect(() => {
    const buildInfo = () => {
      if (metaData.length === 0) return;
      buildRuleValidations();
    };
    buildInfo();
  }, [buildRuleValidations, metaData]);

  const getAllDataControls = useCallback(():ContactMetaDataState | undefined => {
    if (validateData === 0) return undefined;
    let formStateClone = {...stateLocal};
    
    for (let index = 0; index < elRefs.length; index++) {
      const ref = elRefs[index] as any;
      if (!ref || !ref.current) continue;

      const dataRef = (ref.current as HandleContractMetaDataChild).getData();
      formStateClone = {
        ...formStateClone,
        [dataRef.id]: {
          type: dataRef.typeElement,
          value: dataRef.value,
          text: dataRef.text,
          label: dataRef.label,
        },
      };
    }
    return formStateClone;
    
  }, [elRefs, stateLocal, validateData]);

  useEffect(() => {
    if (validateData === 0) return;
    const dataControls =  getAllDataControls();
    if(!dataControls) return;
    const isValide = startValidations(dataControls, validations);
    setState(dataControls);
    if(onValidationMetaData){
      onValidationMetaData(!isValide, dataControls, validations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateData]);

  const buildDateTextBox = useCallback(
    (control: ContactMetaDataDto, index: number) => (
      <div key={`DateTextBoxMemo_${control.id}`}>
        <DateTextBoxMemo
          isOutlined={isOutlined}
          id={control.id}
          ref={elRefs[index]}
          isRequired={control.isRequired}
          label={control.label}
          value={
            stateLocal[control.id]
              ? stateLocal[control.id].value
              : undefined
          }
          error={stateError[control.id]}
        />
      </div>
    ),
    [elRefs, stateLocal, stateError, isOutlined]
  );

  const buildTextBox = useCallback(
    (control: ContactMetaDataDto, index: number) => (
      <div key={`TextFieldMemo_${control.id}`}>
        <TextFieldMemo
          isOutlined={isOutlined}
          ref={elRefs[index]}
          id={control.id}
          isRequired={control.isRequired}
          type={
            control.dataType === TypeControl.number
              ? TypeTextField.number
              : control.dataType === TypeControl.currency
              ? TypeTextField.currency
              : undefined
          }
          label={control.label}
          value={
            stateLocal[control.id]
              ? stateLocal[control.id].value.toString()
              : undefined
          }
          error={stateError[control.id]}
          maxLength={control.size}
          textHelp={control.toolTip}
        />
      </div>
    ),
    [elRefs, stateLocal, stateError, isOutlined]
  );

  const buildAutocomplete = useCallback(
    (control: ContactMetaDataDto, index: number) => (
      <div key={`AutocompleteCustomTableMemo_${control.id}`}>
        <AutocompleteCustomTable
          isOutlined={isOutlined}
          ref={elRefs[index]}
          metaData={control}
          value={stateLocal[control.id] ? stateLocal[control.id].value.toString() : undefined}
          error={stateError[control.id]}
          textHelp={control.toolTip}
        />
      </div>
    ),
    [elRefs, stateLocal, stateError, isOutlined]
  );

  const buildControls = useCallback(
    () =>
      metaData.map((control, index) => {
        switch (control.dataType) {
          case TypeControl.number:
          case TypeControl.currency:
          case TypeControl.text2:
          case TypeControl.text:
          case TypeControl.email:
          case TypeControl.email2:
            return buildTextBox(control, index);
          case TypeControl.date:
            return buildDateTextBox(control, index);
          case TypeControl.list:
            return buildAutocomplete(control, index);
          default:
            return buildTextBox(control, index);
        }

      }),
    [metaData, buildTextBox, buildAutocomplete, buildDateTextBox]
  );

  return <Fragment>{buildControls()}</Fragment>;
};
export default ContactMetaData;
