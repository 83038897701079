import { makeStyles } from '@material-ui/core';
import { ContextFooter } from '../../../dto/ContextAppDto';
import TitleFooter from '../common/TitleFooter';
import TextFooter from '../common/TextFooter';
import ImageFooter from '../common/ImageFooter';
import clsx from 'clsx';
import SocialNetworkIcon from '../common/SocialNetworkIcon';

interface FooterProps {
  footer: ContextFooter;
}

const Footer = ({ footer }: FooterProps) => {
  const useStyles = makeStyles({
    divider: {
      '& > div': { borderLeftColor: `${footer.textColor}` }
    }
  });

  const classes = useStyles();

  return (
    <div className='container mx-auto px-3'>
      <div className='sm:flex sm:mt-8'>
        <div className={clsx('mt-8 sm:mt-0 sm:w-full grid grid-cols-1 md:grid-cols-3 justify-center gap-y-4 pb-7 md:divide-x-2', classes.divider)}>
          <div className='col-span-2'>
            <div className='w-full flex flex-col space-y-1 text-center'>
              <TitleFooter text={footer?.text1?.text} textColor={footer?.textColor} />
              <TextFooter footer={footer} text={footer?.text2} />
              <TextFooter footer={footer} text={footer?.text3} />
              <TextFooter footer={footer} text={footer?.text4} />
              <TextFooter footer={footer} text={footer?.text5} />
              <TextFooter footer={footer} text={footer?.text6} />
              <TextFooter footer={footer} text={footer?.text7} />
              <TextFooter footer={footer} text={footer?.text8} />
              <TextFooter footer={footer} text={footer?.text9} />
              <TextFooter footer={footer} text={footer?.text10} />
              <TextFooter footer={footer} text={footer?.text11} />
              <TextFooter footer={footer} text={footer?.text12} />
              <TextFooter footer={footer} text={footer?.text13} />
            </div>
          </div>
          <div>
            <div className='flex flex-col'>
              <div className='flex flex-col md:flex-row justify-center'>
                {footer.image1 && (
                  <ImageFooter
                    src={footer.image1}
                    link={footer.txtLinkImg1?.text}
                  ></ImageFooter>
                )}
                {footer.image2 && (
                  <ImageFooter
                    src={footer.image2}
                    link={footer.txtLinkImg2?.text}
                  ></ImageFooter>
                )}
              </div>
              <div className='mt-10 w-full text-center space-x-2'>
                {
                  footer.socialNetworks?.map(socialNetwork => (
                    <SocialNetworkIcon
                      key={socialNetwork.name}
                      socialNetwork={socialNetwork}
                      textColor={footer.textColor}
                      footerType={footer.type}
                    />
                  ))
                }
              </div>
              <div className='text-center space-y-3'>
                <br />
                <TextFooter footer={footer} text={footer?.text14} />
                <br />
                <br />
                <TextFooter footer={footer} text={footer?.text15} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
