import { API_BASE, API_ECM, API_PQRSD } from '../constants';
import {
  getData,
  postData,
  postFormData,
} from '../_base';
import {

  PostPrqsDto,
  PqrsInformation,
  PqrsPolicyDto,
} from '../../dto/pqrs';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { EnumTypePQRS } from '../../constants/PqrsType';
import {
  CreatePqrsResponse,
  PqrsdAvaliableActionsDto,
  ChangeStatusPqrsdDto,
  CreateObservationDto,
  DocumentBasicDto,
} from './types';
import { IErrorApiBase } from '../_base/types';
import { PqrsdBasicDto } from './types';
import { DocumentInformationDto } from '../../dto/DocumentInformationDto';

const base = `${API_BASE}/v1/Pqrs`;
const baseEcm = `${API_ECM}/v2/DocumentInformation`;
const basePqrsd = `${API_PQRSD}/v1/Pqrsd`;

const url = {
  getByLanguageIdAndType: `${base}/Information`,
  getMetaData: `${base}/MetaData`,
  getPolicy: `${base}/GetPolicy`,
  create: `${base}/Create`,
  addFiles: `${base}/AddFiles`,
  getInformation: `${baseEcm}/GetInformationDocument`,
  getInformationAccessCode: `${baseEcm}/GetInformationDocumentAccessCode`,
  validationInformationAccessCode: `${baseEcm}/ValidationInformationDocumentAccessCode`,
  getAvalibleActions: `${base}/GetAvalibleActions`,
  changeStatusPqrsd: `${base}/ChangeStatusPqrsd`,
  AddObservationPqrsd: `${base}/addObservationPqrsd`,
  getExtensions: `${base}/Extensions`,
  getPqrsdByDocId: `${basePqrsd}/GetPqrsd`,
  getDocumentType: `${base}/GetDocumentType`
};

export const getByLanguageIdAndType = async (
  pqrsType: EnumTypePQRS
): Promise<PqrsInformation | undefined> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getByLanguageIdAndType}/${lngDto.id}/${pqrsType}`;
  const result = await getData<PqrsInformation>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};

export const getPolicy = async (
  pqrsType: EnumTypePQRS
): Promise<PqrsPolicyDto | undefined> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getPolicy}/${lngDto.id}/${pqrsType}`;
  const result = await getData<PqrsPolicyDto>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};

export const createPqrs = async (
  data: PostPrqsDto
): Promise<CreatePqrsResponse | undefined> => {
  const _url = `${url.create}`;
  const result = await postData<PostPrqsDto, CreatePqrsResponse>(_url, data);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};

export const addFilesPqrs = async (
  docId: string,
  files: File[]
): Promise<boolean | undefined> => {
  const _url = `${url.addFiles}`;
  const formData = new FormData();

  for (const file of files) {
    formData.append('files', file, file.name);
  }
  formData.set('docId', docId);

  const result = await postFormData<boolean>(_url, formData);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};

export const getInformationDocumentAccesCode = async (
  documentNumber: string,
  accessCode: string
): Promise<DocumentInformationDto | undefined> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getInformationAccessCode}/${lngDto.id}/${documentNumber}/${accessCode}`;
  const result = await getData<DocumentInformationDto>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};

export const getInformationDocument = async (
  documentNumber: string
): Promise<DocumentInformationDto | undefined> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getInformation}/${lngDto.id}/${documentNumber}`;
  const result = await getData<DocumentInformationDto>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};

export const validationInformationDocumentAccesCode = async (
  documentNumber: string,
  accessCode: string
): Promise<IErrorApiBase[] | undefined> => {
  const _url = `${url.validationInformationAccessCode}/${documentNumber}/${accessCode}`;
  const result = await getData<IErrorApiBase[]>(_url);
  if (result && !result.hasError && result.data) return undefined;
  else return result.error?.errors;
};

export const getAvaliablesActionsPqrsd = async (
  docId: string
): Promise<PqrsdAvaliableActionsDto | undefined> => {
  const _url = `${url.getAvalibleActions}`;
  const data = { documentEncrypted: docId } as DocumentBasicDto;
  const result = await postData<DocumentBasicDto, PqrsdAvaliableActionsDto>(_url, data);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};

export const changeStatePqrsd = async (data: ChangeStatusPqrsdDto): Promise<boolean> => {
  const _url = `${url.changeStatusPqrsd}`;
  const result = await postData<ChangeStatusPqrsdDto, boolean>(_url, data);
  if (result && !result.hasError && result.data) return result.data;
  else return false;
};


export const addObservationPqrsd = async (data: CreateObservationDto): Promise<boolean> => {
  const _url = `${url.AddObservationPqrsd}`;
  const result = await postData<CreateObservationDto, boolean>(_url, data);
  if (result && !result.hasError && result.data) return result.data;
  else return false;
};


export const getPqrsdByDocId = async (
  docId: string
): Promise<PqrsdBasicDto | undefined> => {
  const _url = `${url.getPqrsdByDocId}`;
  const data = { documentEncrypted: docId } as DocumentBasicDto;
  const result = await postData<DocumentBasicDto, PqrsdBasicDto>(_url, data);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};

export const getDocumentType = async (
  pqrsType: EnumTypePQRS
): Promise<number | undefined> => {
  const _url = `${url.getDocumentType}/${pqrsType}`;
  const result = await getData<number>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};