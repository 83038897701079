import React, { useEffect, useState } from 'react';
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from 'devextreme-react/data-grid';
import { IMyExpedientsDto } from '../../../dto/Reports';
import { getMyExpedientsApi } from '../../../api/reports';
import { PagePrincipal } from '../../../containers';
import { AppBar, Tab, Tabs, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';
import { useHistory } from 'react-router';
import RouteNavigation from '../../../constants/RouteNavigation';
import TabPanel from './tabPanel';
import { getUrlMyProcess } from '../../../api/bpm';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../components';
import { getUrlProcessDetail } from '../../../api/bpm/index';
const allowedPageSizes = [5, 10, 50, 100];

interface PorfolioMyExpedientsParams {
  tab: string;
  id: string;
}

const PorfolioMyExpedients = () => {
  const { tab, id } = useParams<PorfolioMyExpedientsParams>();
  const [data, setData] = useState<IMyExpedientsDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState<number>(0);
  const { t } = useTranslation();
  const [urlFront, setUrlFrom] = useState<string>('');

  useEffect(() => {
    if (tab) setTabSelected(+tab);
  }, [tab]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getMyExpedientsApi();
      const resultMapped = result.map((p) => {
        let stateName = t(`pqrsState.${p.state}`);
        if (stateName === 'pqrsState.null') stateName = t(`pqrsState.RADIC`);

        let typeName = t(`documentType.${p.type}`);
        if (typeName === 'documentType.null') typeName = '';
        return {
          ...p,
          state: stateName,
          type: typeName,
        };
      });

      setData(resultMapped);
    };
    fetch();
  }, [t]);

  useEffect(() => {
    const fetch = async () => {
      const url = await (id ? getUrlProcessDetail(+id) : getUrlMyProcess());
      if (url) setUrlFrom(url);
      setTimeout(function () {
        setIsLoading(false);
      }, 1500);
    };
    fetch();
  }, [id]);

  const onCellClick = (cell: any) => {
    if (cell?.column?.name === 'seeDocument') {
      history.push(
        `/${RouteNavigation.pqrsTrackingDocument}/${cell.data.documentNumber}`
      );
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (id) history.push(`/${RouteNavigation.PortfolioMyExpedients}/${newValue}`);
    setTabSelected(newValue);
  }

  const propsTab = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      tabIndex: 0
    };
  };
  return (
    <PagePrincipal title={t('pages.porfolioMyExpedients.title')}>
      <div className='m-5'>
        <AppBar position='static'>
          <Tabs value={tabSelected} onChange={handleChange} centered>
            <Tab
              label={t('pages.porfolioMyExpedients.tabs.pqrsd')}
              {...propsTab(0)}
              className='text-2xl'
            />
            <Tab
              label={t('pages.porfolioMyExpedients.tabs.process')}
              {...propsTab(1)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={tabSelected} index={0}>
          <DataGrid
            dataSource={data}
            showBorders
            columnHidingEnabled
            onCellClick={onCellClick}
          >
            <HeaderFilter visible />
            <Paging defaultPageSize={10} />
            <Pager
              visible
              showPageSizeSelector
              allowedPageSizes={allowedPageSizes}
            />
            <FilterRow visible showOperationChooser={false}/>
            <Column
              dataField='type'
              dataType='string'
              caption={t('pages.porfolioMyExpedients.table.columns.type')}
            />
            <Column
              dataField='documentNumber'
              dataType='string'
              caption={t('pages.porfolioMyExpedients.table.columns.numDoc')}
            />
            <Column
              width={200}
              dataField='state'
              dataType='string'
              caption={t('pages.porfolioMyExpedients.table.columns.state')}
            />
            <Column
              dataField='documentType'
              dataType='string'
              caption={t('pages.porfolioMyExpedients.table.columns.typeDoc')}
            />
            <Column
              dataField='dateCreation'
              dataType='date'
              caption={t('pages.porfolioMyExpedients.table.columns.date')}
              format='dd/MM/yyyy hh:mm'
              allowHeaderFiltering={true}
            />
            <Column type='buttons' width={50} name='seeDocument'>
              <Button
                render={() => (
                  <IconButton size='small' className='hover:text-green-700'>
                    <VisibilityIcon />
                  </IconButton>
                )}
              />
            </Column>
          </DataGrid>
        </TabPanel>
        <TabPanel value={tabSelected} index={1}>
          {urlFront && (
            <iframe
              src={urlFront}
              title='Bpm'
              className='w-full h-screen'
            ></iframe>
          )}
        </TabPanel>
      </div>
      <Loading loading={isLoading} />
    </PagePrincipal>
  );
};
export default PorfolioMyExpedients;
