import { getData } from '../_base';
import { API_ECM } from './../constants';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { DocumentTypeSectionDto } from '../../dto/DocumentTypeSectionDto';

const baseEcm = `${API_ECM}/v2/DocumentTypeSection`;

const url = {
  getDocumentTypeSections: `${baseEcm}/Get`,
};

export const getDocumentTypeSections = async (documentTypeId: number): Promise<DocumentTypeSectionDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getDocumentTypeSections}/${lngDto.id}/${documentTypeId}`;
  const result = await getData<DocumentTypeSectionDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data;
  else return [];
};
