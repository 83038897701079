import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import i18n from '../../i18n';
import { PagePrincipal } from '../../containers';
import { getServiceChannelByLangApi } from '../../api/serviceChannel';
import { uniqueId } from 'lodash';
import Panel from './panel';
import { ServiceChannelDto } from '../../dto/admin/types';

const ServiceChannels = () => {
  const { t } = useTranslation();
  const [services, setServices] = useState<ServiceChannelDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
      const result = await getServiceChannelByLangApi();
      setServices(result);
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <PagePrincipal title={t('pages.serviceChannels.title')} subTitle={t('pages.serviceChannels.subTitle')}>
      <Container>
        <section>
          <div className='container px-5 py-4 mx-auto'>
            <div className='ml-2 w-full flex flex-wrap'>
              {services.map((p) => (
                <div key={uniqueId()} className='w-full lg:w-4/12'>
                  <Panel
                    title={p.text1}
                    description1={p.text2}
                    description2={p.text3}
                    description3={p.text4}
                    description4={p.text5}
                    url1={p.link}
                    icon={p.icon}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </Container>
    </PagePrincipal>
  );
};
export default ServiceChannels;
