import React, { useEffect, useState } from 'react';
import { getTreatmentApi } from '../../../api/AdmInquiries';
import { TreatmentDto } from '../../../dto/admin/types';
import { useTranslation } from 'react-i18next';
import AutocompleteStyled from '../../../components/AutoComplete';

interface ITreatmenAutocompleteProps {
  idPersonType?: string;
  idSelected?: string;
  error?: string;
  onChange: (value: TreatmentDto) => void;
  isOutlined? :boolean;
  textHelp?: string;
}

const defaultcity: TreatmentDto = {
  id: '',
  description: '',
};

const TreatmenAutocomplete = ({
  idSelected,
  idPersonType,
  onChange,
  error,
  isOutlined,
  textHelp,
}: ITreatmenAutocompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<TreatmentDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
      const result = await getTreatmentApi();
      setOptions(result);
    };
    callApi();
  }, [idPersonType]);

  const getOptionSelected = (): TreatmentDto => {
    if (idSelected) {
      const selected = options.find((p) => p.id === idSelected);
      return selected ? selected : defaultcity;
    }
    return defaultcity;
  };

  const handlerOnChange = (e: any, value: any | null) => {
    if (value) {
      onChange(value as TreatmentDto);
    }
  };

  return (
    <AutocompleteStyled
      isOutlined={isOutlined}
      error={error}
      options={options}
      value={getOptionSelected()}
      onChange={handlerOnChange}
      label={t('components.treatmenAutocomplete.label')}
      autoHighlight
      getOptionLabel={(option: any) => option.description}
      renderInput={() => <></>}
      textHelp={textHelp}
    />
  );
};

export default TreatmenAutocomplete;
