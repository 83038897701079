import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface ICurrentTimeProps {
  classNameTime?: string;
  classNameDate?: string;
}

const CurrentTime = ({ classNameTime, classNameDate }: ICurrentTimeProps) => {
  const [dateC, setdateC] = useState<Date>(new Date());
  const { t } = useTranslation();

  const getDate = () => {
    const day = dateC.getDate();
    const date = t(`dayOfWeek.${dateC.getDay()}`);
    const month = t(`month.${dateC.getMonth()}`);
    const year = dateC.getFullYear();

    return `${date}, ${day} ${month} ${year}`;
  };

  const getTime = () => {
    let hours = dateC.getHours();
    let minutes = dateC.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesFinal = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutesFinal + ' ' + ampm;
    return strTime;
  };

  setInterval(() => {
    setdateC(new Date());
  }, 60000);

  return (
    <Fragment>
      <span className={clsx(classNameTime, 'capitalize font-body')}> {getTime()}</span>
      <span className={clsx(classNameDate, 'capitalize font-body')}>{getDate()}</span>
    </Fragment>
  );
};
export default CurrentTime;
