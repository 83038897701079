import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

interface IFiltreProps {
  types: string[];
  onSelectType: (type: string[]) => void;
  values: string[];
}

const FiltrePrimary = ({ types, onSelectType, values }: IFiltreProps) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<string[]>([]);

  useEffect(() => {
    setChecked(values);
  }, [values]);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onSelectType(newChecked);
  };

  return (
    <List className={classes.root}>
      {types.map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem
            key={value}
            role={undefined}
            dense
            button
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                style={{
                  color: 'gray',
                }}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value} />
          </ListItem>
        );
      })}
    </List>
  );
};
export default FiltrePrimary;
