import { IconButton, InputLabel, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty, sumBy } from 'lodash';
import { Fragment } from 'react';
import { Required, TooltipHelp } from '.';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface FileUploadProps {
  files: File[];
  label: string | undefined;
  isRequired?: boolean;
  textHelp?: string;
  error?: string;
  maxFiles?: number;
  maxSizeTotalMB?: number;
  acceptExtesion: string;
  isMultiple: boolean;
  onErrorSize?: () => void;
  onErrorMaxFiles?: () => void;
  onErrorExtensionNotAccepted?: (ext: string) => void;
  onLoadFiles: (files: File[], hasErrorValidation: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  text: {
    width: '100%',
    color: theme.palette.text.primary,
  },
}));

const FileUpload = ({
  files,
  label,
  textHelp,
  isRequired,
  error,
  acceptExtesion,
  isMultiple,
  maxFiles,
  maxSizeTotalMB,
  onLoadFiles,
  onErrorSize,
  onErrorMaxFiles,
  onErrorExtensionNotAccepted
}: FileUploadProps) => {
  const classes = useStyles();
  const hasError = !isEmpty(error);

  const handleChange = (selectorFiles: FileList) => {
    const _files: File[] = [];
    for (let index = 0; index < selectorFiles.length; index++) {
      _files.push(selectorFiles[index]);
    }
    if (validationMaxFiles(_files) && validationMaxSize(_files) && validationAcceptedExtensions(_files)) {
      onLoadFiles(_files, false);
    } else {
      onLoadFiles([], true);
    }
  };

  const clearSelection = () => onLoadFiles([], false);

  const validationMaxFiles = (filesList: File[]) => {
    if (maxFiles && filesList.length > maxFiles) {
      onErrorMaxFiles && onErrorMaxFiles();
      return false;
    }
    return true;
  };

  const validationMaxSize = (filesList: File[]) => {
    const sizeTotalInMb = sumBy(filesList, (p) => p.size) / 1024 / 1024; 
    if (maxSizeTotalMB && sizeTotalInMb > maxSizeTotalMB) {
      onErrorSize && onErrorSize();
      return false;
    }
    return true;
  };

  const validationAcceptedExtensions = (filesList: File[]) => {
    for (let index = 0; index < filesList.length; index++) {
      const ext = filesList[index].name.split('.').pop();
      if (ext && !acceptExtesion.includes(ext) && !acceptExtesion.includes(ext.toUpperCase())) {
        onErrorExtensionNotAccepted && onErrorExtensionNotAccepted(`(.${ ext })`);
        return false;
      }
    }
    return true;
  }

  return (
    <Fragment>
      <div className='h-10'>
        <InputLabel className={classes.text}>
          {label} {isRequired && <Required />}
          {textHelp && <TooltipHelp text={textHelp} />}
        </InputLabel>
      </div>
      <div
        className={`flex w-full items-center justify-center bg-grey-lighter`}
      >
        <div className='relative w-full'>
          {files.length > 0 && (
            <span className='absolute left-0-0'>
              <span className='badge mb-3 bg-red-800 rounded-full px-2 py-1 text-center object-right-top text-white text-sm mr-1'>
                {files.length}
              </span>
            </span>
          )}

          <span className="absolute right-0">
            <TooltipHelp text={acceptExtesion} />
          </span>

          <label
            className={clsx(
              'w-full flex flex-col items-center px-4 py-6 text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white',
              { 'border-red-500': hasError }
            )}
          >
            <svg
              className='w-8 h-8'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
            >
              <path d='M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z' />
            </svg>
            <input
              multiple={isMultiple}
              type='file'
              accept={acceptExtesion}
              className='hidden'
              onChange={(e: any) => handleChange(e.target.files)}
            />
          </label>
        </div>
      </div>
      {hasError && (
        <span className='font-bold text-red-600 italic ml-5'>{error}</span>
      )}
      {files.length > 0 && (
        <span className='float-right'>
          <IconButton aria-label='delete' onClick={clearSelection}>
            <HighlightOffIcon fontSize='large' />
          </IconButton>
        </span>
      )}
    </Fragment>
  );
};
export default FileUpload;
