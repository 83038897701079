import { STORAGE_LANGUAGE } from '../constants/App';
import { LanguageDto } from '../dto/admin/types';
import { getLocalStorage } from './localStorage';

const languageDefault = {
  id: 1,
  code: 'es',
  name: 'Español',
} as LanguageDto;

export const getCurrentLanguageDto = (): LanguageDto => {
  const lng = getLocalStorage<LanguageDto>(STORAGE_LANGUAGE);
  if (!lng) return languageDefault;
  return lng;
};
