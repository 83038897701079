import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { searchApi } from '../../api/reports';
import { SearchResultDto } from '../../api/reports/types';
import {
  AccordionProcess,
  DynamicControlTemplate,
  PagePrincipal,
} from '../../containers';
import Filter from './filter';
import { EnumModules } from '../../constants/Modules';
import { getServiceChannelByLangApi } from '../../api/serviceChannel';
import { sortBy, uniqueId } from 'lodash';
import { getPoliciesByLangApi } from '../../api/politicies';
import {
  ManualDto,
  PolicyDto,
  ServiceChannelDto,
  TechnicalRequirementsDto,
} from '../../dto/admin/types';
import { NormativityOfficeDto } from '../../dto/normativityOffice';
import { getNormativityOfficeByLangApi } from '../../api/normativityOffice';
import { getManaulsByLangApi } from '../../api/manuals';
import { GetQuestionsFrequentlyByLangApi } from '../../api/questionsFrequently';
import { QuestionsFrequentlyDto } from '../../dto/QuestionsFrequentlyDto';
import PanelServiceChannels from '../serviceChannels/panel';
import PanelPolitics from '../politics/panel';
import PanelInfoNormatives from '../normativity/panel';
import PanelManual from '../manuals/panel';
import PanelFaqs from '../questionsFrequently/panel';
import PanelTechnicalRequirements from '../technicalRequirements/panel';
import { getTechnicalRequirementByLangApi } from '../../api/technicalRequirement';
import { ProcessDto } from '../../dto/Process';
import { getProcessByLangApi } from '../../api/process';
import { InformativeTextDto } from '../../dto/DynamicControlDto';
import { getInformativeTextByLangApi } from '../../api/informativeText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, Typography } from '@material-ui/core';
import RouteNavigation from '../../constants/RouteNavigation';

interface ISearchParam {
  text: string;
}
const Search = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { text } = useParams<ISearchParam>();
  const [info, setInfo] = useState<SearchResultDto[]>([]);
  const [moduleSelected, setModuleSelected] = useState<string>();
  const [infoPolitics, setInfoPolitics] = useState<PolicyDto[]>();
  const [infoManuals, setInfoManuals] = useState<ManualDto[]>();
  const [infoFaqs, setInfoFaqs] = useState<QuestionsFrequentlyDto[]>();
  const [infoProcess, setInfoProcess] = useState<ProcessDto[]>();
  const [infoInformativesText, setInfoInformativesText] =
    useState<InformativeTextDto[]>();
  const [infoTechnicalRequirements, setInfoTechnicalRequirements] =
    useState<TechnicalRequirementsDto[]>();
  const [infoNormatives, setInfoNormatives] =
    useState<NormativityOfficeDto[]>();
  const [infoServiceChannels, setInfoServiceChannels] =
    useState<ServiceChannelDto[]>();

  useEffect(() => {
    const fecth = async () => {
      const resultSearch = await searchApi(text);
      if (resultSearch) {
        setInfo(resultSearch);
        setModuleSelected( resultSearch[0]?.module );
      } else {
        setModuleSelected(undefined);
      }
    };
    fecth();
  }, [text]);

  useEffect(() => {
    const buildControls = async () => {
      setInfoPolitics(undefined);
      setInfoServiceChannels(undefined);
      setInfoNormatives(undefined);
      setInfoManuals(undefined);
      setInfoFaqs(undefined);
      setInfoTechnicalRequirements(undefined);
      setInfoProcess(undefined);
      setInfoInformativesText(undefined);

      const ids = info
        .filter((p) => p.module === moduleSelected)
        .map((p) => p.id);
      switch (moduleSelected) {
        case EnumModules.CHANNELS_ATTENTION:
          const serviceChannel = (await getServiceChannelByLangApi()).filter(
            (p) => ids.includes(p.id)
          );
          setInfoServiceChannels(serviceChannel);
          break;
        case EnumModules.FAQS:
          const faqs = (await GetQuestionsFrequentlyByLangApi()).filter((p) =>
            ids.includes(p.id)
          );
          setInfoFaqs(faqs);
          break;
        case EnumModules.MANUALS:
          const manuals = (await getManaulsByLangApi()).filter((p) =>
            ids.includes(p.id)
          );
          setInfoManuals(manuals);
          break;
        case EnumModules.NORMATIVIDAD:
          const nomatives = (await getNormativityOfficeByLangApi()).filter(
            (p) => ids.includes(p.id)
          );
          setInfoNormatives(nomatives);
          break;
        case EnumModules.POLITICS:
          const politics = (await getPoliciesByLangApi()).filter((p) =>
            ids.includes(p.id)
          );
          setInfoPolitics(politics);
          break;
        case EnumModules.REQUIREMENTS:
          const technicalRequirement = (
            await getTechnicalRequirementByLangApi()
          ).filter((p) => ids.includes(p.id));
          setInfoTechnicalRequirements(technicalRequirement);
          break;
        case EnumModules.INFORMATION_TEXT:
          const informativesText = (await getInformativeTextByLangApi()).filter(
            (p) => ids.includes(p.id)
          );
          setInfoInformativesText(informativesText);
          break;
        case EnumModules.PROCEDURES:
          const process = (await getProcessByLangApi()).filter((p) =>
            ids.includes(p.id)
          );
          setInfoProcess(process);

          break;
        default:
          break;
      }
    };
    buildControls();
  }, [info, moduleSelected]);

  const onSelectFilter = async (module: string) => {
    setModuleSelected(module);
  };

  const onGoModule = () => {
    switch (moduleSelected) {
      case EnumModules.CHANNELS_ATTENTION:
        history.push(`/${RouteNavigation.ServiceChannels}`);
        break;
      case EnumModules.FAQS:
        history.push(`/${RouteNavigation.Questions}`);
        break;
      case EnumModules.MANUALS:
        history.push(`/${RouteNavigation.OurSiteManual}`);
        break;
      case EnumModules.NORMATIVIDAD:
        history.push(`/${RouteNavigation.OurSiteRegulation}`);
        break;
      case EnumModules.POLITICS:
        history.push(`/${RouteNavigation.OurSitePolitics}`);
        break;
      case EnumModules.REQUIREMENTS:
        history.push(`/${RouteNavigation.OurSiteTechnicalRequirements}`);
        break;
      case EnumModules.INFORMATION_TEXT:
        history.push(`${RouteNavigation.Home}`);
        break;
      case EnumModules.PROCEDURES:
        history.push(`/${RouteNavigation.Process}`);
        break;
      default:
        break;
    }
  };

  return (
    <PagePrincipal title={t('pages.search.title')} withContainer>
      <div className='w-full pb-4'>
        {info.length === 0 ? (
          <div className='w-full h-full text-center'>
            <Typography variant='h5'>{t('pages.search.notFound')}</Typography>
          </div>
        ) : (
          <div className='grid grid-cols-1 sm:grid-cols-4 gap-2'>
            <div className='col-span-1'>
              {info && (
                <Filter information={info} onSelectFilter={onSelectFilter} />
              )}
            </div>
            <div className='col-span-3'>
              <div style={{ textAlign: 'end' }}>
                {moduleSelected && (
                  <Button
                    onClick={onGoModule}
                    variant='contained'
                    color='primary'
                    endIcon={<ArrowForwardIcon />}
                  >
                    {t(`pages.search.modules.${moduleSelected}`)}
                  </Button>
                )}
              </div>

              {infoServiceChannels &&
                infoServiceChannels.map((p) => (
                  <div key={uniqueId()} className=''>
                    <PanelServiceChannels
                      title={p.text1}
                      description1={p.text2}
                      description2={p.text3}
                      description3={p.text4}
                      description4={p.text5}
                      url1={p.link}
                      icon={p.icon}
                    />
                  </div>
                ))}
              {infoPolitics && <PanelPolitics politics={infoPolitics} />}
              {infoNormatives && (
                <PanelInfoNormatives normatives={infoNormatives} />
              )}
              {infoManuals && <PanelManual manuals={infoManuals} />}
              {infoFaqs && <PanelFaqs questions={infoFaqs} fullWidth />}
              {infoTechnicalRequirements &&
                infoTechnicalRequirements.map((p) => (
                  <div key={uniqueId()} className=''>
                    <PanelTechnicalRequirements data={p} />
                  </div>
                ))}
              {infoProcess &&
                infoProcess.map((p) => (
                  <AccordionProcess
                    idBpm={p.idBpm}
                    id={p.id}
                    key={p.id}
                    title={p.title}
                    duration={p.duration}
                    price={p.cost}
                    description={p.description}
                    isAccessibleByUser={p.isAccessibleByUser}
                    documentsUrl={p.documentsUrl}
                    idDocumentType={p.idDocumentType}
                  />
                ))}

              {infoInformativesText &&
                sortBy(infoInformativesText, (p) => p.order).map((p) => (
                  <DynamicControlTemplate
                    key={uniqueId()}
                    configuration={p}
                    fullWidth
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </PagePrincipal>
  );
};

export default Search;
