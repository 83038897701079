import React from 'react';
import { IUserDtoJwt } from '../../dto/UserDto';

export interface UserContext {
  userAuth: IUserDtoJwt | null | undefined;
  setCurrentUser: (value: IUserDtoJwt | null | undefined) => void;
}

export const USER_DEFAULT_VALUE = {
  userAuth: undefined,
  setCurrentUser: () => {},
};

export const userContext = React.createContext<UserContext>(USER_DEFAULT_VALUE);