import React, { useEffect, useMemo, useState } from 'react';
import { NormativityOfficeDto } from '../../dto/normativityOffice';
import { getNormativityOfficeByLangApi } from '../../api/normativityOffice';
import { useTranslation } from 'react-i18next';
import { PagePrincipal } from '../../containers';
import { isEmpty, orderBy, uniq } from 'lodash';
import Filtre from './filtres';
import { ISearchFiltres } from './types';
import { isAfter, isBefore } from 'date-fns';
import { Pagination } from '../../components';
import { getDatePagination } from '../../utilitys/pagination';
import Panel from './panel';
const RECORDS_PER_PAGE = 5;

const NormativityOffice = () => {
  const { i18n, t } = useTranslation();
  const [normative, setNormative] = useState<NormativityOfficeDto[]>([]);
  const [normativeFiltred, setNormativeFiltred] = useState<
    NormativityOfficeDto[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [normativeToShow, setNormativeToShow] = useState<
    NormativityOfficeDto[]
  >([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getNormativityOfficeByLangApi();
      const orderedResult = orderBy(result, (p) => p.title);
      setNormative(orderedResult);
      setNormativeFiltred(orderedResult);
      const paginatedData = getDatePagination(
        orderedResult,
        1,
        RECORDS_PER_PAGE
      );
      setNormativeToShow(paginatedData);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onFitre = (filtres: ISearchFiltres) => {
    let listFiltred: NormativityOfficeDto[] = [...normative];
    if (filtres.title)
      listFiltred = listFiltred.filter((p) =>
        p.title.toLowerCase().includes(filtres.title!.toLowerCase())
      );

    if (!isEmpty(filtres.types))
      listFiltred = listFiltred.filter((p) => filtres.types!.includes(p.type));

    if (filtres.description) {
      listFiltred = listFiltred.filter(
        (p) =>
          p.paragraph1
            ?.toLowerCase()
            .includes(filtres.description!.toLowerCase()) ||
          p.paragraph2
            ?.toLowerCase()
            .includes(filtres.description!.toLowerCase()) ||
          p.paragraph3
            ?.toLowerCase()
            .includes(filtres.description!.toLowerCase()) ||
          p.paragraph4
            ?.toLowerCase()
            .includes(filtres.description!.toLowerCase())
      );
    }

    if (filtres.expeditionDateEnd && filtres.expeditionDateStart) {
      listFiltred = listFiltred.filter(
        (p) =>
          isAfter(new Date(p.expeditionDate), filtres.expeditionDateStart!) &&
          isBefore(new Date(p.expeditionDate), filtres.expeditionDateEnd!)
      );
    }
    if (filtres.publicationDateStart && filtres.publicationDateEnd) {
      listFiltred = listFiltred.filter(
        (p) =>
          isAfter(new Date(p.publicationDate), filtres.publicationDateStart!) &&
          isBefore(new Date(p.publicationDate), filtres.publicationDateEnd!)
      );
    }

    setPage(1);
    setNormativeFiltred(listFiltred);
    const paginatedData = getDatePagination(listFiltred, 1, RECORDS_PER_PAGE);
    setNormativeToShow(paginatedData);
  };

  const getTypes = useMemo(
    () => uniq(normative.map((p) => p.type)),
    [normative]
  );

  const changePage = (newPage: number) => {
    const paginatedData = getDatePagination(
      normativeFiltred,
      newPage,
      RECORDS_PER_PAGE
    );
    setPage(newPage);
    setNormativeToShow(paginatedData);
  };

  return (
    <PagePrincipal title={t('pages.normativityOffice.title')}>
      <div className='flex flex-col sm:items-center md:items-start space-y-5 md:flex-row p-0 space-x-5 lg:space-y-5'>
        <div className='w-full max-w-sm'>
          <Filtre types={getTypes} onFitre={onFitre} />
        </div>
        <div className='w-full h-full'>
          <Panel normatives={normativeToShow} />
          <div className='flex justify-center pt-5'>
            <Pagination
              recordsPerPage={RECORDS_PER_PAGE}
              total={normativeFiltred.length}
              page={page}
              onChangePage={changePage}
            />
          </div>
        </div>
      </div>
    </PagePrincipal>
  );
};
export default NormativityOffice;
