import { Fragment } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { QuestionsFrequentlyDto } from '../../dto/QuestionsFrequentlyDto';
import { API_BASE_FILES } from '../../api/constants';
import { getEmbedVideoUrl } from '../../utilitys/url';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
  },
  colorTextSecondary: {
    color: theme.palette.text.primary,
  },
}));

interface IPanelProps {
  questions: QuestionsFrequentlyDto[];
  fullWidth?: boolean;
}

const Panel = ({ questions, fullWidth }: IPanelProps) => {
  const classes = useStyles();
  
  return (
    <Fragment>
      {questions.map((p) => (
        <div key={p.id} className={fullWidth ? 'w-full' : `w-full lg:w-1/2 px-4 py-2`}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id={`question${p.id}`}
              className={classes.root}
            >
              <span className='text-xl font-body'>{p.description}</span>
            </AccordionSummary>
            <AccordionDetails>
              <div className='flex flex-col items-center space-y-7 w-full'>
                <div className='text-justify'>
                  <Typography>{p.answer}</Typography>
                </div>
                <div>
                  {p.image && <img src={ `${ API_BASE_FILES }${ p.image }` } alt='' className='h-48' />}
                </div>
                <div>
                  {p.video && (
                    <iframe className="w-full h-48" title={ p.description } src={ getEmbedVideoUrl(p.video) }></iframe>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </Fragment>
  );
};
export default Panel;
