import React, { useEffect, useState } from 'react';
import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from 'devextreme-react/data-grid';
import { IMyExpedientsDto } from '../../../dto/Reports';
import { getMyExpedientsApi } from '../../../api/reports';
import { PagePrincipal } from '../../../containers';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';
import { useHistory } from 'react-router';
import RouteNavigation from '../../../constants/RouteNavigation';
const allowedPageSizes = [5, 10, 50, 100];

const PqrsdTrackingReg = () => {
  const [data, setData] = useState<IMyExpedientsDto[]>([]);
  const history = useHistory();
  const { t } = useTranslation();


  useEffect(() => {
    const fetch = async () => {
      const result = await getMyExpedientsApi();
      const resultMapped = result.map((p) => {
        let stateName = t(`pqrsState.${p.state}`);
        if (stateName === 'pqrsState.null') stateName = t(`pqrsState.RADIC`);

        let typeName = t(`documentType.${p.type}`);
        if (typeName === 'documentType.null') typeName = '';
        return {
          ...p,
          state: stateName,
          type: typeName,
        };
      });

      setData(resultMapped);
    };
    fetch();
  }, [t]);


  const onCellClick = (cell: any) => {
    if (cell?.column?.name === 'seeDocument') {
      history.push(
        `/${RouteNavigation.pqrsTrackingDocument}/${cell.data.documentNumber}`
      );
    }
  };

  return (
    <PagePrincipal title={t('pages.pqrs.trackingReg.title')}>
      <div className='m-5'>
        <DataGrid
          dataSource={data}
          showBorders
          columnHidingEnabled
          onCellClick={onCellClick}
        >
          <HeaderFilter visible />
          <Paging defaultPageSize={10} />
          <Pager
            visible
            showPageSizeSelector
            allowedPageSizes={allowedPageSizes}
          />
          <FilterRow visible />
          <Column
            dataField='type'
            dataType='string'
            caption={t('pages.pqrs.trackingReg.table.columns.type')}
          />
          <Column
            dataField='documentNumber'
            dataType='string'
            caption={t('pages.pqrs.trackingReg.table.columns.numDoc')}
          />
          <Column
            width={200}
            dataField='state'
            dataType='string'
            caption={t('pages.pqrs.trackingReg.table.columns.state')}
          />
          <Column
            dataField='documentType'
            dataType='string'
            caption={t('pages.pqrs.trackingReg.table.columns.typeDoc')}
          />
          <Column
            dataField='dateCreation'
            dataType='date'
            caption={t('pages.pqrs.trackingReg.table.columns.date')}
            format='dd/MM/yyyy hh:mm'
          />
          <Column type='buttons' width={50} name='seeDocument'>
            <Button
              render={() => (
                <div className='hover:text-green-700 cursor-pointer'>
                  <VisibilityIcon />
                </div>
              )}
            />
          </Column>
        </DataGrid>
      </div>
    </PagePrincipal>
  );
};
export default PqrsdTrackingReg;
