import React from 'react';
import { Calendar as CalendarComponent, PagePrincipal } from '../../containers';

const Calendar = () => {
  const year = (new Date()).getFullYear();

  return (
    <PagePrincipal title={`${year}`}>
      <div className='pl-10 text-center'>
        <CalendarComponent year={year}/>
      </div>
    </PagePrincipal>
  );
};
export default Calendar;
