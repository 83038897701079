import React from 'react';
import {
  CityAutocomplete,
  CountryAutocomplete,
  DepartmentAutocomplete,
  TreatmenAutocomplete,
} from '../../../containers';
import { TextField } from '../../../components';
import { IInformationUserVuvProps } from './types';
import { useTranslation } from 'react-i18next';
import { ID_NATURAL_PERSON } from '../constants';
import { TypeTextField } from '../../../components/TextField';

const GeneralInformation = ({
  formState,
  formStateError,
  onChange,
  validateMetaData,
  isOutlined,
}: IInformationUserVuvProps) => {
  const { t } = useTranslation();
  const isNaturalPerson = formState.personType.id === ID_NATURAL_PERSON;
  const textTranslate = isNaturalPerson ? 'pages.user.common.naturalPerson' : 'pages.user.common.legalPerson';
  return (
    <div className='grid grid-cols-1 gap-4 md:grid-cols-2 '>
      <div className='col-span-1'>
        <TextField
          label={t(`${textTranslate}.poste`)}
          value={formState.poste}
          isOutlined={isOutlined}
          error={formStateError?.poste}
          onChange={onChange('poste')}
          textHelp={t('pages.user.common.helpText.poste')}
          maxLength={300}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          isRequired
          isOutlined={isOutlined}
          label={t(`${textTranslate}.phone`)}
          value={formState.phone}
          error={formStateError?.phone}
          type={TypeTextField.number}
          onChange={onChange('phone')}
          textHelp={t('pages.user.common.helpText.phone')}
          maxLength={10}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          isRequired
          isOutlined={isOutlined}
          label={t(`${textTranslate}.address`)}
          value={formState.address}
          error={formStateError?.address}
          onChange={onChange('address')}
          textHelp={t('pages.user.common.helpText.address')}
          maxLength={100}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          isOutlined={isOutlined}
          label={t(`${textTranslate}.address2`)}
          value={formState.address2}
          error={formStateError?.address2}
          onChange={onChange('address2')}
          textHelp={t('pages.user.common.helpText.address2')}
          maxLength={100}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          isOutlined={isOutlined}
          label={t(`${textTranslate}.address3`)}
          value={formState.address3}
          error={formStateError?.address3}
          onChange={onChange('address3')}
          textHelp={t('pages.user.common.helpText.address3')}
          maxLength={100}
          />
      </div>
      <div className='col-span-1'>
        <TreatmenAutocomplete
          isOutlined={isOutlined}
          idSelected={formState.treatment.id}
          error={formStateError['treatment']}
          onChange={onChange('treatment')}
          textHelp={t('pages.user.common.helpText.treatmen')}
          />
      </div>
      <div className='col-span-1'>
        <CountryAutocomplete
          idSelected={formState.country.id}
          isOutlined={isOutlined}
          error={formStateError['country']}
          onChange={onChange('country')}
          textHelp={t('pages.user.common.helpText.country')}
          />
      </div>
      <div className='col-span-1'>
        <DepartmentAutocomplete
          isOutlined={isOutlined}
          idSelected={formState.department.id}
          idCountry={formState.country.id}
          error={formStateError['country']}
          onChange={onChange('department')}
          textHelp={t('pages.user.common.helpText.department')}
          />
      </div>
      <div className='col-span-1'>
        <CityAutocomplete
          isOutlined={isOutlined}
          error={formStateError['city']}
          idSelected={formState.city.id}
          idDepartment={formState.department.id}
          onChange={onChange('city')}
          textHelp={t('pages.user.common.helpText.city')}
        />
      </div>
    </div>
  );
};
export default GeneralInformation;
