import { EnumValidatorType, IValidatorField } from '../../../hooks/validation/useValidator/types';
import { regexEmail } from '../../../constants/Regex';

export const validations: IValidatorField[] = [
  {
    field: 'recoveryMethod',
    validations: {
      required: true,
    },
  },
  {
    field: 'dataRecovery',
    validations: {
      required: true,
      where: {
        fieldParent: 'recoveryMethod',
        validationType: EnumValidatorType.Value,
        value: '1',
        required: true,
        pattern: {
          value: regexEmail,
          message: 'invalidEmail',
        }
      },
    },
  },
];
