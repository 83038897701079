import { IRoutesAccess } from '../containers/Authorization/types';
import RouteNavigation from '../constants/RouteNavigation';
import {
  FoceLoginPage,
  QuestionsFrequentlyPage,
  NoAccessPage,
  CalendarPage,
  ServiceChannelsPage,
  MapSitePage,
  HomePage,
  RecoveryPwPage,
  ChangePasswordPage,
} from '../pages';

export const routesGeneral: IRoutesAccess[] = [
  { url: RouteNavigation.Home, component: HomePage, allowAnonymous: true },
  {
    url: RouteNavigation.FocerLogin,
    component: FoceLoginPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.RecoverPassword,
    component: RecoveryPwPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.UserChangePassword,
    component: ChangePasswordPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.Questions,
    component: QuestionsFrequentlyPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.NoAccess,
    component: NoAccessPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.Calendar,
    component: CalendarPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.ServiceChannels,
    component: ServiceChannelsPage,
    allowAnonymous: true,
  },
  {
    url: RouteNavigation.MapSite,
    component: MapSitePage,
    allowAnonymous: true,
  },
];
