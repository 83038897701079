import { API_BASE } from '../constants';
import { getData } from '../_base';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { PolicyDto } from '../../dto/admin/types';

const base = `${API_BASE}/v1/Policies`;

const url = {
  getByLanguage: `${base}/Policies`,
};

export const getPoliciesByLangApi = async (): Promise<PolicyDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getByLanguage}/${lngDto.id}`;
  const result = await getData<PolicyDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data;
  else return [];
};
