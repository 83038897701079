import { Button, Container, makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserVUVApi, UpdateUserVUVApi } from '../../../api/user';
import { Alert, Loading } from '../../../components';
import {
  PagePrincipal,
} from '../../../containers';
import { IUserVuvDto } from '../../../dto/UserDto';
import { IUserProfileState } from './types';
import { mapDtoToState, mapStateToDto } from './mapper';
import GeneralInformation from '../common/generalInformation';
import BasicInformation from '../common/basicInformation';
import MetaDataInformation from '../common/metaDataInformation';
import LoginInformation from '../common/loginInformation';
import { ContactMetaDataState } from '../../../containers/ContactMetaData/types';
import { IValidatorField } from '../../../hooks/validation/useValidator/types';
import { IAlertTypes } from '../../../components/Alert';
import useValidator from '../../../hooks/validation/useValidator/useValidator';
import { IFormState } from '../common/types';
import { validations } from '../common/validations';

const defaultState: IUserVuvDto = {
  userTypeId: 0,
  personTypeId: 0,
  identificationTypeId: '',
  countryId: '',
  departmentId: '',
  cityId: '',
  treatmentId: '',
  identificationNumber: '',
  company: '',
  fistName: '',
  middleName: '',
  lastName: '',
  middlelastName: '',
  poste: '',
  phone: '',
  phone2: '',
  address: '',
  address2: '',
  address3: '',
  email: '',
  email2: '',
  userName: '',
};

const useStyles = makeStyles((theme) => ({
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSeconday: {
    color: theme.palette.text.secondary,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  backgroundDefault: {
    backgroundColor: theme.palette.background.paper,
  },
  borderTop:{
    borderColor: theme.palette.secondary.main,
  }
}));

const Profile = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [validateMetaData, setValidateMetaData] = useState<number>(0);
  const [openAlertError, setOpenAlertError] = useState<boolean>(false);
  const [formStateError, startValidations] = useValidator<IFormState>();
  const [hasFomrError, setHasFomrError] = useState<boolean>(false);

  const [formState, setFormState] = useState<IUserProfileState>(
    mapDtoToState(defaultState)
  );

  const onChange = (propName: string) => (value: any) => {
    const formStateClone = { ...formState, [propName]: value };
    setFormState(formStateClone);
  };

  const handleOnValidationMetaData = async (
    hasError: boolean,
    metaData: ContactMetaDataState,
    validations: IValidatorField[]
  ) => {
    if (hasError && !showAll) setOpenAlertError(hasError);
    if (!hasError && !hasFomrError) {
      await UpdateUserVUVApi(mapStateToDto(formState, metaData));
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const data = await getUserVUVApi();
      if(data) setFormState(mapDtoToState(data));
      setIsLoading(false);
    };
    fetch();
  }, []);

  const onSave = () => {
    const hasError = !startValidations(formState, validations(formState.identificationType));
    setValidateMetaData(validateMetaData + 1);
    setOpenAlertError(hasError && !showAll);
    setHasFomrError(hasError);
  };

  return (
    <PagePrincipal>
      <Container className='mx-auto my-5'>
        <div className='md:flex no-wrap md:-mx-2 '>
          <div className='w-full md:w-3/12 md:mx-2'>
            <Paper className={clsx('p-3 border-t-4', classes.borderTop)}>
              <h1
                className={clsx(
                  classes.textPrimary,
                  'font-bold text-xl leading-8 my-1 capitalize'
                )}
              >
                {formState?.fistName} {formState?.middleName}{' '}
                {formState?.lastName} {formState?.middlelastName}
              </h1>
              <h3
                className={clsx(
                  classes.textPrimary,
                  'font-lg font-bold italic leading-6'
                )}
              >
                {formState?.nickName}
              </h3>

              <h3
                className={clsx(
                  classes.textPrimary,
                  'font-lg text-semibold leading-6'
                )}
              >
                {formState?.identificationNumber}
              </h3>
              <p className={clsx(classes.textPrimary, 'text-sm leading-6')}>
                {formState?.email}
              </p>
              <ul className='hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm'>
                <li className='flex items-center py-3'>
                  <span>{t('pages.user.profile.status')}</span>
                  <span className='ml-auto'>
                    <span className='bg-green-600 py-1 px-2 rounded text-white text-sm'>
                    {t('pages.user.profile.statusActive')}
                    </span>
                  </span>
                </li>
              </ul>
            </Paper>
            <div className='my-4'></div>
          </div>
          <div className='w-full md:w-9/12 mx-2 '>
            <div className={clsx(classes.backgroundDefault, 'shadow-sm')} >
              <div className={clsx(classes.primaryColor,'w-full text-center  rounded-t-lg')}>
                <h1 className={clsx(classes.textSeconday,'text-3xl p-5')}> {t('pages.user.profile.title')}</h1>
              </div>

              <div className='p-5'>
                <BasicInformation
                  disabledPrincipalData
                  isOutlined
                  formState={formState}
                  formStateError={formStateError}
                  onChange={onChange}
                />
              </div>
              <button
                className={clsx(
                  classes.colorSecondary,
                  'block w-full  text-lg font-semibold rounded-lg  focus:outline-none focus:shadow-outline hover:shadow-xs p-3 my-4'
                )}
                onClick={() => setShowAll(!showAll)}
              >
                {!showAll ? t('pages.user.profile.showAll') : t('pages.user.profile.hideAll')}
              </button>

              <div className={clsx('p-5',showAll ? '' : 'invisible h-0')}>
                <GeneralInformation
                  isOutlined
                  formState={formState}
                  formStateError={formStateError}
                  onChange={onChange}
                />
                <br />
                <MetaDataInformation
                  isOutlined
                  formState={formState}
                  formStateError={formStateError}
                  onChange={onChange}
                  validateMetaData={validateMetaData}
                  onValidationMetaData={handleOnValidationMetaData}
                />
                <br />
                <LoginInformation
                  isOutlined
                  disabledPrincipalData
                  formState={formState}
                  formStateError={formStateError}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className='my-4'>
              <Button
                type='button'
                variant='contained'
                color='primary'
                className='float-right'
                onClick={onSave}
              >
                {t('pages.user.profile.btnUpdateInformation')}
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Alert
        open={openAlert}
        type={IAlertTypes.success}
        onClickClose={() => setOpenAlert(false)}
      >
        <span className='text-lg'> {t('pages.user.profile.messageOk')}</span>
      </Alert>
      <Alert
        open={openAlertError}
        type={IAlertTypes.error}
        duration={2000}
        onClickClose={() => setOpenAlertError(false)}
      >
        <span className='text-lg'> {t('pages.user.profile.messageError')}</span>
      </Alert>
      <Loading loading={isLoading} />
    </PagePrincipal>
  );
};
export default Profile;
