import { Snackbar } from '@material-ui/core';
import React from 'react';

export enum IAlertTypes {
  warning,
  error,
  success,
  info,
}

export enum AlertPosition {
  TopLeft,
  TopRight,
  TopCenter,
  BottomLeft,
  BottomCenter,
  BottomRight,
}

interface IAlertProps {
  open: boolean;
  type: IAlertTypes;
  children: React.ReactNode;
  duration?: number;
  onClickClose?: () => void;
}

const Alert = ({
  open,
  type,
  children,
  duration,
  onClickClose,
}: IAlertProps) => {
  const getColor = (): string => {
    switch (type) {
      case IAlertTypes.error:
        return 'bg-red-600';
      case IAlertTypes.success:
        return 'bg-green-600';
      case IAlertTypes.warning:
        return 'bg-yellow-600';
      default:
        return 'bg-blue-600';
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration || 6000}
      onClose={onClickClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div
        className={`text-white px-6 py-4 border-0 rounded relative mb-4 ${getColor()}`}
      >
        <span className='inline-block align-middle mr-8'>
          <b>{children}</b>
        </span>
        <button
          className='absolute bg-transparent text-xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none'
          onClick={onClickClose}
        >
          <span>×</span>
        </button>
      </div>
    </Snackbar>
  );
};
export default Alert;
