import React, { useEffect, useState } from 'react';
import { getCountriesApi } from '../../../api/AdmInquiries';
import { CountryDto } from '../../../dto/admin/types';
import { useTranslation } from 'react-i18next';
import { AutocompleteStyled } from '../../../components';

interface ICountryAutocompleteProps {
  idSelected?: string;
  error?: string;
  onChange: (value: CountryDto) => void;
  isOutlined? : boolean;
  textHelp?: string;
}

const defaultCountry: CountryDto = {
  id: '',
  name: '',
};

const CountryAutocomplete = ({
  idSelected,
  onChange,
  error,
  isOutlined,
  textHelp,
}: ICountryAutocompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<CountryDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
      const result = await getCountriesApi();
      setOptions(result);
    };
    callApi();
  }, []);

  const getOptionSelected = (): CountryDto => {
    if (idSelected) {
      const selected = options.find((p) => p.id === idSelected);
      return selected ? selected : defaultCountry;
    }
    return defaultCountry;
  };

  const handlerOnChange = (e: any, value: any | null) => {
    if (value) onChange(value as CountryDto);
  };

  return (
    <AutocompleteStyled
      isOutlined={isOutlined}
      error={error}
      options={options}
      label={t('components.countryAutocomplete.label')}
      value={getOptionSelected()}
      onChange={handlerOnChange}
      getOptionLabel={(option: any) => option.name}
      renderInput={() => <></>}
      textHelp={textHelp}
    />
  );
};

export default CountryAutocomplete;
