import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@material-ui/icons/Home';
import { Tooltip, Typography } from '@material-ui/core';
import { INavigationItem } from '../../navigation/types';
import navigationConfig from '../../navigation';
import { KeyCodes } from '../../constants/KeyCode';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [currentRoute, setCurrentRoute] = useState<
    INavigationItem | undefined
  >();

  const [currentRouteParent, setCurrentRouteParent] = useState<
    INavigationItem | undefined
  >();

  
const gerNavigationsBasic = () => {
  const routes = [...navigationConfig];
  const basics = routes.map((p) => {
    const _current = { ...p };
    _current.url = p.url?.split('/:')[0];
    return _current;
  });

  return basics;
};


  const getCurrentNavigationItem = useCallback(() => {
    let current: INavigationItem | undefined;
    let currentParent: INavigationItem | undefined;
    current = navigationConfig.find((p) => `/${p.url}` === location.pathname);
    if (current) {
      setCurrentRouteParent(current);
      return;
    }

    navigationConfig.forEach((element) => {
      if (element.children) {
        const currentChild = element.children.find(
          (p) => `/${p.url}` === location.pathname
        );
        if (currentChild) {
          currentParent = element;
          current = currentChild
         
        }
      }
    });

    if(!current){
      gerNavigationsBasic().forEach((element) => {
        if (element.children) {
          const currentChild = element.children.find(
            (p) => location.pathname.startsWith(`/${p.url}`)
          );
          if (currentChild) {
            currentParent = element;
            current = currentChild
           
          }
        }
      });
    }

    setCurrentRouteParent(currentParent);
    setCurrentRoute(current);

  }, [location.pathname]);

  useEffect(() => {
    setCurrentRouteParent(undefined);
    setCurrentRoute(undefined);
    getCurrentNavigationItem();
  }, [getCurrentNavigationItem, location]);


  const handleClick = (url: string | undefined) => (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if(url === '/')
    history.push(url);
    else if(url)
    history.push(`/${url}`);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === KeyCodes.Enter) e.currentTarget.click();
  }

  return (
    <nav
      className='text-black font-bold my-8 pl-8 rounded'
      aria-label='Breadcrumb'
    >
      <ol className='list-none p-0 inline-flex'>
        <li className='flex items-center'>
          <Tooltip
            title={
              <Typography variant='body2'>
                {t(`navigation.toolTips.home`)}
              </Typography>
            }
            placement='top'
          >
            <a href='/' onClick={handleClick('/')} onKeyDown={handleKeyDown}>
              <HomeIcon color='primary' />
            </a>
          </Tooltip>
          <svg
            className='fill-current w-3 h-3 mx-3'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 320 512'
          >
            <path d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z' />
          </svg>
        </li>
        {currentRouteParent && currentRouteParent.keyTranslate && (
          <li className='flex items-center'>
            <a href='/' onClick={handleClick(currentRouteParent.url)} onKeyDown={handleKeyDown}>
              {t(`navigation.${currentRouteParent.keyTranslate}`)}
            </a>
            <svg
              className='fill-current w-3 h-3 mx-3'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 320 512'
            >
              <path d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z' />
            </svg>
          </li>
        )}
        {currentRoute && (
          <li className='flex items-center'>
            <a
              href='/'
              className='text-gray-500'
              aria-current='page'
              onClick={handleClick(undefined)}
              onKeyDown={handleKeyDown}
            >
              {t(`navigation.${currentRoute.keyTranslate}`)}
            </a>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
