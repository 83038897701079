import React, { Fragment, useEffect, useState } from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { getObservationPqrsApi } from '../../api/reports';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BasicModal } from '../../components';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import 'devextreme/dist/css/dx.material.blue.light.css';
import 'devextreme/dist/css/dx.common.css';
import { ObservationsPqrsDto } from '../../api/reports/types';

interface ObservationsProps {
  docId: string;
  documentNumber: string;
  reload: number,
}

const Observations = ({ docId, reload }: ObservationsProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<ObservationsPqrsDto[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const result = await getObservationPqrsApi(docId);
      if (result) setData(result);
    };
    fetch();
  }, [docId, reload]);

  return (
    <Fragment>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant='contained'
        color='primary'
        endIcon={<InsertCommentIcon fontSize='large' />}
      >
        {t('pages.pqrs.observation.title')}
      </Button>
      <BasicModal
        open={open}
        title={t('pages.pqrs.observation.title')}
        showCancelButton
        titleButtonCancel={t('pages.pqrs.observation.close')}
        onClickCancel={() => {
          setOpen(false);
        }}
        maxWidth='lg'
      >
        <div className=''>
          <DataGrid dataSource={data} showBorders noDataText={t('message.noData')}>
            <Column
              dataField='state'
              dataType='string'
              caption={t('pages.pqrs.observation.table.state')}
              // width={200}
              // minWidth={200}
            />
            <Column
              dataField='observation'
              dataType='string'
              caption={t('pages.pqrs.observation.table.observation')}
              // width={500}
            />
            <Column
              dataField='creationDate'
              dataType='date'
              caption={t('pages.pqrs.observation.table.creationDate')}
              // width={100}
            />
          </DataGrid>
        </div>
      </BasicModal>
    </Fragment>
  );
};
export default Observations;
