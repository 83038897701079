import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteStyled, BasicModal } from '../../../components';

interface IExtensionDaysActionProps {
  addDays: (days: number) => void;
}

const ExtensionDaysAction = ({addDays}: IExtensionDaysActionProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [days, setDays] = useState<string | undefined>();
 
  const { t } = useTranslation();

  const onClickAccept = () => {
    if (days) {
      setOpen(false);
      addDays(+days);
    }
  };


  const getDays = () => {
    const days: string[] = [];
    for (let index = 1; index <= 30; index++) {
      days.push(`${index}`);
    }
    return days;
  }

  const changeDay = (e: any, value: any | null) => {
    setDays(value);
  };

  return (
    <div>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        color='primary'
        variant='contained'
      >
        {t('pages.pqrs.actions.extensionDays.title')}
      </Button>
      <BasicModal
        open={open}
        title={t('pages.pqrs.actions.extensionDays.title')}
        showAcceptButton={!!days}
        showCancelButton
        titleButtonCancel={t('pages.pqrs.actions.extensionDays.btnNon')}
        titleButtonAccept={t('pages.pqrs.actions.extensionDays.btnYes')}
        onClickCancel={() => {
          setOpen(false);
        }}
        onClickAccept={onClickAccept}
      >
        <div className='text-2xl italic flex w-96 justify-center'>
          <AutocompleteStyled
            options={getDays()}
            onChange={changeDay}
            renderInput={() => <></>}
          />
           {t('pages.pqrs.actions.extensionDays.message')}
        </div>
       
      </BasicModal>
    </div>
  );
};
export default ExtensionDaysAction;
