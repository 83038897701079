import { IUserVuvDto } from '../../dto/UserDto';
import { API_BASE } from '../constants';
import { getData, postData, postDataWithOutResponse, putDataWithOutResponse } from '../_base';
import { IChangePasswordRequest, IRecoveryPasswordRequest, IRegisterUserRequest, IUpdateUserRequest } from './types';
import { IErrorApiBase } from '../_base/types';

const base = `${API_BASE}/v1/User`;

const url = {
  getUserVUV: `${base}/`,
  RegisterUserVUV: `${base}/Create`,
  UpdateUserVUV: `${base}/Update`,
  RecoveryPwVUV: `${base}/RecoveryAccessCode`,
  ChangePasswordVUV: `${base}/ChangeCodeAccess`,
};

export const getUserVUVApi = async (): Promise<IUserVuvDto | undefined> => {
  const _url = `${url.getUserVUV}`;
  const result = await getData<IUserVuvDto>(_url);
  if (result && !result.hasError && result.data)
    return result.data as IUserVuvDto;
  return undefined;
};

export const registrerUserVUVApi = async (
  request: IRegisterUserRequest
): Promise<[boolean, IErrorApiBase[] | undefined]> => {
  const _url = `${url.RegisterUserVUV}`;
  const result = await postDataWithOutResponse<IRegisterUserRequest>(
    _url,
    request
  );
  if (!result.hasError) return [true, undefined];
  return [false, result.error?.errors];
};

export const UpdateUserVUVApi = async (
  request: IUpdateUserRequest
): Promise<[boolean, IErrorApiBase[] | undefined]> => {
  const _url = `${url.UpdateUserVUV}`;
  const result = await putDataWithOutResponse<IUpdateUserRequest>(
    _url,
    request
  );
  if (!result.hasError) return [true, undefined];
  return [false, result.error?.errors];
};

export const recoveryPasswordVUVApi = async (
  type: number,
  value: string
): Promise<[boolean, IErrorApiBase[] | undefined]> => {
  const _url = `${url.RecoveryPwVUV}`;
  const data: IRecoveryPasswordRequest = { type, value };
  const result = await postData<IRecoveryPasswordRequest, Boolean>(_url, data);
  if (result && !result.hasError && result.data) return [true, undefined];
  return [false, result.error?.errors ];
};

export const changePasswordVUVApi = async (
  oldPassword: string,
  newPassword: string
): Promise<boolean> => {
  const _url = `${url.ChangePasswordVUV}`;
  const data: IChangePasswordRequest = { oldPassword, newPassword };
  const result = await postData<IChangePasswordRequest, Boolean>(_url, data);
  if (result && !result.hasError) return true;
  return false;
};
