import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionsFrequentlyDto } from '../../dto/QuestionsFrequentlyDto';
import { GetQuestionsFrequentlyByLangApi } from '../../api/questionsFrequently';
import i18n from '../../i18n';
import { PagePrincipal } from '../../containers';
import Panel from './panel';

const QuestionsFrequently = () => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<QuestionsFrequentlyDto[]>([]);
  useEffect(() => {
    const callApi = async () => {
      const result = await GetQuestionsFrequentlyByLangApi();
      setQuestions(result);
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <PagePrincipal
      title={t('pages.questionsFrequently.title')}
      subTitle={t('pages.questionsFrequently.subTitle')}
    >
      <section className='text-gray-700'>
        <div className='container px-5 pb-24 mx-auto'>
          <div className='flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2'>
            <Panel questions={questions} />
          </div>
        </div>
      </section>
    </PagePrincipal>
  );
};
export default QuestionsFrequently;
