import { INavigationItem } from './types';
import RouteNavigation from '../constants/RouteNavigation';

export const navigationPortfolio: INavigationItem =   {
  id: '4',
  keyTranslate: 'portfolio',
  allowAnonymous: true,
  icon: 'snippet_folder',
  children: [
    {
      id: '4.1',
      keyTranslate: 'portfolioMydocuments',
      url: RouteNavigation.PortfolioMyDocuments,
      allowAnonymous: true,
    },
    {
      id: '4.2',
      keyTranslate: 'PortfolioMyExpedients',
      url: RouteNavigation.PortfolioMyExpedients,
      allowAnonymous: true,
    },
    {
      id: '4.3',
      keyTranslate: 'portfolioMynotifications',
      url: RouteNavigation.PortfolioMyNotifications,
      allowAnonymous: true,
      hide: true,
    },
  ],
};