import { PostPrqsDto, IPostPrqsMetaDataDto } from '../../dto/pqrs';
import {
  DocumentMetaDataState,
  TypeControl,
} from '../../containers/DocumentMetaData/types';
import { EnumTypePQRS } from '../../constants/PqrsType';

export const mapStateToDto = (
  typePqrs: EnumTypePQRS,
  metaData: DocumentMetaDataState,
  policyAccepted: boolean,
): PostPrqsDto => {
  const dto: PostPrqsDto = {
    typePqrs,
    policyAccepted,
    metaData: [],
  };

  Object.keys(metaData).forEach((key) => {
    if (metaData[`${key}`].type !== TypeControl.file) {
      dto.metaData!.push({
        id: +key,
        value: getValue(metaData[`${key}`].value),
        text: metaData[`${key}`].text,
        typeElement: metaData[`${key}`].type,
        initial: metaData[`${key}`].initial,
      } as IPostPrqsMetaDataDto);
    }
  });
  return dto;
};

export const mapStateToDtoFiles = (
  metaData: DocumentMetaDataState,
): File[] => {
  let Files: File[] = [];
  const keys = Object.keys(metaData);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (metaData[`${key}`].type === TypeControl.file) {
      const files = metaData[`${key}`].value as File[];
      Files = Files.concat(files);
    }
  }
  return Files;
};


 const getValue = (value: any) => {
  if(Array.isArray(value)){
    return value.join(',');
  }
  return value;
 }