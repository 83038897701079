import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import RouteNavigation from '../../constants/RouteNavigation';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { userContext } from '../../hooks/contexts/userContext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    height: '130px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  avatar: {
    width: 72,
    height: 72,
    position: 'absolute',
    top: 92,
    padding: 8,
    background: theme.palette.background.default,
    boxSizing: 'content-box',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

interface IUserNavbarHeaderProps {
  onChangeStatusAuth: (token: string) => void;
}

const UserNavbarHeader = ({ onChangeStatusAuth }: IUserNavbarHeaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { userAuth } = useContext(userContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onClickMenu = (url?: string) => {
    setAnchorEl(null);
    if (url) history.push(`/${url}`);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AppBar position='static' color='primary' classes={{ root: classes.root }}>
      {userAuth && (
        <Fragment>
          <Typography
            className=' text-16 whitespace-nowrap'
            color='textSecondary'
          >
            {t('components.userHeader.wellcome')}
          </Typography>
          <Typography
            className=' text-16 whitespace-nowrap'
            color='textSecondary'
          >
            {userAuth?.fullName}
          </Typography>
          <Typography
            className=' text-13 mt-8 opacity-50 whitespace-nowrap'
            color='textSecondary'
          >
            {userAuth?.email}
          </Typography>
        </Fragment>
      )}

      <div className='text-center'>
        <IconButton
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        {userAuth && (
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={() => onClickMenu()}
          >
            <MenuItem onClick={() => onClickMenu(RouteNavigation.UserAccount)}>
              <ListItemIcon
                onClick={() => onClickMenu(RouteNavigation.UserAccount)}
              >
                <SettingsIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit'>
                {t('components.userHeader.account')}
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => onClickMenu(RouteNavigation.UserRecovery)}>
              <ListItemIcon
                onClick={() => onClickMenu(RouteNavigation.UserRecovery)}
              >
                <LockOpenIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit'>
                {t('components.userHeader.recoveryPw')}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                onChangeStatusAuth('');
              }}
            >
              <ListItemIcon
                onClick={() => {
                  setAnchorEl(null);
                  onChangeStatusAuth('');
                }}
              >
                <ExitToAppIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit'>
                {t('components.userHeader.logout')}
              </Typography>
            </MenuItem>
          </Menu>
        )}
      </div>
    </AppBar>
  );
};

export default UserNavbarHeader;
