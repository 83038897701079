import React from 'react';
import { makeStyles } from '@material-ui/core';
import { renderImg } from '../utility';
import Section from '../common/section';
import Descriptions from '../common/description';
import Title from '../common/title';

const useStyles = makeStyles((theme) => ({
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
}));

interface IPanel5Props {
  title: string;
  tagLeft: string;
  tagRight: string;
  description: string;
  description2: string;
  img: string;
  url?: string;
}

const Panel5 = ({
  title,
  tagLeft,
  tagRight,
  description,
  description2,
  img,
  url,
}: IPanel5Props) => {
  const classes = useStyles();

  return (
    <Section tagRight={tagRight} tagLeft={tagLeft}>
      <div className='w-full h-full rounded-xl '></div>
      <Title title={title} url={url} center></Title>
      <div className='mb-10'>{renderImg(img, '7rem', classes.secondaryColor)}</div>
      <Descriptions description1={description} description2={description2} />
    </Section>
  );
};
export default Panel5;
