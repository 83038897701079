import { API_BASE } from '../constants';
import { getData } from '../_base';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { ServiceChannelDto } from '../../dto/admin/types';

const base = `${API_BASE}/v1/ServiceChannel/ServiceChannel`;

const url = {
  getByLanguage: `${base}/`,
};

export const getServiceChannelByLangApi = async (): Promise<ServiceChannelDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getByLanguage}${lngDto.id}`;
  const result = await getData<ServiceChannelDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data;
  else return [];
};
