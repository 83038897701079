import {
  IRegisterUserRequest,
  IRegisterUserMetaDataRequest,
} from '../../../api/user/types';
import { IFormState } from '../common/types';

export const mapStateToDto = (
  state: IFormState
): IRegisterUserRequest=> {

  const dto: IRegisterUserRequest = {
    personTypeId: state.personType.id,
    userTypeId: state.userType.id,
    identificationTypeId: state.identificationType.id,
    countryId: state.country.id,
    departmentId: state.department.id,
    cityId: state.city.id,
    treatmentId: state.treatment.id,
    identificationNumber: state.identificationNumber,
    company: state.company,
    fistName: state.fistName,
    middleName: state.middleName,
    lastName: state.lastName,
    middlelastName: state.middlelastName,
    poste: state.poste,
    phone: state.phone,
    phone2: state.phone2,
    address: state.address,
    address2: state.address2,
    address3: state.address3,
    email: state.email,
    email2: state.email2,
    UserName: state.nickName,
    metaData: [],
  };

  dto.metaData = Object.keys(state.metaData).map(
    (key) =>
      ({
        id: +key,
        text: state.metaData[+key].text,
        value: state.metaData[+key].value,
        typeElement: state.metaData[+key].type,
      } as IRegisterUserMetaDataRequest)
  );
  return dto;
};
