export enum EnumModules {
  CHANNELS_ATTENTION ="CHANNELS_ATTENTION",
  FAQS ="FAQS",
  MANUALS ="MANUALS",
  NORMATIVIDAD ="NORMATIVIDAD",
  POLITICS ="POLITICS",
  PQRS_TEXT ="PQRS_TEXT",
  REQUIREMENTS ="REQUIREMENTS",
  INFORMATION_TEXT ="INFORMATION_TEXT",
  PROCEDURES ="PROCEDURES",
}