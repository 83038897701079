import { NormativityOfficeDto } from '../../dto/normativityOffice';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Tooltip, Typography } from '@material-ui/core';
import Descriptions from './desciption';
import { convertDatetoDateFormatted } from '../../utilitys/date';
import { getUrl } from '../../utilitys/url';
import { useTranslation } from 'react-i18next';

interface INormativeProps {
  normatives: NormativityOfficeDto[];
}

const Panel = ({ normatives }: INormativeProps) => {
  const { t } = useTranslation();

  const onDownloadPdf = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className="mt-3 w-full">
      {normatives.map((p) => (
        <Accordion key={p.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel1bh-content${p.id}`}
            id={`panel1bh-header${p.id}`}
          >
            <div className='w-full flex justify-between '>
              <div className='font-bold text-xl w-full text-center font-body'>
                {p.title}
              </div>
              {p.documentUrl && (
                <Tooltip
                  title={
                    <Typography variant='body2'>
                      {t('pages.normativityOffice.tooltipPdf')}
                    </Typography>
                  }
                  placement='top'
                >
                  <div
                    className='space-x-3 flex text-red-700'
                    onClick={() => onDownloadPdf(getUrl(p.documentUrl))}
                  >
                    <PictureAsPdfIcon fontSize='large' />
                  </div>
                </Tooltip>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className='mt-1 w-full flex flex-col p-20 pt-0 font-body'>
              <span className='text-lg font-bold mt-3'>
                {t('pages.normativityOffice.labelType')}:
              </span>
              <span className='text-lg'>{p.type}</span>
              <span className='text-lg font-bold mt-3'>
                {t('pages.normativityOffice.labelExpeditionDate')}
              </span>
              <span className='text-lg'>
                {convertDatetoDateFormatted(p.expeditionDate)}
              </span>
              <span className='text-lg font-bold mt-3'>
                {t('pages.normativityOffice.labelPublicationDate')}
              </span>
              <span className='text-lg'>
                {convertDatetoDateFormatted(p.publicationDate)}
              </span>
              <span className='text-lg font-bold mt-3'>
                {t('pages.normativityOffice.labelName')}
              </span>
              <span className='text-lg'>{p.title}</span>
              <span className='text-lg font-bold mt-3'>
                {t('pages.normativityOffice.labelDescription')}
              </span>
              <Descriptions
                description1={p.paragraph1}
                description2={p.paragraph2}
                description3={p.paragraph3}
                description4={p.paragraph4}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
export default Panel;
