import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Dialog, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IErrorApiBase } from '../../api/_base/types';
import { ErrorField } from './types';
import ErrorIcon from '@material-ui/icons/Error';

interface IErrorDisplaysProps {
  errors: IErrorApiBase[] | undefined;
  module: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ErrorDisplays = ({ errors, module }: IErrorDisplaysProps) => {
  const [errorField, setErrorField] = useState<ErrorField[]>([]);
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!errors) {
      setOpen(false);
      return;
    }

    const errorsMap = errors.map((p) => {
      return {
        message: t(`message.validation.${module}.${p.message}`),
      } as ErrorField;
    });

    setOpen(true);
    setErrorField(errorsMap);
  }, [errors, module, t]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          <div className='text-red-600 text-xl space-x-3'>
            <ErrorIcon />
            <span>{t('components.errorDisplays.title')}</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='flex flex-col space-y-2 text-lg'>
            {errorField.map((p) => (
              <span key={p.message}>{p.message}</span>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t('components.errorDisplays.btnClose')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ErrorDisplays;
