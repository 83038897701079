import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Captcha from './Captcha';
import { IS_DEV } from '../constants/App';

interface IButtonCaptchaProps {
  textButton: string;
  onClick: () => void;
  startIcon?: React.ReactNode;
  fullWidth?: boolean;
  centerContent?: boolean;
}

const ButtonCaptcha = ({
  textButton,
  onClick,
  startIcon,
  fullWidth,
  centerContent,
}: IButtonCaptchaProps) => {
  const [isCaptchaOk, setIsCaptchaOk] = useState<boolean>(IS_DEV);

  const handleOnChangeCaptcha = (isValide: boolean) => setIsCaptchaOk(isValide);

  return (
    <Fragment>
      <div
        className={clsx('w-full ', centerContent ? 'flex justify-center' : '')}
      >
        {!isCaptchaOk && <Captcha onChange={handleOnChangeCaptcha} />}
        {isCaptchaOk && (
          <Button
            type='button'
            fullWidth={fullWidth}
            variant='contained'
            color='primary'
            startIcon={startIcon}
            onClick={() => onClick()}
          >
            {textButton}
          </Button>
        )}
      </div>
    </Fragment>
  );
};
export default ButtonCaptcha;
