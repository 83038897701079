import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface ILoadingProps {
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loading = ({ loading }: ILoadingProps) => {
  const classes = useStyles();
  return (
     <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
       <CircularProgress color='inherit' />
     </Backdrop>
  );
};

export default Loading;
