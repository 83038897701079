import React from 'react';

interface IPanelDefaultProps {
  title: string;
  description: string;
  date?: string;
  tag?: string;
  url?: string;
}

const PanelDefault = ({
  title,
  tag,
  description,
  date,
}: IPanelDefaultProps) => {
  return (
    <div className='mt-2 bg-red-900'>
      <span className=''>
        {title}
      </span>
      <p className=''>{description}</p>
    </div>
  );
};
export default PanelDefault;
