import {
  ContextAppDto,
} from '../../dto/ContextAppDto';
import { API_BASE } from '../constants';
import { getData } from '../_base';

const base = `${API_BASE}/v1/UserEnvironment`;

const url = {
  getContext: `${base}/UserEnvironment`,
};

export const getContextsAppApi = async (): Promise<ContextAppDto[]> => {
  const _url = `${url.getContext}`;
  const result = await getData<ContextAppDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data as ContextAppDto[];
  return [];
};
