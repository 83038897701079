import { API_BASE } from '../constants';
import { getData } from '../_base';
import { BpmUrlResponse } from './dto';
const base = `${API_BASE}/v1/BPM`;

const url = {
  GetUrlMyProcess: `${base}/GetUrlMyProcess`,
  GetUrlProcessDetail: `${base}/GetUrlProcessDetail`,
  GetInformationProcess: `${base}/GetInformationProcess`,
};

export const getUrlMyProcess = async (): Promise<string> => {
  const _url = `${url.GetUrlMyProcess}`;
  const result = await getData<BpmUrlResponse>(_url);
  if (result && !result.hasError && result.data) return result.data.url;
  else return '';
};

export const getUrlProcessDetail = async (idProcessInstance: number): Promise<string> => {
  const _url = `${url.GetUrlProcessDetail}/${idProcessInstance}`;
  const result = await getData<BpmUrlResponse>(_url);
  if (result && !result.hasError && result.data) return result.data.url;
  else return '';
};
