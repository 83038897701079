import { getData } from '../_base';
import { API_ECM } from '../constants';
import { MetaDataTableConfigDto } from '../../dto/MetadataTableConfigDto';

const base = `${API_ECM}/v2/MetaDataTableConfig`;

const url = { getMetaDataTableConfig: `${base}/Get` };

export const getMetaDataTableConfig = async (
    metadataId: number
): Promise<MetaDataTableConfigDto[]> => {
  const _url = `${url.getMetaDataTableConfig}/${metadataId}`;
  const result = await getData<MetaDataTableConfigDto[]>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return [];
};
