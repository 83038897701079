import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.spacing(1)
  },
  border: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    width: '100%'
  },
  content: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: theme.spacing(1),
    textAlign: 'center',
    whiteSpace: 'nowrap'
  }
}));

interface IDividerWithTextProps {
    text?: string
}

const DividerWithText = ({ text }: IDividerWithTextProps) => {
    const classes = useStyles();
    return (
     <div className={classes.container}>
       <div className={classes.border} />
       <span className={classes.content}>{text}</span>
       <div className={classes.border} />
     </div>
    );
};

export default DividerWithText;
