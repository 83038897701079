import { API_AUTHENTICATION } from '../constants';
import { getData, postData } from '../_base';
import { ILoginRequestApi, ILoginResponseApi } from './types';
import { IErrorApiBase } from '../_base/types';

const base = `${API_AUTHENTICATION}/v1/Login`;

const url = {
  loginVUV: `${base}/VUV`,
  RefreshTokenApi: `${base}/RefeshToken`
}

export const LoginVUVApi = async (
  request: ILoginRequestApi
): Promise<[boolean, IErrorApiBase[] | undefined | string]> => {
    const result = await postData<ILoginRequestApi, ILoginResponseApi>(url.loginVUV, request);

    if(!result.hasError) return [true, result?.data?.token];
    return [false, result.error?.errors];
};

export const RefreshTokenApi = async (): Promise<[boolean, IErrorApiBase[] | undefined | string]> => {
    const result = await getData<ILoginResponseApi>(url.RefreshTokenApi);
    if(!result.hasError) return [true, result?.data?.token];
    return [false, result.error?.errors];
};

