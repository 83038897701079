import {
  IdentificationTypeAutocomplete,
  PersonTypeAutocomplete,
  UserTypeAutocomplete,
} from '../../../containers';
import { TextField } from '../../../components';
import { ID_NATURAL_PERSON } from '../constants';
import { IInformationUserVuvProps } from './types';
import { useTranslation } from 'react-i18next';

const BasicInformation = ({ formState, formStateError, isOutlined, onChange, disabledPrincipalData }: IInformationUserVuvProps) => {
  const { t } = useTranslation();
  const isNaturalPerson = formState.personType.id === ID_NATURAL_PERSON;
  const textTranslate = isNaturalPerson ? 'pages.user.common.naturalPerson' : 'pages.user.common.legalPerson';
  return (
    <div className='grid grid-cols-1 gap-4 md:grid-cols-2 '>
      <div className='col-span-1'>
        <UserTypeAutocomplete
          isOutlined={isOutlined}
          isRequired
          idSelected={formState.userType.id}
          error={formStateError["userType"]}
          onChange={onChange('userType')}
          textHelp={t('pages.user.common.helpText.userType')}
          />
      </div>
      <div className='col-span-1'>
      </div>
      <div className='col-span-1'>
        <PersonTypeAutocomplete
          disabled={disabledPrincipalData}
          isOutlined={isOutlined}
          isRequired
          idSelected={formState.personType.id}
          error={formStateError["personType"]}
          onChange={onChange('personType')}
          textHelp={t('pages.user.common.helpText.personType')}
          />
      </div>
      <div className='col-span-1'>
        <IdentificationTypeAutocomplete
           disabled={disabledPrincipalData}
          isOutlined={isOutlined}
          isRequired
          idSelected={formState.identificationType.id}
          idPersonType={formState.personType.id}
          onChange={onChange('identificationType')}
          error={formStateError["identificationType"]}
          textHelp={t('pages.user.common.helpText.identificationType')}
          />
      </div>
      <div className='col-span-1'>
        <TextField
           disabled={disabledPrincipalData}
          isRequired
          isOutlined={isOutlined}
          label={t(`${textTranslate}.identification`)}
          value={formState.identificationNumber}
          error={formStateError["identificationNumber"]}
          onChange={onChange('identificationNumber')}
          textHelp={t('pages.user.common.helpText.identification')}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          isRequired={!isNaturalPerson}
          isOutlined={isOutlined}
          label={t(`${textTranslate}.company`)}
          value={formState.company}
          error={formStateError["company"]}
          onChange={onChange('company')}
          textHelp={t('pages.user.common.helpText.company')}
          maxLength={300}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          isOutlined={isOutlined}
          isRequired
          label={t(`${textTranslate}.fistName`)}
          value={formState.fistName}
          error={formStateError["fistName"]}
          onChange={onChange('fistName')}
          textHelp={t('pages.user.common.helpText.firstName')}
          maxLength={50}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          label={t(`${textTranslate}.middleName`)}
          isOutlined={isOutlined}
          value={formState.middleName}
          error={formStateError["middleName"]}
          onChange={onChange('middleName')}
          textHelp={t('pages.user.common.helpText.middleName')}
          maxLength={50}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          isRequired
          isOutlined={isOutlined}
          label={t(`${textTranslate}.lastName`)}
          value={formState.lastName}
          error={formStateError["lastName"]}
          onChange={onChange('lastName')}
          textHelp={t('pages.user.common.helpText.lastName')}
          maxLength={50}
          />
      </div>
      <div className='col-span-1'>
        <TextField
          isOutlined={isOutlined}
          label={t(`${textTranslate}.middlelastName`)}
          value={formState.middlelastName}
          error={formStateError["middlelastName"]}
          onChange={onChange('middlelastName')}
          textHelp={t('pages.user.common.helpText.middleLastName')}
        />
      </div>
    </div>
  );
};
export default BasicInformation;
