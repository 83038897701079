import React, { useEffect, useState } from 'react';
import { getIdentificationTypeApi } from '../../../api/AdmInquiries';
import { IdentificationTypeDto } from '../../../dto/admin/types';
import { useTranslation } from 'react-i18next';
import { AutocompleteStyled } from '../../../components';

interface IIdentificationTypeAutocompleteProps {
  idPersonType?: number;
  idSelected?: string;
  isRequired? : boolean;
  error?: string;
  isOutlined? :boolean,
  disabled?:boolean;
  onChange: (value: IdentificationTypeDto) => void;
  textHelp?: string;
}

const defaultcity: IdentificationTypeDto = {
  id: '',
  description: '',
  personTypeId: '',
};

const IdentificationTypeAutocomplete = ({
  idSelected,
  idPersonType,
  isRequired,
  isOutlined,
  onChange,
  error,
  disabled,
  textHelp,
}: IIdentificationTypeAutocompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<IdentificationTypeDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
      if (idPersonType) {
        const result = await getIdentificationTypeApi(idPersonType);
        setOptions(result);
      }
    };
    callApi();
  }, [idPersonType]);

  const getOptionSelected = (): IdentificationTypeDto => {
    if (idSelected) {
      const selected = options.find((p) => p.id === idSelected);
      return selected ? selected : defaultcity;
    }
    return defaultcity;
  };

  const handlerOnChange = (e: any, value: any | null) => {
    if (value) {
      onChange(value as IdentificationTypeDto);
    }
  };

  return (
    <AutocompleteStyled
      disabled={disabled}
      isOutlined={isOutlined}
      error={error}
      isRequired={isRequired}
      options={options}
      value={getOptionSelected()}
      label={t('components.identificationTypeAutocomplete.label')}
      onChange={handlerOnChange}
      getOptionLabel={(option: any) => option.description}
      renderInput={() => <></>}
      textHelp={textHelp}
    />
  );
};

export default IdentificationTypeAutocomplete;
