import { API_BASE } from '../constants';
import { getData, postData } from '../_base';
import { IMyExpedientsDto, IDocumentDescriptionDto } from '../../dto/Reports';
import {
  BasicDocumentRequest,
  GetMyDocumentsApiResponse,
  MyDocumentsRequest,
  ObservationsPqrsDto,
  SearchResultDto,
} from './types';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { typeDocument } from '../../pages/portfolio/myDocuments/types';

const base = `${API_BASE}/v1/Reports`;

const url = {
  GetMyExpedients: `${base}/myExpedients`,
  GetMyDocumentsApi: `${base}/myDocuments`,
  GetDocuments: `${base}/Documents`,
  GetObservationsPQRS: `${base}/ObservationsPQRS`,
  Search: `${base}/Search`,
};

export const getMyExpedientsApi = async (): Promise<IMyExpedientsDto[]> => {
  const _url = `${url.GetMyExpedients}`;
  const result = await getData<IMyExpedientsDto[]>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return [];
};

export const getMyDocumentsApi = async (): Promise<
  [
    IDocumentDescriptionDto[],
    IDocumentDescriptionDto[],
    IDocumentDescriptionDto[]
  ]
> => {
  const _url = `${url.GetMyDocumentsApi}`;
  const result = await getData<GetMyDocumentsApiResponse>(_url);

  if (result && !result.hasError && result.data) {
    return [result.data.pqrs, result.data.other, result.data.process];
  } else return [[], [], []];
};

export const getDocumentsApi = async (
  id: string,
  type: typeDocument
): Promise<IDocumentDescriptionDto | undefined> => {
  const _url = `${url.GetDocuments}`;
  const dto: MyDocumentsRequest = { id, type };
  const result = await postData<MyDocumentsRequest, IDocumentDescriptionDto>(
    _url,
    dto
  );
  if (result && !result.hasError && result.data) {
    return result.data;
  } else return undefined;
};

export const getObservationPqrsApi = async (
  docId: string
): Promise<ObservationsPqrsDto[] | undefined> => {
  const _url = `${url.GetObservationsPQRS}`;
  const dto: BasicDocumentRequest = { docId };
  const result = await postData<BasicDocumentRequest, ObservationsPqrsDto[]>(
    _url,
    dto
  );
  if (result && !result.hasError && result.data) {
    return result.data;
  } else return undefined;
};

export const searchApi = async (
  text: string
): Promise<SearchResultDto[] | undefined> => {
  const _url = `${url.Search}`;
  const lngDto = getCurrentLanguageDto();
  const dto = { text, language: lngDto.id };
  const result = await postData<{}, SearchResultDto[]>(
    _url,
    dto
  );
  if (result && !result.hasError && result.data) {
    return result.data;
  } else return undefined;
};