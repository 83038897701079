import clsx from 'clsx';
import { Fragment } from 'react';

interface ITitleFooterProps {
  text?: string;
  textColor?: string;
}

const TitleFooter = ({ text, textColor }: ITitleFooterProps) => {
  return text ? (
    <span className={clsx('text-2xl mb-3 font-semibold font-body')} style={{color: `${textColor}`}}>{text}</span>
  ) : (
    <Fragment />
  );
};

export default TitleFooter;