import React, { useRef } from 'react';
import { BasicModal } from '../../components';
import SignatureCanvas from 'react-signature-canvas';

interface ISignatureModalProps {
  isModalOpen: boolean;
  onClickAccept: (signatureBase64: string) => void;
  onClickCancel: () => void;
}

const SignatureModal = ({
  onClickAccept,
  onClickCancel,
  isModalOpen,
}: ISignatureModalProps) => {
  const refSignature = useRef<any>();
  const onClickAcceptLocal = () => {
    const signature = refSignature.current?.getTrimmedCanvas().toDataURL("image/png");
    if (signature) onClickAccept(signature);
  };

  return (
    <BasicModal
      open={isModalOpen}
      maxWidth='xl'
      title='Firma'
      showAcceptButton
      titleButtonAccept='Firmar'
      onClickAccept={onClickAcceptLocal}
      showCancelButton
      titleButtonCancel='Cancel'
      onClickCancel={onClickCancel}
    >
      <div>
        <SignatureCanvas
          ref={refSignature}
          canvasProps={{ width: 900, height: 400, className: ' border-2' }}
        />
      </div>
    </BasicModal>
  );
};
export default SignatureModal;
