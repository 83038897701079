import React, { useContext } from 'react';
import contextApp from '../../../hooks/contexts/contextApp';

const TopBar = () => {
  const contextApplication = useContext(contextApp);
  return (
    <div>
      {contextApplication.topBarHeader?.isActive && (
        <div
          className={'flex w-full h-10 relative'}
          style={{
            backgroundColor: contextApplication.topBarHeader.color,
          }}
        >
          <div className=' w-full grid grid-cols-5 h-10'>
            <div className='col-span-1'></div>
            <div className='col-span-3'>
              {
                contextApplication.topBarHeader?.urlImg && (
                  <img
                    src={`${contextApplication.topBarHeader.urlImg}`}
                    alt='logo'
                    className='h-8 bg-transparent'
                    onClick={() =>
                      window.open(contextApplication.topBarHeader.urlLink, '_blank')
                    }
                  />
                )
              }
            </div>
            <div className='col-span-1'></div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TopBar;
