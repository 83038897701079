import { IRoutesAccess } from '../containers/Authorization/types';
import RouteNavigation from '../constants/RouteNavigation';
import {
  BpmProcessPage,
  ProcessDetailPage,
  ProcessHomePage,
} from '../pages';
import Roles from '../constants/Roles';

export const routesProcess: IRoutesAccess[] = [
  {
    url: RouteNavigation.Process,
    component: ProcessHomePage,
    allowAnonymous: true,
  },
  {
    url: `${RouteNavigation.ProcessDetail}/:processId`,
    component: ProcessDetailPage,
    allowAnonymous: true,
  },
  {
    url: `${RouteNavigation.ProcessBpm}/:processId/:id`,
    component: BpmProcessPage,
    allowAnonymous: false,
    rolesAccesss: [Roles.GuestVUV],
  }
];
