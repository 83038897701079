import React from 'react';
import { useTranslation } from 'react-i18next';
import Pqrsd from '..';
import { EnumTypePQRS } from '../../../constants/PqrsType';

const PqrsdAuthenticated = () => {
  const { t } = useTranslation();
  return (
    <Pqrsd
      title={t('pages.pqrs.authenticated.title')}
      subTitle={t('pages.pqrs.authenticated.subTitle')}
      typePqrs={EnumTypePQRS.Registered}
    />
  );
};
export default PqrsdAuthenticated;
