import { useEffect, useState } from 'react';
import { EnumTypePQRS } from '../constants/PqrsType';
import { useTranslation } from 'react-i18next';
import { getByLanguageIdAndType } from '../api/pqrs/index';
import { Typography } from '@material-ui/core';
import { DocumentTypeDto } from '../dto/admin/types';
import { getDocumentTypeById } from '../api/documentTypes/index';
import DangerouslySetInnerHTML from './DangerouslySetInnerHTML';

interface IMessageTermsProps {
    typePqrs: EnumTypePQRS;
}

export const MessageTerms = ({ typePqrs }: IMessageTermsProps) => {
    const { t, i18n: { language } } = useTranslation();
    const [documentType, setDocumentType] = useState<DocumentTypeDto>();

    useEffect(() => {
        const fetch = async () => {
          const pqrsInfo = await getByLanguageIdAndType(typePqrs);
          if (pqrsInfo?.documentTypeId) {
            const docType = await getDocumentTypeById(pqrsInfo.documentTypeId);
            if (docType) setDocumentType(docType);
          }
        };
        fetch();
    }, [typePqrs, language]);

    return (
        <div className='pb-10'>
            {
                documentType?.messageTerms
                ? (
                    <>                    
                        <Typography variant='body1' color='textPrimary'><b>{t('pages.pqrs.common.noteLabel')}</b></Typography>
                        <DangerouslySetInnerHTML text={documentType.messageTerms} />
                    </>
                )
                : (
                    <Typography variant='body1' color='textPrimary'>
                        <b>{t('pages.pqrs.common.noteLabel')}</b>{' '}
                        { t('pages.pqrs.common.noteText') }
                    </Typography>
                )
            }
        </div>
    )
}