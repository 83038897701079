import { INavigationItem } from './types';
import RouteNavigation from '../constants/RouteNavigation';

export const navigationOutSite: INavigationItem = {
  id: '3',
  keyTranslate: 'outSite',
  allowAnonymous: true,
  icon: 'maps_home_work',
  children: [
    {
      id: '3.1',
      keyTranslate: 'outSiteManual',
      allowAnonymous: true,
      url: RouteNavigation.OurSiteManual,
    },
    {
      id: '3.2',
      keyTranslate: 'OurSiteRegulation',
      allowAnonymous: true,
      url: RouteNavigation.OurSiteRegulation,
    },
    {
      id: '3.3',
      keyTranslate: 'OurSiteTechnicalRequirements',
      allowAnonymous: true,
      url: RouteNavigation.OurSiteTechnicalRequirements,
    },
    {
      id: '3.4',
      keyTranslate: 'politics',
      allowAnonymous: true,
      url: RouteNavigation.OurSitePolitics,
    },
  ],
};
