import { API_ECM } from '../constants';
import {
  getData,
} from '../_base';
import { ExtensionDto } from '../../dto/extensionDto';
import { ExtensionTypeReference } from './../../constants/ExtensionTypeReference';

const baseEcm = `${API_ECM}/v2`;

const url = {
  getExtensions: `${baseEcm}/AdmInquiries/Extension`,
};

export const getExtensionsByDocumentType = async (
  documentTypeId?: number
): Promise<ExtensionDto | undefined> => {
  const _url = `${url.getExtensions}/${ExtensionTypeReference.TypeDocument}/${documentTypeId}`;
  const result = await getData<ExtensionDto>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};
