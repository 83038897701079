import React, { Fragment, useRef } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface TooltipHelpProps {
  text: string;
}
const limitText = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
      pointerEvents: 'auto',
      maxWidth: '30%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '50%'
      }
    },
  })
);

const TooltipHelp = ({ text }: TooltipHelpProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [seeMore, setSeeMore] = React.useState<boolean>(false);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSeeMore(false);
    setAnchorEl(btnRef.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <IconButton aria-label='help' size='small'
        ref={btnRef}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        <ContactSupportIcon fontSize='inherit' />
      </IconButton>
      <Popover
        id='mouse-over-popover'
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        onMouseLeave={handlePopoverClose}
        onMouseEnter={handlePopoverOpen}
        disableRestoreFocus
      >
        {
          text.length > limitText
          ?
          <Typography>
              { seeMore ? text : `${text.slice(0, limitText)}...` }
              {
                !seeMore &&
                (
                  <span
                    onClick={() => setSeeMore(true)}
                    className='text-blue-400 hover:text-blue-500 cursor-pointer'
                  >
                    { t('components.tooltipHelp.seeMore') }
                  </span>
                )
              }
          </Typography>
          :
          <Typography>{text}</Typography>
        }
      </Popover>
    </Fragment>
  );
};
export default TooltipHelp;