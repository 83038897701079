import React from 'react';
import clsx from 'clsx';
import GlobalFilter from './GlobalFilter';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

interface ITableToolbarProps {
  title: string,
  numSelected: number;
  setGlobalFilter: () => void;
  preGlobalFilteredRows: any[];
  globalFilter: string;
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    flex: '1 1 100%',
  },
}));

const TableToolbar = ({ title, numSelected, preGlobalFilteredRows, setGlobalFilter, globalFilter }: ITableToolbarProps) => {
  const classes = useToolbarStyles();
  const { t } = useTranslation();
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color='inherit' variant='subtitle1'>
          {numSelected} {t('components.tableReactMaterial.selected')}
        </Typography>
      ) : (
        <Typography className={classes.title} variant='h6' id='tableTitle'>
          {title}
        </Typography>
      )}

        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
    </Toolbar>
  );
};

export default TableToolbar;
