import { API_BASE } from '../constants';
import { getData } from '../_base';
import { QuestionsFrequentlyDto } from '../../dto/QuestionsFrequentlyDto';
import { getCurrentLanguageDto } from '../../utilitys/language';

const base = `${API_BASE}/v1/Faqs/Faqs`;

const url = {
  getByLanguage: `${base}`,
};

export const GetQuestionsFrequentlyByLangApi = async (): Promise<
  QuestionsFrequentlyDto[]
> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getByLanguage}/${lngDto.id}`;
  const result = await getData<QuestionsFrequentlyDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data;
  else return [];
};
