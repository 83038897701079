import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { DangerouslySetInnerHTML } from '../../components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RouteNavigation from '../../constants/RouteNavigation';
import { useContext, useEffect, useState } from 'react';
import { userContext } from '../../hooks/contexts/userContext';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DocumentInfoDto } from '../../dto/Process';
import ProcessDocuments from '../../components/ProcessDocuments';

interface IAccordionProcessProps {
  id: number;
  idBpm: string;
  title: string;
  duration: string;
  price: string;
  description: string;
  isAccessibleByUser: boolean;
  documentsUrl: DocumentInfoDto[];
  idDocumentType: number;
}
const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.text.primary,
  },
}));

const AccordionProcess = ({
  id,
  idBpm,
  title,
  duration,
  price,
  description,
  isAccessibleByUser,
  documentsUrl,
  idDocumentType
}: IAccordionProcessProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { userAuth } = useContext(userContext);
  const [hasProcessDocuments, setHasProcessDocuments] = useState<Boolean>(false);

  useEffect(() => {
    if (documentsUrl && documentsUrl.length > 0) setHasProcessDocuments(true);
  }, [documentsUrl]);

   const OnStartProcess = () => {
    const path = userAuth ? `/${RouteNavigation.ProcessBpm}/${idBpm}/${id}` : `/${RouteNavigation.FocerLogin}`;
    history.push(path);
  };

  return (
    <div className='mt-3 w-full'>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel1bh-content${id}`}
          id={`panel1bh-header${id}`}
        >
          <div className='w-full flex justify-between'>
            <div className='font-bold text-lg font-title'>{title}</div>
            <div className='space-x-3 flex'>
              <QueryBuilderIcon />
              <span className='mt-1'>{duration}</span>
              <LocalAtmIcon />
              <span className='mt-1'>{price}</span>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='w-full'>
              {(!userAuth || isAccessibleByUser) && idDocumentType != null ? (
                <div className={`flex mb-8 ${ hasProcessDocuments ? 'justify-between' : 'justify-end' }`}>
                  { hasProcessDocuments && <ProcessDocuments documentsUrl={ documentsUrl } /> }
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={OnStartProcess}
                    endIcon={<ArrowForwardIosIcon/>}
                  >
                    {t('pages.processHome.start')}
                  </Button>
                </div>
              )   : idDocumentType == null ? ''
              : (
                <>
                  { hasProcessDocuments && <ProcessDocuments documentsUrl={ documentsUrl } /> }
                  <div className='font-bold italic text-lg text-red-500 text-center my-5'>
                    {t('pages.processHome.processItIsNotAccessibleByUser')}
                  </div>
                </>
              )}
            <div
              className={clsx(
                classes.textPrimary,
                'mt-1'
              )}
              style={{maxWidth: 'fit-content'}}
            >
              <DangerouslySetInnerHTML text={description} />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AccordionProcess;
