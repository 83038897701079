import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  textColorPrimary: {
    color: theme.palette.text.primary,
  },
  textColorSecondary: {
    color: theme.palette.secondary.main,
  },
}));

interface ISectionProps {
  children: any;
  tagLeft: string;
  tagRight: string;
}

const Section = ({ children, tagLeft, tagRight }: ISectionProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, 'rounded-lg shadow-md m-3 p-12')}>
      <div className='flex justify-between items-center'>
        <span className={clsx(classes.textColorPrimary, 'pl-2 italic font-body')}>
          {tagLeft}
        </span>
        <span className={clsx(classes.textColorPrimary, 'font-bold rounded font-body')}>
          {tagRight}
        </span>
      </div>
      <div className='overflow-hidden'>{children}</div>
    </div>
  );
};
export default Section;
