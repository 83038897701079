import React from 'react';
import {
  ContactMetaData,
} from '../../../containers';
import { IInformationUserVuvProps } from './types';

const MetaDataInformation = ({
  formState,
  onChange,
  validateMetaData,
  onValidationMetaData,
  isOutlined
}: IInformationUserVuvProps) => {
  return (
    <div className='grid grid-cols-1 gap-4 md:grid-cols-2 '>
      <ContactMetaData
        isOutlined={isOutlined}
        state={formState.metaData}
        personTypeId={formState.personType.id}
        validateData={validateMetaData!}
        onValidationMetaData={onValidationMetaData}
      />
    </div>
  );
};
export default MetaDataInformation;
