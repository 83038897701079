import { API_BASE } from '../constants';
import { getData } from '../_base';
import { NormativityOfficeDto } from '../../dto/normativityOffice';
import { getCurrentLanguageDto } from '../../utilitys/language';

const base = `${API_BASE}/v1/NormativityOffice`;

const url = {
  getByLanguage: `${base}/NormativityOffice`,
};

export const getNormativityOfficeByLangApi = async (): Promise<NormativityOfficeDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getByLanguage}/${lngDto.id}`;
  const result = await getData<NormativityOfficeDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data;
  else return [];
};
