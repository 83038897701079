import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { InputLabel, makeStyles } from '@material-ui/core';
import CalendarMonth from './calendar';
import * as dateFns from 'date-fns';
import { useTranslation } from 'react-i18next';
import { BasicModal } from '..';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyCodes } from '../../constants/KeyCode';

const useStyles = makeStyles((theme) => ({
  bakgroundSecondary: {
    backgroundColor: theme.palette.background.paper,
  },
  primaryText: {
    color: theme.palette.text.primary,
  },
}));

interface IDatePickerProps {
  label: string;
  initalDate?: Date;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  error?: string;
  onAcceptDate: (date: Date | undefined, hasError: boolean) => void;
}
const DatePicker = ({
  label,
  initalDate,
  onAcceptDate,
  disabled,
  minDate,
  maxDate,
  error,
}: IDatePickerProps) => {
  const classes = useStyles();
  const [date, setDate] = useState<Date | undefined>(initalDate);
  const [final, setFinal] = useState<Date | undefined>(initalDate);
  const [openModal, setOpenModal] = useState(false);
  const [hasErrorMaxDate, setHasErrorMaxDate] = useState(false);
  const [hasErrorMinDate, setHasErrorMinDate] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setDate(initalDate);
    setFinal(initalDate);
  }, [initalDate]);

  const onChangeDate = (date: Date) => {
    if (maxDate && date && !dateFns.isBefore(date, dateFns.addDays(maxDate, 1)))
      setHasErrorMaxDate(true);
    else setHasErrorMaxDate(false);
    if (minDate && date && dateFns.isBefore(date, minDate))
      setHasErrorMinDate(true);
    else setHasErrorMinDate(false);
    setDate(date);
  };

  const getDateFormatted = (dateToFormat: Date | undefined) =>
    dateToFormat
      ? `${dateFns.getDate(dateToFormat)} ${t(
          `month.${dateFns.getMonth(dateToFormat)}`
        )} ${dateFns.getYear(dateToFormat)}`
      : '';

  const onClickOpenModal = () => {
    if (!disabled) {
      setOpenModal(true);
    }
  };

  const onClickRemove = () => {
    if (!disabled) {
      setFinal(undefined);
      setDate(undefined);
      onAcceptDate(undefined, hasErrorMaxDate || hasErrorMinDate);
    }
  };

  const onKeyDownOpenModal = (e: any) => {
    if (e.keyCode === KeyCodes.Enter) e.currentTarget.click();
  }

  return (
    <Fragment>
      <BasicModal
        open={openModal}
        title={label}
        showAcceptButton
        titleButtonAccept='Aceptar'
        onClickAccept={() => {
          setFinal(date);
          onAcceptDate(date, hasErrorMaxDate || hasErrorMinDate);
          setOpenModal(false);
        }}
      >
        <label className='font-bold text-xl mb-1 text-gray-700 block text-center capitalize'>
          {getDateFormatted(date)}
        </label>
        <CalendarMonth onChangeDate={onChangeDate} initialDate={date} />
      </BasicModal>
      <div>
        <div className='h-10'>
          <InputLabel className={classes.primaryText}>{label}</InputLabel>
        </div>
        <div className={'relative h-16'}>
          <input
            readOnly
            type='text'
            value={getDateFormatted(final)}
            className={clsx(
              classes.bakgroundSecondary,
              classes.primaryText,
              'w-full h-full pl-4 pr-10 py-3 rounded-md border ',
              error || hasErrorMaxDate || hasErrorMinDate
                ? 'border-red-500'
                : ''
            )}
            onClick={onClickOpenModal}
            onKeyDown={onKeyDownOpenModal}
          />

          {final && (
            <div
              className='absolute top-3 right-6 px-3 py-2'
              onClick={onClickRemove}
            >
              <ClearIcon fontSize='small' />
            </div>
          )}
          <div
            className='absolute top-3 right-0 px-3 py-2'
            onClick={onClickOpenModal}
          >
            <DateRangeIcon color='secondary' fontSize='small' />
          </div>
        </div>
        {error && (
          <span className='font-bold text-red-600 italic ml-5'>{error}</span>
        )}
        {hasErrorMaxDate && (
          <span className='font-bold text-red-600 italic ml-5'>
            {t('components.datePicker.errorMaxDate')}{' '}
            {getDateFormatted(maxDate!)}
          </span>
        )}
        {hasErrorMinDate && (
          <span className='font-bold text-red-600 italic ml-5'>
            {t('components.datePicker.errorMinDate')}{' '}
            {getDateFormatted(minDate!)}
          </span>
        )}
      </div>
    </Fragment>
  );
};
export default DatePicker;
