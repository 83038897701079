import React, {
  createRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { sortBy } from 'lodash';
import { getConfigurationMetaData } from '../../api/metaData';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import { IValidatorField } from '../../hooks/validation/useValidator/types';
import useValidator from '../../hooks/validation/useValidator/useValidator';
import DateTextBoxMemo from './dateTextBoxMemo';
import TextFieldMemo from './textFieldMemo';
import AutocompleteCustomTable from './autocompleteCustomTableMemo';
import AutocompleteMultipleCustomTableMemo from './autocompleteMultipleCustomTableMemo';
import FileUploadMemo from './fileUploadMemo';
import DataGridMemo from './dataGridMemo';
import {
  RelationListWithChild,
  DocumentMetaDataState,
  TypeControl,
  TypeControlData,
  HandleDocumentMetaDataChild,
} from './types';
import { regexEmail } from '../../constants/Regex';
import { useTranslation } from 'react-i18next';
import { buildRelationListWithChild, getVisibilityControls } from './utility';
import { BpmProcessInformationDto } from '../../api/backOffice/dto';
import clsx from 'clsx';
import DividerWithText from '../../components/DividerWithText';
import { getDocumentTypeSections } from '../../api/documentTypeSections';
import { DocumentTypeSectionDto } from '../../dto/DocumentTypeSectionDto';

interface DocumentMetaDataProps {
  documentTypeId: number;
  validateData: number;
  onValidationMetaData?: (
    hasError: boolean,
    data: DocumentMetaDataState,
    validations: IValidatorField[]
    ) => void;
    processBpmInformation?: BpmProcessInformationDto;
}

const DocumentMetaData = ({
  documentTypeId,
  validateData,
  onValidationMetaData,
  processBpmInformation,
}: DocumentMetaDataProps) => {
  const { i18n, t } = useTranslation();
  const [elRefs, setElRefs] = React.useState<any>([]);
  const [controlsVisibles, setControlsVisibles] = React.useState<string[]>([]);
  const [stateLocal, setState] = useState<DocumentMetaDataState>({});
  const [validations, setValidations] = useState<IValidatorField[]>([]);
  const [stateError, startValidations] = useValidator<DocumentMetaDataState>();
  const [relationListWithChild, setRelationListWithChild] = useState<
    RelationListWithChild[]
  >([]);
  const [metaData, setMetaData] = useState<
    MetaDataConfigurationDocumentaryTypeDto[]
  >([]);
  const [sections, setSections] = useState<DocumentTypeSectionDto[]>([]);
  const [hasSections, setHasSections] = useState<boolean>(false);

  const buildInitialState = (
    metadataInitial: MetaDataConfigurationDocumentaryTypeDto[]
  ) => {
    let result = {};
    metadataInitial.forEach((p) => {
      if (p.defaultValue) {
        result = {
          ...result,
          [`${p.id}`]: {
            value: p.defaultValue,
          },
        };
      }
    });
    return result;
  };

  useEffect(() => {
    const fetch = async () => {
      const result = await getConfigurationMetaData(documentTypeId);
      const dtSections = await getDocumentTypeSections(documentTypeId);
      const refControls = result.map((p) => createRef());
      const dependencies = result
        .filter((p) => p.dependentListInitial && p.fieldDependentList)
        .map(
          (p) =>
            ({
              originInitial: p.initial,
              destinationInitial: p.dependentListInitial,
              fieldDependentDestination: p.fieldDependentList,
              valueDependentDestination: undefined,
            } as RelationListWithChild)
        );
      setState(buildInitialState(result));
      setRelationListWithChild(dependencies);
      setElRefs(refControls);
      setMetaData(sortBy(result, (p) => p.order));
      setControlsVisibles(result.filter(p => p.isVisible).map(p => p.initial));
      setSections(dtSections);
      setHasSections(!!dtSections.length);
    };
    fetch();
  }, [documentTypeId, i18n.language]);

  const getAllDataControls = useCallback(():
    | DocumentMetaDataState
    | undefined => {
    if (validateData === 0) return undefined;
    let formStateClone = { ...stateLocal };

    for (let index = 0; index < elRefs.length; index++) {
      const ref = elRefs[index] as any;
      if (!ref || !ref.current) continue;

      const dataRef = (ref.current as HandleDocumentMetaDataChild).getData();
      formStateClone = {
        ...formStateClone,
        [`${dataRef.id}`]: {
          type: dataRef.typeElement,
          value: dataRef.value,
          text: dataRef.text,
          label: dataRef.label,
          initial: dataRef.initial,
          dicSec: dataRef.dicSec,
          attributeFormId: dataRef.attributeFormId,
        },
      };
    }
    return formStateClone;
  }, [elRefs, stateLocal, validateData]);

  useEffect(() => {
    if (validateData === 0) return;
    const dataControls = getAllDataControls();
    if (!dataControls) return;
    const isValide = startValidations(dataControls, validations);
    setState(dataControls);
    if (onValidationMetaData) {
      onValidationMetaData(!isValide, dataControls, validations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateData]);

  const buildRuleValidations = useCallback(() => {
    const rules = metaData
      .filter((p) => p.isRequired && controlsVisibles.includes(p.initial))
      .filter((p) => (hasSections ? p.sectionId : true))
      .map((p) => {
        const validation: IValidatorField = {
          field: `${p.id}.value`,
          validations: {
            required: true,
          },
        };
        if (
          p.controlType === TypeControl.text &&
          p.dataType === TypeControlData.email
        ) {
          validation.validations.pattern = {
            value: regexEmail,
            message: t('message.validation.invalidEmail'),
          };
        }
        return validation;
      });
    setValidations(rules);
  }, [metaData, t, controlsVisibles, hasSections]);

  useEffect(() => {
    const buildInfo = () => {
      if (metaData.length === 0) return;
      buildRuleValidations();
    };
    buildInfo();
  }, [buildRuleValidations, metaData, controlsVisibles]);

  const isVisible = useCallback((control: MetaDataConfigurationDocumentaryTypeDto) => {
    if(hasSections && !control.sectionId) return false;
    if(control.isVisible) return true;
    if(controlsVisibles.some( p=> p === control.initial)) return true;
    return false;
  }, [hasSections, controlsVisibles]);

  const isVisibleSection = useCallback((sectionId?: number) => {
    return metaData.some(x => x.sectionId === sectionId && controlsVisibles.includes(x.initial));
  }, [metaData, controlsVisibles]);

  const handleOnChangeAutocomplete = useCallback(
    (initial: string, valueSelected: string | undefined) => {
      const relation = buildRelationListWithChild(relationListWithChild, initial ,valueSelected);
      setRelationListWithChild(relation);
      const visibilty = getVisibilityControls(controlsVisibles, metaData, initial, valueSelected);
      setControlsVisibles(visibilty);
    },
    [relationListWithChild, controlsVisibles, metaData]
  );

  const buildDateTextBox = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => (
      <div
        key={`DateTextBoxMemo_${control.id}`}
        className={isVisible(control) ? 'visible' : 'hidden'}
      >
        <DateTextBoxMemo
          ref={elRefs[index]}
          metaData={control}
          value={
            stateLocal[control.id] ? stateLocal[control.id].value : undefined
          }
          error={stateError[control.id]}
        />
      </div>
    ),
    [elRefs, stateLocal, stateError, isVisible]
  );
  const buildTextBox = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => (
      <div
        key={`TextFieldMemo_${control.id}`}
        className={isVisible(control) ? 'visible' : 'hidden'}
      >
        <TextFieldMemo
          metaData={control}
          ref={elRefs[index]}
          value={
            stateLocal[control.id]
              ? stateLocal[control.id].value.toString()
              : undefined
          }
          error={stateError[control.id]}
        />
      </div>
    ),
    [elRefs, stateLocal, stateError, isVisible]
  );

  const buildAutocomplete = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => {
      const dependency = relationListWithChild.find(
        (p) => p.destinationInitial === control.initial
      );
      const isParentOfDependency =
        relationListWithChild.find(
          (p) => p.originInitial === control.initial
        ) != null;

      return (
        <div
          key={`AutocompleteCustomTableMemo_${control.id}`}
          className={isVisible(control) ? 'visible' : 'hidden'}
        >
          <AutocompleteCustomTable
            onChangeValue={handleOnChangeAutocomplete}
            isParentdependent={isParentOfDependency}
            isdependent={dependency != null}
            valueDependent={dependency?.valueDependentDestination}
            fieldDepedent={dependency?.fieldDependentDestination}
            ref={elRefs[index]}
            metaData={control}
            value={
              stateLocal[control.id]
                ? stateLocal[control.id].value.toString()
                : undefined
            }
            error={stateError[control.id]}
            processBpmInformation={processBpmInformation}
          />
        </div>
      );
    },
    [
      relationListWithChild,
      handleOnChangeAutocomplete,
      elRefs,
      stateLocal,
      stateError,
      isVisible,
      processBpmInformation,
    ]
  );

  const buildFileUpload = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => (
        <div
          key={`buildFileUpload${control.id}`}
          className={isVisible(control) ? 'visible' : 'hidden'}
        >
          <FileUploadMemo
            metaData={control}
            ref={elRefs[index]}
            error={stateError[control.id]}
            documentTypeId={documentTypeId}
            value={
              stateLocal[control.id] ? stateLocal[control.id].value : undefined
            }
          />
        </div>
    ),
    [elRefs, stateError, stateLocal, isVisible, documentTypeId]
  );

  const buildMultiSelect = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => (
      <div
        key={`AutocompleteCustomTableMemo_${control.id}`}
        className={isVisible(control) ? 'visible' : 'hidden'}
      >
        <AutocompleteMultipleCustomTableMemo
          isOutlined
          ref={elRefs[index]}
          metaData={control}
          processBpmInformation={processBpmInformation}
          value={
            stateLocal[control.id]
              ? (stateLocal[control.id].value as string[])
              : undefined
          }
          error={stateError[control.id]}
        />
      </div>
    ),
    [elRefs, stateLocal, stateError, isVisible, processBpmInformation]
  );

  const buildDataGrid = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => (
      <div
        key={`DataGridMemo_${control.id}`}
        className={isVisible(control) ? 'visible' : 'hidden'}
      >
        <DataGridMemo
          ref={elRefs[index]}
          metaData={control}
          value={
            stateLocal[control.id] ? stateLocal[control.id].value : undefined
          }
          error={stateError[control.id]}
        />
      </div>
    ),
    [elRefs, stateLocal, stateError, isVisible]
  );

  const buildControls = useCallback(
    (sectionId: any = null) =>
      metaData.filter(x => x.sectionId === sectionId).map(control => {
        const index = metaData.indexOf(control);
        switch (control.controlType) {
          case TypeControl.text:
            if (control.dataType === TypeControlData.date) {
              return buildDateTextBox(control, index);
            }
            return buildTextBox(control, index);
          case TypeControl.file:
            return buildFileUpload(control, index);
          case TypeControl.multipleSelect:
            return buildMultiSelect(control, index);
          case TypeControl.list:
            return buildAutocomplete(control, index);
          case TypeControl.dataGrid:
            return <div key={index} className='col-span-2'>{ buildDataGrid(control, index) }</div>;
          default:
            return buildTextBox(control, index);
        }
      }),
    [
      metaData,
      buildTextBox,
      buildAutocomplete,
      buildDateTextBox,
      buildFileUpload,
      buildMultiSelect,
      buildDataGrid
    ]
  );

  const buildSections = useCallback(() => (
    sections.map(({ id, name }) => (
      <div key={id} className={clsx('col-span-2', isVisibleSection(id) ? 'visible' : 'hidden')}>
        <DividerWithText text={name} />
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 pb-4'>
          {buildControls(id)}
        </div>
      </div>
    ))
  ), [sections, buildControls, isVisibleSection]);

  return (
    <Fragment>
      { hasSections && buildSections() }
      { buildControls() }
    </Fragment>
  );
};
export default DocumentMetaData;
