import React from 'react';
import { TextField } from '../../../components';
import { IInformationUserVuvProps } from './types';
import { useTranslation } from 'react-i18next';
import { ID_NATURAL_PERSON } from '../constants';

const LoginInformation = ({
  formState,
  formStateError,
  onChange,
  isOutlined,
  disabledPrincipalData
}: IInformationUserVuvProps) => {
  const { t } = useTranslation();
  const isNaturalPerson = formState.personType.id === ID_NATURAL_PERSON;
  const textTranslate = isNaturalPerson ? 'pages.user.common.naturalPerson' : 'pages.user.common.legalPerson';
  return (
    <div className='grid grid-cols-1 gap-4 md:grid-cols-2 '>
      <div className='col-span-1'>
        <TextField
          isRequired
          isOutlined={isOutlined}
          label={t(`${textTranslate}.email`)}
          value={formState.email}
          error={formStateError?.email}
          onChange={onChange('email')}
          textHelp={t('pages.user.common.helpText.email')}
        />
      </div>
      <div className='col-span-1'>
        <TextField
          isRequired
          isOutlined={isOutlined}
          disabled={disabledPrincipalData}
          label={t(`${textTranslate}.nickName`)}
          value={formState.nickName}
          error={formStateError?.nickName}
          onChange={onChange('nickName')}
          textHelp={t('pages.user.common.helpText.userName')}
        />
      </div>
    </div>
  );
};
export default LoginInformation;
