import { API_BACK_OFFICE } from '../constants';
import { getData } from '../_base';
import { BpmProcessInformationDto, FormAttributeMetadataLanguageDto } from './dto';
const base = `${API_BACK_OFFICE}/v1`;

const url = {
  GetInformationProcess: `${base}/Bpm/GetInformationProcess`,
  GetMetadataAdmin: `${base}/Form/GetMetadataAdminByAttributeIdentification`,
};

export const getProcessInformation = async (processGuidId: string): Promise<BpmProcessInformationDto | undefined> => {
  const _url = `${url.GetInformationProcess}/${processGuidId}`;
  const result = await getData<BpmProcessInformationDto>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};


export const getMetadataAdminByMetadataId = async (
  formId: number,
  attributeId: string,
  metadataId: string
): Promise<FormAttributeMetadataLanguageDto | undefined> => {
  const _url = `${url.GetMetadataAdmin}/${formId}/${attributeId}/${metadataId}`;
  const result = await getData<FormAttributeMetadataLanguageDto>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};
