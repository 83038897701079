import clsx from 'clsx';
import Required from './Required';
import TooltipHelp from './TooltipHelp';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel, makeStyles } from '@material-ui/core';
import { MetaDataTableConfigDto } from '../dto/MetadataTableConfigDto';
import DataGrid, { Column, Editing, Paging } from 'devextreme-react/data-grid';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    '& .dx-datagrid > .dx-datagrid-header-panel': { order: 3 }
  },
  text: {
    width: '100%',
    color: theme.palette.text.primary,
  },
}));

interface ICustomDataGridProps {
  value?: any[];
  label?: string;
  error?: string;
  textHelp?: string;
  disabled?: boolean;
  isOutlined?: boolean;
  isRequired?: boolean;
  metadataConfigTable: MetaDataTableConfigDto[];
  onChange?: (value: any[]) => void;
}

const CustomDataGrid = (
  {
    label,
    error,
    textHelp,
    onChange,
    isRequired,
    metadataConfigTable
  } : ICustomDataGridProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const hasError = !isEmpty(error);
  const [items,] = useState<any[]>([]);

  const handleChange = () => {
    if (!onChange) return;
    const itemsClone = [ ...items ];
    itemsClone.forEach(x => delete x['__KEY__']);
    onChange(itemsClone.map(x => Object.values(x)));
  }

  const buildColumns = useCallback(()=> {
    return metadataConfigTable.map(c => (
      <Column
        key={c.id}
        dataField={c.dataField}
        caption={c.columnName}
        validationRules={[{type: 'required', message: t('message.validation.fieldRequired')}]}
      />
    ));
  }, [t, metadataConfigTable]);

  return (
    <>
      {label && (
        <div className='h-10'>
          <InputLabel className={classes.text}>
            {label} {isRequired && <Required />}
            {textHelp && <TooltipHelp text={textHelp} />}
          </InputLabel>
        </div>
      )}
      {hasError && (
        <span className='font-bold text-red-600 italic ml-5'>{error}</span>
      )}      
      <DataGrid
        dataSource={items}
        showBorders={true}
        allowColumnReordering={true}
        onRowInserted={handleChange}
        onRowUpdated={handleChange}
        onRowRemoved={handleChange}
        className={clsx(classes.dataGrid)}
      >
        <Paging enabled={true} pageSize={5} />
        <Editing
          mode="row"
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}
          confirmDelete={false}
        />
        { buildColumns() }
      </DataGrid>
    </>
  )
}

export default CustomDataGrid;
