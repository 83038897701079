import React, { memo, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getExtensionsByDocumentType } from '../../api/extensions';
import { FileUpload } from '../../components';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import {
  HandleDocumentMetaDataChild,
  ResultDocumentMetaDataChild,
  TypeControl,
} from './types';

interface FileUploadMemoProps {
  ref: any;
  error?: string;
  value?: File[];
  documentTypeId?: number;
  metaData: MetaDataConfigurationDocumentaryTypeDto;
}

const defaultAcceptExtFiles = '.jpg,.jpeg,.png,.pdf,.mp3,.mp4,.ppt,.pptx,.xls,.xlsx,.doc,.docx';

const FileUploadMemo = memo(
  forwardRef(
    (
      props: FileUploadMemoProps,
      ref: React.Ref<HandleDocumentMetaDataChild | undefined>
    ) => {
      const { t } = useTranslation();
      const {
        error,
        documentTypeId,
        metaData: { isRequired, id, toolTip, label, initial, dicSec, attributeFormId },
      } = props;
      const [files, setFiles] = useState<File[]>([]);
      const [localError, setLocalError] = useState<string>('');
      const [acceptExtensionFiles, setAcceptExtensionFiles] = useState<string>(defaultAcceptExtFiles);

      useEffect(() => {
        if (!documentTypeId) return;
        const fetch = async () => {
          const result = await getExtensionsByDocumentType(documentTypeId);
          if (result && result?.extensions){
            setAcceptExtensionFiles(result.extensions);
          }
        };
        fetch();
      }, [documentTypeId, setAcceptExtensionFiles]);

      useImperativeHandle(ref, () => ({
        getData() {
          return {
            id,
            value: files,
            text: '',
            typeElement: TypeControl.file,
            label: label,
            initial: initial,
            dicSec: dicSec,
            attributeFormId,
          } as ResultDocumentMetaDataChild;
        },
      }));

      const handleOnErrorMaxFiles = () => {
        setLocalError(t('message.validation.filesMaximun', { value: '10' }));
      };

      const handleOnErrorSize = () => {
        setLocalError(
          t('message.validation.filesSizeMaximun', { value: '50' })
        );
      };

      const handleOnErrorExtensionNotAccepted = (ext: string) => {
        setLocalError(
          t('message.validation.fileExtensionNotAccepted', { value: ext })
        );
      }

      const handleOnLoadFiles = (
        files: File[],
        hasErrorValidation: boolean
      ) => {
        setFiles(files);
        if (!hasErrorValidation) {
          setLocalError('');
        }
      };

      return (
        <FileUpload
          files={files}
          maxFiles={10}
          maxSizeTotalMB={50}
          isMultiple
          label={label}
          isRequired={isRequired}
          textHelp={toolTip}
          acceptExtesion={acceptExtensionFiles}
          error={localError || error}
          onErrorSize={handleOnErrorSize}
          onErrorMaxFiles={handleOnErrorMaxFiles}
          onErrorExtensionNotAccepted={handleOnErrorExtensionNotAccepted}
          onLoadFiles={handleOnLoadFiles}
        />
      );
    }
  )
);
export default FileUploadMemo;
