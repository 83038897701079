import React, { Fragment, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { SearchResultDto } from '../../api/reports/types';
import { uniqBy, orderBy, uniqueId } from 'lodash';
import { Badge, Divider, makeStyles, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
  },
  backgroundPrincipal: {
    backgroundColor: theme.palette.primary.main,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}));

interface IFiltreProps {
  information: SearchResultDto[];
  onSelectFilter: (module: string) => void;
}

const Filter = ({ information, onSelectFilter }: IFiltreProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedModule, setSelectedModule] = React.useState<string>();

  useEffect(() => {
    if (information) setSelectedModule(information[0]?.module);
  }, [information, setSelectedModule]);

  const handleListItemClick = (module: string) => {
    setSelectedModule(module);
    onSelectFilter(module);
  };

  const getTranslatedModules = () => {
    const modulesTranlated = uniqBy(information, (p) => p.module).map((p) => {
      return {
        moduleTranlate: t(`pages.search.modules.${p.module}`),
        module: p.module,
      };
    });
    return orderBy(modulesTranlated, (p) => p.moduleTranlate);
  };

  return (
    <Paper>
      <div className='rounded-lg shadow-xl'>
        <div
          className={clsx(
            classes.backgroundPrincipal,
            classes.textSecondary,
            'text-center text-xl w-full h-12 p-2 font-semibold italic font-title'
          )}
        >
          {t('pages.search.modulesLabel')}
        </div>
        <div className='p-5'>
          <List component='nav' aria-label='search'>
            {getTranslatedModules().map((value) => {
              return (
                <Fragment key={uniqueId()}>
                  <ListItem
                    button
                    selected={selectedModule === value.module}
                    onClick={() => handleListItemClick(value.module)}
                    classes={{ selected: classes.active }}
                  >
                    <ListItemText primary={value.moduleTranlate} />
                    <Badge badgeContent={information.filter(p => p.module === value.module).length} color='primary'/>
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            })}
          </List>
        </div>
      </div>
    </Paper>
  );
};
export default Filter;
