import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicModal } from '../../../components';

const RequestAppealForReversalAction = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const onClickAccept = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        color='primary'
        variant='contained'
      >
        {t('pages.pqrs.actions.requestAppealForReversal.title')}
      </Button>
      <BasicModal
        open={open}
        title={t('pages.pqrs.actions.requestAppealForReversal.title')}
        showAcceptButton
        showCancelButton
        titleButtonCancel={t('pages.pqrs.actions.requestAppealForReversal.btnNon')}
        titleButtonAccept={t('pages.pqrs.actions.requestAppealForReversal.btnYes')}
        onClickCancel={() => {
          setOpen(false);
        }}
        onClickAccept={onClickAccept}
      >
        <div className='text-2xl italic'>
          {t('pages.pqrs.actions.requestAppealForReversal.message')}
        </div>
      </BasicModal>
    </div>
  );
};
export default RequestAppealForReversalAction;
