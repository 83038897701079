import { makeStyles } from '@material-ui/core';
import { ContextFooter } from '../../../dto/ContextAppDto';
import TitleFooter from '../common/TitleFooter';
import TextFooter from '../common/TextFooter';
import ImageFooter from '../common/ImageFooter';
import SocialNetworkIcon from '../common/SocialNetworkIcon';
import clsx from 'clsx';

interface FooterProps {
  footer: ContextFooter;
}

const Footer = ({ footer }: FooterProps) => {
  const useStyles = makeStyles({
    divider: {
      '& > div': { borderLeftColor: `${footer.textColor}` }
    }
  });
  const classes = useStyles();

  return (
    <div className='container mx-auto'>
      <div className='sm:flex py-10'>
        <div className={clsx('mt-8 sm:mt-0 sm:w-full grid grid-cols-1 md:grid-cols-4 justify-between gap-y-4 md:divide-x-2', classes.divider)} >
          <div>
              <div className='flex flex-col items-center md:flex-row md:justify-center'>
                {footer.image1 && (
                  <ImageFooter
                    src={footer.image1}
                    link={footer.txtLinkImg1?.text}
                  ></ImageFooter>
                )}
                {footer.image2 && (
                  <ImageFooter
                    src={footer.image2}
                    link={footer.txtLinkImg2?.text}
                  ></ImageFooter>
                )}
              </div>
          </div>
          <div className='col-span-2 px-5'>
            <div className='w-full flex flex-col space-x-3 space-y-1'>
              <TitleFooter text={footer?.text1?.text} textColor={footer?.textColor} />
              <TextFooter footer={footer} text={footer?.text2} />
              <TextFooter footer={footer} text={footer?.text3} />
              <TextFooter footer={footer} text={footer?.text4} />
              <TextFooter footer={footer} text={footer?.text5} />
              <TextFooter footer={footer} text={footer?.text6} />
              <TextFooter footer={footer} text={footer?.text7} />
              <TextFooter footer={footer} text={footer?.text8} />
              <TextFooter footer={footer} text={footer?.text9} />
            </div>
            <div className='mt-2 w-full space-x-2'>
                {
                  footer.socialNetworks?.map(socialNetwork => (
                    <SocialNetworkIcon
                      key={socialNetwork.name}
                      socialNetwork={socialNetwork}
                      textColor={footer.textColor}
                      footerType={footer.type}
                    />
                  ))
                }
              </div>
          </div>
          <div className='px-5'>
            <div className='flex flex-col mt-4 md:mt-0 mb-2 space-y-2'>
              <TitleFooter text={footer?.text10?.text} textColor={footer?.textColor} />
              <TextFooter footer={footer} text={footer?.text11} />
              <TextFooter footer={footer} text={footer?.text12} />
              <TextFooter footer={footer} text={footer?.text13} />
              <TextFooter footer={footer} text={footer?.text14} />
              <TextFooter footer={footer} text={footer?.text15} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
