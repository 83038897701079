import React, { Fragment, useState } from 'react';
import {
  Button,
  useMediaQuery,
  useTheme,
  Paper,
  Container,
} from '@material-ui/core';
import { Process, QuickAccess, SearchPQRSD } from '../../containers';
import { useTranslation } from 'react-i18next';
import Header from './header';

interface IPagePrincipalProps {
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
  withContainer?: boolean;
}
const PagePrincipal = ({
  title,
  subTitle,
  children,
  withContainer,
}: IPagePrincipalProps) => {
  const [openDialogProcess, setOpenDialogProcess] = useState<boolean>(false);
  const [openDialogQuickAccess, setOpenDialogQuickAccess] =
    useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const buildBody = (nodeChildren: any) => {
    if (withContainer)
      return (
        <Container component={Paper}>
          <Header title={title} subTitle={subTitle} />
          {nodeChildren}
        </Container>
      );
    else
      return (
        <Fragment>
          <Header title={title} subTitle={subTitle} /> {nodeChildren}
        </Fragment>
      );
  };

  return (
    <Fragment>
      <div className='h-full'>
        <div className='flex ml-1 mr-1 lg:gap-4'>
          <div className='space-y-3 w-full'>
            <div className='flex justify-end space-x-2 lg:h-0 lg:invisible'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setOpenDialogProcess(true)}
              >
                {t('components.process.title')}
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setOpenDialogQuickAccess(true)}
              >
                {t('components.quickAccess.title')}
              </Button>
            </div>
            {buildBody(children)}
          </div>
          <div className='flex flex-col mt-2'>
            <div className='w-0 lg:w-full'>
              <div className='mt-2 shadow-2xl'>
                <QuickAccess
                  showAsDialgo={isMobile}
                  isDialogOpened={openDialogQuickAccess}
                  onCloseDialog={() => setOpenDialogQuickAccess(false)}
                />
              </div>
              <div>
                <div className='mt-2 shadow-2xl'>
                  <Process
                    showAsDialgo={isMobile}
                    isDialogOpened={openDialogProcess}
                    onCloseDialog={() => setOpenDialogProcess(false)}
                  />
                </div>
                {!isMobile && (
                  <div className='mt-2 shadow-2xl'>
                    <Paper>
                      <SearchPQRSD />
                    </Paper>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Fragment>
  );
};
export default PagePrincipal;
