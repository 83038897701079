import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {
  AutocompleteStyled,
  FiltersPanel,
  RangeDates,
  TextField,
} from '../../../components';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useTranslation } from 'react-i18next';
import { Filtres } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
    backgroundPrincipal: {
      backgroundColor: theme.palette.primary.main,
    },
    textSecondary: {
      color: theme.palette.text.secondary,
    },
  })
);

interface IFiltreProps {
  onFiltre: (filtres: Filtres) => void;
}

const initialState: Filtres = {
  withAnswer: false,
  withAttachment: false,
  year: undefined,
  dateInitial: undefined,
  dateFinal: undefined,
  documentNumber: '',
};

const Filtre = ({ onFiltre }: IFiltreProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [filtres, setFiltres] = useState<Filtres>(initialState);

  const getYears = (): string[] => {
    const years: string[] = [];
    for (let index = new Date().getFullYear(); index > 2000; index--) {
      years.push(`${index}`);
    }
    return years;
  };

  const reset = () => {
    onFiltre(initialState);
    setFiltres(initialState);
  };
  const onChangeYear = (e: any, value: any | null) =>
    updateStateAndCallFiltre({ year: +value });
  const onChangeDate = (startDate?: Date, endDate?: Date) =>
    updateStateAndCallFiltre({ dateInitial: startDate, dateFinal: endDate });
  const onChangeDocumentNumber = (value: string) =>
    updateStateAndCallFiltre({ documentNumber: value });
  const onChangeWithAnswer = () =>
    updateStateAndCallFiltre({ withAnswer: !filtres.withAnswer });
  const onChangeWithAttachment = () =>
    updateStateAndCallFiltre({ withAttachment: !filtres.withAttachment });

  const updateStateAndCallFiltre = (value: {}) => {
    const newFiltres = { ...filtres, ...value };
    onFiltre(newFiltres);
    setFiltres(newFiltres);
  };

  useEffect(() => {}, [filtres, onFiltre]);

  return (
    <FiltersPanel>
      <List className={clsx(classes.root, '')}>
        <ListItem button onClick={reset}>
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary={t('pages.porfolioMyDocuments.filtre.reset')} />
        </ListItem>
        <ListItem role={undefined} dense button onClick={onChangeWithAnswer}>
          <ListItemIcon>
            <Checkbox
              checked={filtres.withAnswer}
              style={{
                color: 'gray',
              }}
              inputProps={{ 'aria-labelledby': 'labelAnswers' }}
            />
          </ListItemIcon>
          <ListItemText
            id='labelAnswers'
            primary={t('pages.porfolioMyDocuments.filtre.withAnswers')}
          />
        </ListItem>
        <ListItem
          role={undefined}
          dense
          button
          onClick={onChangeWithAttachment}
        >
          <ListItemIcon>
            <Checkbox
              checked={filtres.withAttachment}
              style={{
                color: 'gray',
              }}
              // inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText
            primary={t('pages.porfolioMyDocuments.filtre.withAttachment')}
          />
        </ListItem>
      </List>
      <AutocompleteStyled
        isOutlined
        options={getYears()}
        label={t('pages.porfolioMyDocuments.filtre.year')}
        onChange={onChangeYear}
        getOptionLabel={(option: any) => option}
        renderInput={() => <></>}
        value={filtres.year ? `${filtres.year}` : null}
      />
      <br />
      <RangeDates
        label={t('pages.porfolioMyDocuments.filtre.labelDate')}
        labelFrom={t('pages.porfolioMyDocuments.filtre.labelDateFrom')}
        labelTo={t('pages.porfolioMyDocuments.filtre.labelDateTo')}
        onChangeDate={onChangeDate}
        maxDate={new Date()}
        endDate={filtres.dateFinal}
        startDate={filtres.dateInitial}
      />
      <br />
      <div>
        <TextField
          maxLength={50}
          isOutlined
          label={t('pages.normativityOffice.labelDescription')}
          onChange={onChangeDocumentNumber}
          value={filtres.documentNumber}
        />
      </div>
    </FiltersPanel>
  );
};
export default Filtre;
