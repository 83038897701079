import { makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import clsx from 'clsx';

interface IDescriptionsProps {
  description1: string;
  description2: string;
}

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.text.primary,
  },
}));


const Descriptions = ({ description1, description2 }: IDescriptionsProps) => {
  const classes = useStyles();

  return (
    <Fragment>
      <pre className={clsx(classes.textPrimary,'pb-5 whitespace-pre-line font-body text-justify')}>{description1}</pre>
      <pre className={clsx(classes.textPrimary,'whitespace-pre-line  font-body text-justify')}>{description2}</pre>
    </Fragment>
  );
};
export default Descriptions;
