import React from 'react';
import { Container, Paper, Typography, Icon } from '@material-ui/core';
import { TechnicalRequirementsDto } from '../../dto/admin/types';
import { useTranslation } from 'react-i18next';

interface IPanelProps {
  data: TechnicalRequirementsDto;
}
const Panel = ({ data }: IPanelProps) => {
  const { t } = useTranslation();
  return (
    <div className='w-full'>
      <div className='w-full h-full rounded-xl'>
        <Container component={Paper} className='p-20'>
          <Typography variant='h4' color='primary'>
            {data.text1}
          </Typography>
          <div className='mt-10'>
            <div className='w-full md:w-60 float-left text-center'>
              <Icon color='primary' style={{ fontSize: '7rem' }}>
                {data.icon}
              </Icon>
            </div>
            <div className='w-full'>
              <pre className='pb-10 whitespace-pre-line font-sans text-justify text-base leading-7 font-body'>
                {data.text2}
              </pre>
              <pre className='pb-10 whitespace-pre-line font-sans text-justify text-base leading-7 font-body'>
                {data.text3}
              </pre>
              <pre className='pb-10 whitespace-pre-line font-sans text-justify text-base leading-7 font-body'>
                {data.text4}
              </pre>
              <br />
              <div className='text-center'>
                {data.link && (
                  <a
                    className='underline italic text-lg text-blue-400 font-body'
                    href={data.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('pages.technicalRequirements.moreInformation')}
                  </a>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default Panel;
