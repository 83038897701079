import React, { useState, useEffect, useMemo } from 'react';
import { PagePrincipal } from '../../containers';
import { useTranslation } from 'react-i18next';
import { getPoliciesByLangApi } from '../../api/politicies';
import { uniq } from 'lodash';
import { PolicyDto } from '../../dto/admin/types';
import Filtre from './filtres';
import Panel from './panel';

const Politics = () => {
  const { t, i18n } = useTranslation();
  const [politics, setPolitics] = useState<PolicyDto[]>([]);
  const [politicsFiltred, setPoliticsFiltred] = useState<PolicyDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
      const result = await getPoliciesByLangApi();
      setPolitics(result);
      setPoliticsFiltred(result);
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);



  const onSelectType = (typesFiltred: string[]) => {
    if (typesFiltred.length === 0) setPoliticsFiltred(politics);
    else
      setPoliticsFiltred(politics.filter((p) => typesFiltred.includes(p.type)));
  };

  const getTypes = useMemo(() => uniq(politics.map((p) => p.type)), [politics]);

  return (
    <PagePrincipal
      title={t('pages.politics.title')}
      subTitle={t('pages.politics.subTitle')}
    >
      <div className='flex flex-col space-y-5 md:flex-row p-0 xl:pl-12  xl:pr-40 space-x-5 lg:space-y-0'>
        <div className='w-full h-full lg:w-1/3'>
          <Filtre types={getTypes} onSelectType={onSelectType} />
        </div>
        <div className='w-full h-full'>
          <Panel politics={politicsFiltred} />
        </div>
      </div>
    </PagePrincipal>
  );
};

export default Politics;
