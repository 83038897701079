import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { getUserTableInformationApi } from '../../api/AdmInquiries';
import { AutocompleteStyled } from '../../components';
import { InformationCustomTableDto } from '../../dto/admin/types';
import { ContactMetaDataDto } from '../../dto/ContactDto';
import { HandleContractMetaDataChild, ResultContractMetaDataChild, TypeControl } from './types';

interface IAutocompleteCustomTableProps {
  metaData: ContactMetaDataDto;
  error?: string;
  value?: string;
  isOutlined? : boolean;
  textHelp?: string;
}

const defaultValue: InformationCustomTableDto = {
  id: '',
  value: '',
};

const AutocompleteCustomTableMemo = memo(forwardRef(
  (
    {metaData, value, error, isOutlined, textHelp}: IAutocompleteCustomTableProps,
    ref: React.Ref<HandleContractMetaDataChild | undefined>
  ) => {
    const [options, setOptions] = useState<InformationCustomTableDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<string | undefined>(
      value
    );

    const handlerOnChangeAutocomplete = (e: any, value: any | null) => {
      const info = value as InformationCustomTableDto;
      if (info) setSelectedValue(info.id);
      else setSelectedValue(undefined);
    };

    useEffect(() => {
      const fetch = async () => {
        const { listeName, listeTexte, listeValue } = metaData;
        const result = await getUserTableInformationApi(listeName, listeValue, listeTexte);
        setOptions(result);
      };
      fetch();
    }, [metaData]);

    const getOptionSelected = (): InformationCustomTableDto => {
      if (selectedValue) {
        const selected = options.find((p) => p.id === selectedValue);
        return selected ? selected : defaultValue;
      }
      return defaultValue;
    };

    useImperativeHandle(ref, () => ({
      getData() {
        const option = getOptionSelected();
        return {
          id: metaData.id,
          value: option.id,
          text: option.value,
          typeElement: TypeControl.list,
          label: metaData.label
        } as ResultContractMetaDataChild;
      },
    }));

    return (
      <AutocompleteStyled
        isOutlined={isOutlined}
        error={error}
        isRequired={metaData.isRequired}
        options={options}
        label={metaData.label}
        value={getOptionSelected()}
        onChange={handlerOnChangeAutocomplete}
        getOptionLabel={(option: InformationCustomTableDto) => option.value}
        renderInput={() => <></>}
        textHelp={textHelp}
      />
    );
  }
));

export default AutocompleteCustomTableMemo;
