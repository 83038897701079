import React, { useEffect, useState } from 'react';
import { getCitiesApi } from '../../../api/AdmInquiries';
import { CityDto } from '../../../dto/admin/types';
import { useTranslation } from 'react-i18next';
import { AutocompleteStyled } from '../../../components';

interface ICityAutocompleteProps {
  idDepartment?: string;
  idSelected?: string;
  error?: string;
  onChange: (value: CityDto) => void;
  isOutlined? : boolean;
  textHelp?: string;
}

const defaultcity: CityDto = {
  id: '',
  name: '',
  departmentId: '',
};

const CityAutocomplete = ({
  idSelected,
  idDepartment,
  error,
  onChange,
  isOutlined,
  textHelp,
}: ICityAutocompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<CityDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
      if (idDepartment) {
        const result = await getCitiesApi(idDepartment!);
        setOptions(result);
      }
    };
    callApi();
  }, [idDepartment]);

  const getOptionSelected = (): CityDto => {
    if (idSelected) {
      const selected = options.find((p) => p.id === idSelected);
      return selected ? selected : defaultcity;
    }
    return defaultcity;
  };

  const handlerOnChange = (e: any, value: any | null) => {
    if (value) {
      onChange(value as CityDto);
    }
  };

  return (
    <AutocompleteStyled
      isOutlined={isOutlined}
      error={error}
      options={options}
      value={getOptionSelected()}
      label={t('components.cityAutocomplete.label')}
      onChange={handlerOnChange}
      getOptionLabel={(option: any) => option.name}
      renderInput={() => <></>}
      textHelp={textHelp}
    />
  );
};

export default CityAutocomplete;
