import { API_BASE } from '../constants';
import {
  postData,
  } from '../_base';
import { DigitalDocumentRequest, DigitalDocumentResponse } from './types';

const base = `${API_BASE}/v1/DigitalDocument`;

const url = {
  saveDigitalDocument: `${base}`,
};

export const saveDigitalDocument = async (
  request: DigitalDocumentRequest
): Promise<DigitalDocumentResponse | undefined> => {
  const _url = `${url.saveDigitalDocument}`;
  const result = await postData<DigitalDocumentRequest, DigitalDocumentResponse>(_url, request);
  if (result && !result.hasError && result.data) return result.data as DigitalDocumentResponse;
  else return undefined;
};
