import React from 'react';
import Section from '../common/section';
import Descriptions from '../common/description';
import Title from '../common/title';

interface IPanel1Props {
  title: string;
  tagLeft: string;
  tagRight: string;
  description: string;
  description2: string;
  img: string;
  url?: string;
}

const Panel1 = ({
  title,
  tagLeft,
  tagRight,
  description,
  description2,
  url,
}: IPanel1Props) => {
  return (
    <Section tagRight={tagRight} tagLeft={tagLeft}>
      <Title title={title} url={url}></Title>
      <Descriptions
        description1={description}
        description2={description2}
      />
    </Section>
  );
};
export default Panel1;
