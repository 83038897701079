import { format } from 'date-fns';

export const convertToDate = (date: string) => {
  return new Date(date);
};

export const convertDateToString = (date: Date) => {
  return date.toISOString();
};

export const convertDatetoDateFormatted = (date: Date) =>
  format(new Date(date), 'dd/MM/yyyy');

export const convertDatetoDatetimeFormatted = (date: Date) =>
  format(new Date(date), 'dd/MM/yyyy HH:mm');

