import { Fragment } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { openWindow } from '../utils';
import { ContextFooter, ContextTextLink } from '../../../dto/ContextAppDto';

interface ITextFooterProps {
  footer: ContextFooter;
  text: ContextTextLink | undefined;
}

const TextFooter = ({ footer, text }: ITextFooterProps) => {
  const history = useHistory();

  return text ? (
    text.link ? (
      <span
        style={{color: `${footer.textColor}`}}
        className={clsx('text-base underline italic cursor-pointer font-body', text.bold && 'font-bold')}
        onClick={() => {
          openWindow(history, text.link);
        }}
      >
        {text.text}
      </span>
    ) : (
      <span
        style={{color: `${footer.textColor}`}}
        className={clsx('text-base font-body', text.bold && 'font-bold')}
      >
        {text.text}
      </span>
    )
  ) : (
    <Fragment />
  );
};

export default TextFooter;
