import { INavigationItem } from './types';
import RouteNavigation from '../constants/RouteNavigation';
import Roles from '../constants/Roles';
import { EnumTypePQRS } from '../constants/PqrsType';

export const navigationPqrs: INavigationItem = {
  id: '2',
  keyTranslate: 'pqrs',
  allowAnonymous: true,
  icon: 'insert_comment',
  children: [
    {
      id: '2.1',
      keyTranslate: 'pqrsInformationAnonymous',
      url: `${RouteNavigation.pqrsInformation}/${EnumTypePQRS.Anonymous}`,
      allowAnonymous: true,
      hideOnAuthenticated: true,
    },
    {
      id: '2.2',
      keyTranslate: 'pqrsInformationNonReg',
      allowAnonymous: true,
      url: `${RouteNavigation.pqrsInformation}/${EnumTypePQRS.NotRegistered}`,
      hideOnAuthenticated: true,
    },
    {
      id: '2.3',
      keyTranslate: 'pqrsInformationpqrsReg',
      allowAnonymous: false,
      url: `${RouteNavigation.pqrsInformation}/${EnumTypePQRS.Registered}`,
      rolesAccesss: [Roles.GuestVUV],
    },
    {
      id: '2.4',
      keyTranslate: 'pqrsTracking',
      allowAnonymous: true,
      url: RouteNavigation.pqrsTracking,
      hideOnAuthenticated: true,
    },
    {
      id: '2.5',
      keyTranslate: 'pqrsInformationAnonymous',
      allowAnonymous: true,
      url: `${RouteNavigation.pqrsdAnonyme}`,
      hide: true,
    },
    {
      id: '2.6',
      keyTranslate: 'pqrsInformationNonReg',
      allowAnonymous: true,
      url: `${RouteNavigation.pqrsdNonReg}`,
      hide: true,
    },
    {
      id: '2.7',
      keyTranslate: 'pqrsInformationpqrsReg',
      allowAnonymous: false,
      url: `${RouteNavigation.pqrsReg}`,
      hide: true,
    },
    {
      id: '2.8',
      keyTranslate: 'pqrsTracking',
      allowAnonymous: true,
      hide: true,
      url: RouteNavigation.pqrsTrackingDocument,
    },
    {
      id: '2.9',
      keyTranslate: 'pqrsTracking',
      allowAnonymous: false,
      url: RouteNavigation.pqrsTrackingReg,
      rolesAccesss: [Roles.GuestVUV],
    },
  ],
};