import { API_ECM } from './../constants';
import { getData } from '../_base';
import { DocumentTypeDto } from '../../dto/admin/types';

const baseEcm = `${API_ECM}/v2/AdmInquiries/DocumentTypes`;

const url = {
  getDocumentTypeById: `${baseEcm}/Get`,
};

export const getDocumentTypeById = async (id: number): Promise<DocumentTypeDto | undefined> => {
  const _url = `${url.getDocumentTypeById}/${id}`;
  const result = await getData<DocumentTypeDto>(_url);
  if (result && !result.hasError && result.data ) return result.data;
  else return undefined;
};
