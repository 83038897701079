import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment } from 'react';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
  },
  colorPrimaryColor: {
    color: theme.palette.primary.main,
  },
  colorTextPrimary: {
    color: theme.palette.text.primary,
  },
  colorTitle: {
    color: theme.palette.info.main
  }
}));

interface IHeaderProps {
  title?: string;
  subTitle?: string;
}

const Header = ({ title, subTitle }: IHeaderProps) => {
  const classes = useStyles();
  return (
    <Fragment>
      {title && (
        <div className='container px-5 pt-4 mx-auto'>
          <div className='text-center mb-10'>
            <Typography variant='h4' color='primary' className={clsx(classes.colorTitle)}>
              {title}
            </Typography>
            <p
              className={clsx(
                classes.colorTextPrimary,
                'text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mt-4 font-body'
              )}
            >
              {subTitle}
            </p>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default Header;
