import { Icon, IconButton, makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';

export interface ICarouselImagesItem {
  id: string;
  name: string;
  urlImage: string;
}
interface ICarouselImagesProps {
  data: ICarouselImagesItem[];
  className?: string;
}
const useStyles = makeStyles((theme) => ({
  primaryColor: {
    color: theme.palette.text.secondary,
  },
  bgPrimaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const CarouselImages = ({ data, className }: ICarouselImagesProps) => {
  const classes = useStyles();
  const [play, setPlay] = useState<boolean>(false);
  const renderItems = () =>
    data.filter(p => p.urlImage).map((p) => {
      if (p.name) {
        return (
          <div
            key={p.id}
            className={'flex flex-col md:flex-row '}
            style={{ minHeight: '400px' }}
          >
            <div
              className={clsx(
                classes.bgPrimaryColor,
                'w-full md:w-4/12 flex items-center'
              )}
            >
              <span
                className={clsx(
                  'w-full max-h-96 text-5xl italic text-center p-4',
                  classes.primaryColor,
                  classes.bgPrimaryColor
                )}
              >
                {p.name}
              </span>
            </div>
            <img
              src={p.urlImage}
              alt={p.name}
              className='w-full md:w-8/12 sm:min-h-carousel max-h-96 bg-transparent'
            />
          </div>
        );
      }

      return (
        <img
          key={p.id}
          src={p.urlImage}
          alt={p.name}
          className='w-full sm:min-h-carousel max-h-96'
        />
      );
    });

  return (
    <Paper className='opacity-100'>
      <Carousel
        className={className}
        autoPlay={play}
        animation='slide'
        fullHeightHover
      >
        {renderItems()}
      </Carousel>
      <div className='w-full text-center'>
        <IconButton
          aria-label='delete'
          size='medium'
          onClick={() => setPlay(!play)}
        >
          {play ? (
            <Icon fontSize='large'>pause_circle_filled</Icon>
          ) : (
            <Icon fontSize='large'>play_circle</Icon>
          )}
        </IconButton>
      </div>
    </Paper>
  );
};
export default CarouselImages;
