import React, { useEffect, useState } from 'react';
import { getUserTypeApi } from '../../../api/AdmInquiries';
import { UserTypeDto } from '../../../dto/admin/types';
import { useTranslation } from 'react-i18next';
import AutocompleteStyled from '../../../components/AutoComplete';
import _ from 'lodash';

interface IUserTypeAutocompleteProps {
  idSelected?: number;
  isRequired?: boolean,
  error?: string;
  isOutlined?: boolean;
  disabled?:boolean;
  textHelp?: string;
  onChange: (value: UserTypeDto) => void;
}


const defaultcity: UserTypeDto = {
  id: 0,
  name: '',
};


const UserTypeAutocomplete = ({
  idSelected,
  onChange,
  isRequired,
  isOutlined,
  disabled,
  error,
  textHelp
}: IUserTypeAutocompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<UserTypeDto[]>([]);
  const [isSingleOption, setIsSingleOption] = useState<boolean>(false);

  useEffect(() => {
    const callApi = async () => {
      const result = await getUserTypeApi();
      setOptions(result);
      setIsSingleOption(result.length === 1);
    };
    callApi();
  }, []);

  useEffect(() => {
    if (isSingleOption) {
      onChange(_.first(options) as UserTypeDto);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSingleOption, options]);

  const getOptionSelected = (): UserTypeDto | undefined => {
    if (idSelected) {
      const selected = options.find((p) => p.id === idSelected);
      return selected ? selected : defaultcity;
    }
    if (isSingleOption) return _.first(options);
    return defaultcity;
  };

  const handlerOnChange = (e: any, value: any | null) => {
    if (value) {
      onChange(value as UserTypeDto);
    }
  };

  return (
    <AutocompleteStyled
      disabled={disabled || isSingleOption}
      isOutlined={isOutlined}
      error={error}
      isRequired={isRequired}
      options={options}
      value={getOptionSelected()}
      onChange={handlerOnChange}
      label={t('components.UserTypeAutocomplete.label')}
      getOptionLabel={(option: any) => option.name}
      renderInput={() => <></>}
      textHelp={textHelp}
    />
  );
};

export default UserTypeAutocomplete;
