import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Dialog,
  DialogContent,
  Divider,
  Icon,
  Typography,
} from '@material-ui/core';
import { ProcessDto } from '../../dto/Process';
import { getProcessByLangApi } from '../../api/process';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RouteNavigation from '../../constants/RouteNavigation';
import { KeyCodes } from '../../constants/KeyCode';

const useStyles = makeStyles((theme) => ({
  backgroundPrincipal: {
    backgroundColor: theme.palette.primary.main,
  },
  backgroundPaper: {
    backgroundColor: theme.palette.background.paper,
  },
  textSecondary: {
    color: theme.palette.text.secondary
  },
  noPadding: {
    padding: 0
  }
}));

interface IProcessProps {
  showAsDialgo?: boolean;
  isDialogOpened?: boolean;
  onCloseDialog?: () => void;
}

const Process = ({
  showAsDialgo,
  isDialogOpened,
  onCloseDialog,
}: IProcessProps) => {
  const classes = useStyles();
  const [processList, setProcessList] = useState<ProcessDto[]>([]);
  const currentLanguage = i18n.language;
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const process = await getProcessByLangApi();
      setProcessList(process);
    };
    fetch();
  }, [currentLanguage]);

  const onClickProcess = (id: number) => {
    history.push(`/${RouteNavigation.ProcessDetail}/${id}`);
  }

  const onAllProcess = () => {
    history.push(`/${RouteNavigation.Process}`);
  }

  const onKeyDown = (e: any) => {
    if (e.keyCode === KeyCodes.Enter) e.currentTarget.click();
  }

  const renderProcess = () =>
    processList.filter(p => p.isOutstanding).map((p) => (
      <div key={p.id}  onClick={() => onClickProcess(p.id)} onKeyDown={onKeyDown}>
        <ListItem button className='border-black'>
          <ListItemText
            onClick={() => onClickProcess(p.id)}
            disableTypography
            primary={<Typography color='textPrimary'>{p.title}</Typography>}
          />
        </ListItem>
        <Divider />
      </div>
    ));

  const renderAsDialog = () => (
    <Dialog open={isDialogOpened || false} onClose={onCloseDialog}>
      <DialogContent className='flex flex-col m-8'>{renderBody()}</DialogContent>
    </Dialog>
  );

  const renderBody = () => (
    <Fragment>
      <div
        className={clsx(
          classes.backgroundPrincipal,
          'p-4 text-center align-middle font-bold rounded rounded-b-none flex w-full'
        )}
      >
        <Icon className='w-10 text-white'>star</Icon>
        <span className={clsx('w-full text-base', classes.textSecondary)}>
          {t('components.process.title')}
        </span>
      </div>

      <List
        component='nav'
        className={clsx(classes.backgroundPaper, 'border')}
        aria-label='process'
      >
        {renderProcess()}
        <ListItem button className={clsx('border-black', classes.noPadding)} onClick={() => onAllProcess()} onKeyDown={onKeyDown}>
          <ListItemText
            disableTypography
            primary={<Typography align='center' color='textPrimary' className='underline'>{t('components.process.seeAll')}</Typography>}
          />
        </ListItem>
      </List>
    </Fragment>
  );

  return (
    <Fragment>{showAsDialgo ? renderAsDialog() : renderBody()}</Fragment>
  );
};
export default Process;
