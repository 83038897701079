import { useEffect } from 'react';
import { IUserDtoJwt } from '../../dto/UserDto';
import { parseJwt } from '../../utilitys/jwt';

const useJwtAuthentication = (token: string, setAuthUser : any) => {
  useEffect(() => {
    const fetch = async () => {
      if (token) {
        const claims = parseJwt(token);
        const data: IUserDtoJwt = {
          id: claims.id,
          email: claims.email, 
          identification: claims.id,
          roles: claims.role.split(',') as string[],
          fullName: claims.unique_name,
          nickName: claims.sub,
          mustChangePassword: claims.mustChangePassword
        }
        setAuthUser(data);
      }else {
        setAuthUser(undefined);
      }
    };
    fetch();
  }, [token, setAuthUser]);

};

export default useJwtAuthentication;
