import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { ErrorDisplays, PagePrincipal } from '../../../containers';
import { Container, Paper } from '@material-ui/core';
import {
  Alert,
  AutocompleteStyled,
  ButtonCaptcha,
  Loading,
  TextField,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import useValidator from '../../../hooks/validation/useValidator/useValidator';
import { IFormState } from './types';
import { validations } from './validations';
import { recoveryPasswordVUVApi } from '../../../api/user';
import { IAlertTypes } from '../../../components/Alert';
import { useAlert } from '../../../hooks/state/useAlert';
import { IErrorApiBase } from '../../../api/_base/types';

interface OptionsDto {
  id: string;
  text: string;
}

const RecoveryPw = () => {
  const { t } = useTranslation();
  const [isVisible, openAlert, hideAlert] = useAlert(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorValidation, setErrorValidation] = useState<
    IErrorApiBase[] | undefined
  >();

  const options: OptionsDto[] = [
    {
      id: '0',
      text: t('pages.user.recoveryPw.option1'),
    },
    {
      id: '1',
      text: t('pages.user.recoveryPw.option2'),
    },
    {
      id: '2',
      text: t('pages.user.recoveryPw.option3'),
    },
  ];
  const [formState, setFormState] = useState<IFormState>({
    dataRecovery: '',
    recoveryMethod: '',
  });

  const onChange = (propName: string) => (value: any) => {
    const formStateClone = { ...formState, [propName]: value };
    setFormState(formStateClone);
  };

  const submit = async () => {
    setIsLoading(true);
    const [success, errors] = await recoveryPasswordVUVApi(
      +formState.recoveryMethod,
      formState.dataRecovery
      );
      if (success) openAlert();
      else setErrorValidation(errors);
      setIsLoading(false);
  };

  const [formStateError, startValidations] = useValidator<IFormState>(submit);
  const getOptionSelected = () =>
    options.find((p) => p.id === formState.recoveryMethod);

  const onChangeAutoComplete = (e: any, value: any) => {
    onChange('recoveryMethod')(value ? value.id : '');
  };

  return (
    <PagePrincipal>
      <div>
        <div className='text-center'>
          <Typography variant='h4' color='primary'>
            {t('pages.user.recoveryPw.title')}
          </Typography>
          <br />
          <br />
          <Container component={Paper} className='p-20 text-left'>
            <div className='w-full flex flex-col items-center '>
              <div className='w-96 space-y-4'>
                <AutocompleteStyled
                  isOutlined
                  options={options}
                  label={t('pages.user.recoveryPw.selectLabel')}
                  value={getOptionSelected()}
                  error={formStateError?.recoveryMethod}
                  getOptionLabel={(option: OptionsDto) => option.text}
                  renderInput={() => <></>}
                  onChange={onChangeAutoComplete}
                />
                <TextField
                  isRequired
                  isOutlined
                  label={t(`pages.user.recoveryPw.labelText`)}
                  value={formState.dataRecovery}
                  error={formStateError?.dataRecovery}
                  onChange={onChange('dataRecovery')}
                />
                <ButtonCaptcha
                  onClick={() => startValidations(formState, validations)}
                  textButton={t('pages.user.recoveryPw.textButton')}
                />
              </div>
            </div>
          </Container>
        </div>
        <div className='pl-40 pr-40'></div>
      </div>
      <Alert
        open={isVisible}
        type={IAlertTypes.success}
        onClickClose={hideAlert}
      >
        <span className='text-lg'>
          {t('pages.user.recoveryPw.messageSuccess')}
        </span>
      </Alert>
      <Loading loading={isLoading} />
      <ErrorDisplays errors={errorValidation} module='useRecovery' />
    </PagePrincipal>
  );
};
export default RecoveryPw;
