import React from 'react';
import { useTranslation } from 'react-i18next';
import Pqrsd from '..';
import { EnumTypePQRS } from '../../../constants/PqrsType';

const PqrsdNonReg = () => {
  const { t } = useTranslation();
  return (
    <Pqrsd
      title={t('pages.pqrs.nonReg.title')}
      subTitle={t('pages.pqrs.nonReg.subTitle')}
      typePqrs={EnumTypePQRS.NotRegistered}
    />
  );
};
export default PqrsdNonReg;
