import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Typography } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiFab-root': {
        background: `${theme.palette.primary.main}`,
        borderRadius: '10%',
        color: `#fff`,
        outline: 'none',
        minWidth: '30px',
        maxWidth: '30px',
        minHeight: '30px',
        maxHeight: '30px',
      },
      '& .myLabel': {
        color: `${theme.palette.text.primary}`,
        display: 'none',
        marginTop: '5px',
        marginLeft: '10px',
        position: 'absolute',
        whiteSpace: 'nowrap'
      }
    },
    scrollToTop: {
      background: `${theme.palette.background.paper}`
    },
    containerFab: {
      boxShadow: '-2px 2px 4px 2px rgba(0,0,0,.3)',
      cursor: 'pointer',
      padding: '5px',
      textAlign: 'center',
      width: '100%',
      '&:hover': {
        background: `${theme.palette.background.paper}`,
        textAlign: 'start',
        width: '300px',
        transition: theme.transitions.create('width', {
          duration: 500,
          easing: theme.transitions.easing.easeOut
        }),
        '& .MuiFab-root': { background: '#000' },
        '& > .myLabel': { display: 'inline' },
      },
    }
}));

const ScrollToTop = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible((window.pageYOffset > 300));
    }

    const onScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        }
    }, []);

    return (
        <div className={clsx(classes.root, 'fixed bottom-10 right-0 z-10', isVisible ? 'opacity-100' : 'opacity-0')}>
            <div className={clsx(classes.scrollToTop, 'flex flex-col items-center w-12 rounded-l')}>
                <div className={clsx(classes.containerFab, 'rounded')} onClick={() => onScrollToTop()}>
                    <Fab
                    size='small'
                    aria-label='Add'
                    variant='extended'
                    style={{fontSize: "15px"}}
                    onClick={() => onScrollToTop()}
                    >
                    <KeyboardArrowUpIcon style={{fontSize: "30px"}} />
                    </Fab>
                    <Typography variant='body2' className='myLabel' style={{fontSize: "14px"}}>
                    {t(`components.scrollToTop.message`)}
                    </Typography>
                </div>
            </div>
      </div>
    );
}

export default ScrollToTop;
