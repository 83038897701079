import { MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IPageSizeSelectorProps {
    pageSize: number;
    options: number[];
    onChangeSize: (pageSize: number) => void;
}

export const PageSizeSelector = ({
    pageSize,
    options,
    onChangeSize
}: IPageSizeSelectorProps) => {
    const { t } = useTranslation();

    const handleChange = ({ target }: React.ChangeEvent<{ value: unknown }>) => {
        onChangeSize(target.value as number);
    }

    return (
        <div>
            <label className='mr-5'> {t('components.pagination.recordsPerPage')} </label>
            <Select value={pageSize} onChange={handleChange}>
                { options.map(option => (<MenuItem key={option} value={option}>{ option }</MenuItem>)) }
            </Select>
        </div>
    );
};
