import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonCaptcha, TextField } from '../../components';
import SearchIcon from '@material-ui/icons/Search';
import { validations } from './validations';
import useValidator from '../../hooks/validation/useValidator/useValidator';
import { TypeTextField } from '../../components/TextField';
import { useHistory } from 'react-router';
import RouteNavigation from '../../constants/RouteNavigation';
import { validationInformationDocumentAccesCode } from '../../api/pqrs';
import { ErrorDisplays } from '..';
import { IErrorApiBase } from '../../api/_base/types';

const useStyles = makeStyles((theme) => ({
  backgroundPrincipal: {
    backgroundColor: theme.palette.primary.main,
  },
  backgroundPaper: {
    backgroundColor: theme.palette.background.paper,
  },
  textSecondary: {
    color: theme.palette.text.secondary
  }
}));

interface IFormState {
  id: string;
  password: string;
}

const initialState: IFormState = {
  id: '',
  password: '',
};

interface ISearchPQRSDProps {
  hideTitle?: boolean;
}

const SearchPQRSD = ({ hideTitle }: ISearchPQRSDProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, setState] = useState<IFormState>(initialState);
  const [errorValidation, setErrorValidation] = useState<
    IErrorApiBase[] | undefined
  >();
  const history = useHistory();

  const handleOnSaved = () => {
    setState(initialState);
    onSearch(state.id, state.password);
  };

  const onChange = (propName: string) => (value: any) => {
    const formStateClone = { ...state, [propName]: value };
    setState(formStateClone);
  };

  const [stateError, startValidations] = useValidator<IFormState>(
    handleOnSaved
  );

  const onSearch = async (document: string, accessCode: string) => {
    const validationsErrors = await validationInformationDocumentAccesCode(
      document,
      accessCode
    );
    if (validationsErrors) {
      setErrorValidation(validationsErrors);
      return;
    }
    history.push(
      `/${RouteNavigation.pqrsTrackingDocument}/${document}/${accessCode}`
    );
  };

  return (
    <div>
      {!hideTitle && (
        <div
          className={clsx(
            classes.backgroundPrincipal,
            'p-4 text-center align-middle font-bold rounded rounded-b-none flex'
          )}
        >
          <span className={clsx('w-full text-base', classes.textSecondary)}>
            {t('components.searchPQRSD.title')}
          </span>
        </div>
      )}

      <div className='p-2 pt-4'>
        <TextField
          label={t('components.searchPQRSD.labelID')}
          error={stateError?.id}
          value={state.id}
          onChange={onChange('id')}
        />
        <br />
        <br />
        <TextField
          label={t('components.searchPQRSD.labelPassword')}
          error={stateError?.password}
          value={state.password}
          type={TypeTextField.password}
          onChange={onChange('password')}
        />

        <div className='text-right mt-3 w-auto'>
          <ButtonCaptcha
            textButton={t('components.searchPQRSD.search')}
            onClick={() => startValidations(state, validations)}
            startIcon={<SearchIcon />}
            centerContent
          />
        </div>
      </div>
      <ErrorDisplays errors={errorValidation} module='pqrs' />
    </div>
  );
};
export default SearchPQRSD;
