import { IUserProfileState } from './types';
import { IUserVuvDto } from '../../../dto/UserDto';
import {
  IRegisterUserMetaDataRequest,
  // IRegisterUserMetaDataRequest,
  IUpdateUserRequest,
} from '../../../api/user/types';
import { ContactMetaDataState } from '../../../containers/ContactMetaData/types';

export const mapDtoToState = (dto: IUserVuvDto): IUserProfileState => {
  const state = {
    userType: {
      id: dto.userTypeId,
      name: ''
    },
    personType: {
      id: dto.personTypeId,
      description: '',
    },
    identificationType: {
      id: dto.identificationTypeId,
      description: '',
      personTypeId: dto.personTypeId.toString(),
    },
    country: {
      id: dto.countryId,
      name: '',
    },
    department: {
      id: dto.departmentId,
      countryId: dto.countryId,
      name: '',
    },
    city: {
      id: dto.cityId,
      departmentId: dto.departmentId,
      name: '',
    },
    treatment: {
      description: '',
      id: dto.treatmentId,
    },
    identificationNumber: dto.identificationNumber,
    company: dto.company,
    fistName: dto.fistName,
    middleName: dto.middleName || '',
    lastName: dto.lastName,
    middlelastName: dto.middlelastName || '',
    poste: dto.poste || '',
    phone: dto.phone || '',
    phone2: dto.phone2 || '',
    address: dto.address || '',
    address2: dto.address2 || '',
    address3: dto.address3 || '',
    email: dto.email,
    email2: dto.email2 || '',
    nickName: dto.userName,
    metaData: {},
  };
  if (dto && dto.metaData) {
    dto.metaData!.forEach((p) => {
      state.metaData = {
        ...state.metaData,
      [p.configurationId]: {
          text: p.text,
          value: p.value,    
        }
      };
    });
  }
  return state;
};

export const mapStateToDto = (state: IUserProfileState, metaData: ContactMetaDataState): IUpdateUserRequest => {
  const dto: IUpdateUserRequest = {
    userTypeId: state.userType.id,
    countryId: state.country.id,
    departmentId: state.department.id,
    cityId: state.city.id,
    treatmentId: state.treatment.id,
    identificationNumber: state.identificationNumber,
    company: state.company,
    fistName: state.fistName,
    middleName: state.middleName,
    lastName: state.lastName,
    middlelastName: state.middlelastName,
    poste: state.poste,
    phone: state.phone,
    phone2: state.phone2,
    address: state.address,
    address2: state.address2,
    address3: state.address3,
    email: state.email,
    email2: state.email2,
    metaData: [],
  };

    dto.metaData = Object.keys(metaData).map(
    (key) =>
      ({
        id: +key,
        configurationId: +key,
        text: metaData[+key].text,
        value: metaData[+key].value,
        typeElement: metaData[+key].type,
      } as IRegisterUserMetaDataRequest)
  );

  return dto;
};
