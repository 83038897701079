import { IRoutesAccess } from '../containers/Authorization/types';
import { routesGeneral } from './general';
import { routesPqrsd } from './pqrsd';
import { routesProcess } from './process';
import { routesUser } from './user';
import { routesOurSite } from './ourSite';
import { routesPortfolio } from './portfolio';
import { routesNotification } from './notification';

export const RoutesApp: IRoutesAccess[] = [
  ...routesGeneral,
  ...routesPqrsd,
  ...routesProcess,
  ...routesUser,
  ...routesOurSite,
  ...routesPortfolio,
  ...routesNotification
];
