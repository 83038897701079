import React from 'react';

interface IDangerouslySetInnerHTMLProps {
  text: string;
}

const DangerouslySetInnerHTML = ({ text }: IDangerouslySetInnerHTMLProps) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: text.replaceAll('color:', '').replaceAll('font-size:', ''),
      }}
      className='text-base font-body'
    ></div>
  );
};
export default DangerouslySetInnerHTML;
