
import { get } from 'lodash';
import { IUseValidatorResult } from './useValidator';

export const assignError = (
  objectToAssing: IUseValidatorResult,
  isNestedProp: boolean,
  field: string,
  error: string
) => {
  let newField: string = field;
  if (isNestedProp) {
    newField = field.split('.')[0];
  }
  objectToAssing[newField] = error;
};

export const validateIsPropNested = (nameField: string, state: any) => {
  const fieldSplited = nameField.split('.');
  const totalItems = fieldSplited.length;
  const propsNested = fieldSplited.splice(1, totalItems);
  const obj = get(state, fieldSplited[0]);
  return checkNested(obj, propsNested);
};

const checkNested = (obj: any, props: string[]) => {
  for (var i = 0; i < props.length; i++) {
    if (!obj || !obj.hasOwnProperty(props[i])) {
      return false;
    }
    obj = obj[props[i]];
  }
  return true;
};