import React, { Fragment, useContext } from 'react';
import { Fab, Typography } from '@material-ui/core';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import contextApp from '../../hooks/contexts/contextApp';
import { ThemeType } from '../../constants/Theme';
import { useTranslation } from 'react-i18next';
import PanToolIcon from '@material-ui/icons/PanTool';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface IButtonFontSizeProps {
  onChangeSize: (more: boolean) => void;
  onChangeTheme: (theme: string) => void;
  themeSelected: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFab-root': {
      background: '#fff',
      borderRadius: '10%',
      color: `${theme.palette.primary.main}`,
      outline: 'none',
      minWidth: '30px',
      maxWidth: '30px',
      minHeight: '30px',
      maxHeight: '30px',
    },
    '& .myLabel': {
      color: '#fff',
      display: 'none',
      marginTop: '5px',
      marginLeft: '10px',
      position: 'absolute',
      whiteSpace: 'nowrap'
    }
  },
  accessibilityBar: {
    background: `${theme.palette.primary.main}`,
    minWidth: '40px',
    maxWidth: '40px'
  },
  containerFab: {
    cursor: 'pointer',
    padding: '5px',
    textAlign: 'center',
    transition: 'none',
    width: '100%',
    '&:hover': {
      background: `${theme.palette.primary.light}`,
      textAlign: 'start',
      width: '350px',
      transition: theme.transitions.create('all', {
        duration: 500,
        easing: theme.transitions.easing.easeOut
      }),
      '& > .myLabel': {
        display: 'inline'
      },
    },
  }
}));

const ButtonFontSize = ({
  onChangeSize,
  onChangeTheme,
  themeSelected,
}: IButtonFontSizeProps) => {
  const ctxApp = useContext(contextApp);
  const { t } = useTranslation();
  const classes = useStyles();

  const renderBouttonsTheme = (): React.ReactNode => {
    return (
      <Fragment>
        {themeSelected === ThemeType.Default ? (
          <div className={clsx(classes.containerFab, 'rounded')} onClick={() => onChangeTheme(ThemeType.Dark)}>
            <Fab
              size='small'
              aria-label='Add'
              variant='extended'
              onClick={() => onChangeTheme(ThemeType.Dark)}
            >
              <Brightness4Icon style={{fontSize: "15px"}} />
            </Fab>
            <Typography variant='body2' className='myLabel' style={{fontSize: "13px"}}>
              {t(`components.buttonAccesibility.tooltipTheme`)}
            </Typography>
          </div>
        ) : (
          <div className={clsx(classes.containerFab, 'rounded')} onClick={() => onChangeTheme(ThemeType.Default)}>
            <Fab
              size='small'
              aria-label='Add'
              variant='extended'
              onClick={() => onChangeTheme(ThemeType.Default)}
            >
              <Brightness7Icon style={{fontSize: "15px"}} />
            </Fab>
            <Typography variant='body2' className='myLabel' style={{fontSize: "13px"}}>
              {t(`components.buttonAccesibility.tooltipTheme`)}
            </Typography>
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <div id='accesibilityBar' className={clsx(classes.root, 'fixed top-1/3 right-0 z-10')}>
      <div className={clsx(classes.accessibilityBar, 'flex flex-col items-center rounded py-2 w-12')}>
        {renderBouttonsTheme()}
        <div className={clsx(classes.containerFab, 'rounded')} onClick={() => onChangeSize(false)}>
          <Fab
            size='small'
            aria-label='Add'
            variant='extended'
            style={{fontSize: "15px"}}
            onClick={() => onChangeSize(false)}
          >
            A-
          </Fab>
          <Typography variant='body2' className='myLabel' style={{fontSize: "13px"}}>
            {t(`components.buttonAccesibility.tooltipDecreaseFont`)}
          </Typography>
        </div>
        <div className={clsx(classes.containerFab, 'rounded')} onClick={() => onChangeSize(true)}>
          <Fab
            size='small'
            aria-label='Add'
            style={{fontSize: "15px"}}
            variant='extended'
            onClick={() => onChangeSize(true)}
          >
            A+
          </Fab>
          <Typography variant='body2' className='myLabel' style={{fontSize: "13px"}}>
            {t(`components.buttonAccesibility.tooltipIncreanceTheme`)}
          </Typography>
        </div>
        {ctxApp.relayCenter.show && (
          <div className={clsx(classes.containerFab, 'rounded')} onClick={() => window.open(ctxApp.relayCenter.urlLink, "_blank")}>
            <Fab
              size='small'
              aria-label=''
              variant='extended'
              onClick={() => window.open(ctxApp.relayCenter.urlLink, "_blank")}
            >
              <PanToolIcon  style={{fontSize: "15px"}} />
            </Fab>
            <Typography variant='body2' className='myLabel' style={{fontSize: "13px"}}>
              {t(`components.buttonAccesibility.tooltipRelayCenter`)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
export default ButtonFontSize;
