import { IRoutesAccess } from '../containers/Authorization/types';
import RouteNavigation from '../constants/RouteNavigation';
import {
  PorfolioMyDocumentsPage,
  PorfolioMyExpedientsPage,
} from '../pages';
import Roles from '../constants/Roles';

export const routesPortfolio: IRoutesAccess[] = [
  {
    url: RouteNavigation.PortfolioMyDocuments,
    component: PorfolioMyDocumentsPage,
    allowAnonymous: false,
    rolesAccesss: [Roles.GuestVUV],
  },
  {
    url: RouteNavigation.PortfolioMyExpedients,
    component: PorfolioMyExpedientsPage,
    allowAnonymous: false,
    rolesAccesss: [Roles.GuestVUV],
  },
  {
    url: `${RouteNavigation.PortfolioMyExpedients}/:tab`,
    component: PorfolioMyExpedientsPage,
    allowAnonymous: false,
    rolesAccesss: [Roles.GuestVUV],
  },
  {
    url: `${RouteNavigation.PortfolioMyExpedients}/:tab/:id`,
    component: PorfolioMyExpedientsPage,
    allowAnonymous: false,
    rolesAccesss: [Roles.GuestVUV],
  }
];
