import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import RouteNavigation from '../../constants/RouteNavigation';
import { hasUserAccess } from '../../utilitys/security';
import { userContext } from '../../hooks/contexts/userContext';
import { parseJwt } from '../../utilitys/jwt';

interface IAuthorizationProp {
  children: React.ReactNode;
  token: string;
  onChangeStatusAuth: (token: string) => void;
}

const Authorization = ({ children, token, onChangeStatusAuth }: IAuthorizationProp) => {
  const location = useLocation();
  const history = useHistory();
  const { userAuth } = useContext(userContext);
  const [withAccess, setWithAccess] = useState<boolean>(false);

  useEffect(() => {
    userHasToChangePassword();
    checkJwtStatus();
    if (hasUserAccess(location.pathname, userAuth)) {
      setWithAccess(true);
      return;
    }
    setWithAccess(false);
    redirectNoAccess();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, token]);

  const userHasToChangePassword = () => {
    if (!isCurrentlocationUserChangePassword() && userAuth?.mustChangePassword)
      history.push(`/${RouteNavigation.UserChangePassword}`);
  };

  const isCurrentlocationUserChangePassword = () => {
    return location.pathname === `/${RouteNavigation.UserChangePassword}`;
  };

  const redirectNoAccess = () => {
    history.push(`/${RouteNavigation.FocerLogin}`);
  };

  const checkJwtStatus = () => {
    if (!token) return true;
    const { exp } = parseJwt(token);
    if (Date.now() >= exp * 1000) {
      onChangeStatusAuth('');
      history.push(RouteNavigation.Home);
    }
  };

  return <Fragment>{withAccess && <Fragment>{children}</Fragment>}</Fragment>;
};

export default Authorization;
