interface IImageFooterProps {
    src: string;
    link?: string;
}

const ImageFooter = ({ src, link }: IImageFooterProps) => {
    const onImageClick = () => { if (link) window.open(link, '_blank'); }

    return (
        <img
            src={ src }
            alt="logo"
            className='max-h-28 ml-6 max-w-xs p-1'
            style={{ cursor: link && 'pointer' }}
            onClick={ () => onImageClick() }      
        />
    )
}

export default ImageFooter;
