import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface IBasicModalProps {
  open: boolean;
  title: string;
  showAcceptButton?: boolean;
  showCancelButton?: boolean;
  onClickAccept?: () => void;
  onClickCancel?: () => void;
  titleButtonAccept?: string;
  titleButtonCancel?: string;
  maxWidth?:  'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  children: any;
}

const BasicModal = ({
  open,
  title,
  showCancelButton,
  showAcceptButton,
  titleButtonCancel,
  titleButtonAccept,
  children,
  onClickAccept,
  onClickCancel,
  maxWidth
}: IBasicModalProps) => {
  const classes = useStyles();

  const handleClickAccept = () => {
    if (onClickAccept) onClickAccept();
  };

  const handleClickCancel = () => {
    if (onClickCancel) onClickCancel();
  };

  return (
    // <div>
      <Dialog aria-labelledby='customized-dialog-title' open={open}  maxWidth={maxWidth}>
        <DialogTitle className={classes.root}>
          <Typography variant='h5'>{title}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          {children}
          <br />
        </DialogContent>
        <DialogActions>
          {showAcceptButton && (
            <Button
              autoFocus
              color='primary'
              variant='contained'
              onClick={handleClickAccept}
            >
              {titleButtonAccept}
            </Button>
          )}
          {showCancelButton && (
            <Button
              onClick={handleClickCancel}
              color='inherit'
              variant='outlined'
            >
              {titleButtonCancel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    // </div>
  );
};

export default BasicModal;
