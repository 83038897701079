import { uniqueId } from 'lodash';
import React, { Fragment } from 'react';
import { IDynamicControlDto } from '../../dto/DynamicControlDto';
import PanelDefault from './Panel';
import Panel1 from './Panel1';
import Panel2 from './Panel2';
import Panel3 from './Panel3';
import Panel4 from './Panel4';
import Panel5 from './Panel5';

interface IDynamicControlTemplateProps {
  configuration: IDynamicControlDto;
  fullWidth?: boolean;
}

const DynamicControlTemplate = ({
  configuration,
  fullWidth,
}: IDynamicControlTemplateProps) => {
  const buildControl = () => {
    switch (configuration.type.toUpperCase()) {
      case 'PANEL1':
        return buildDivSize(
          configuration.size,
          <Panel1
            title={configuration.title}
            tagLeft={configuration.tagLeft}
            tagRight={configuration.tagRight}
            description={configuration.paragraph1}
            description2={configuration.paragraph2}
            url={configuration.link}
            img={configuration.image}
          />
        );
      case 'PANEL2':
        return buildDivSize(
          configuration.size,
          <Panel2
            title={configuration.title}
            tagLeft={configuration.tagLeft}
            tagRight={configuration.tagRight}
            description={configuration.paragraph1}
            description2={configuration.paragraph2}
            url={configuration.link}
            img={configuration.image}
          />
        );
      case 'PANEL3':
        return buildDivSize(
          configuration.size,
          <Panel3
            title={configuration.title}
            tagLeft={configuration.tagLeft}
            tagRight={configuration.tagRight}
            description={configuration.paragraph1}
            description2={configuration.paragraph2}
            url={configuration.link}
            img={configuration.image}
          />
        );
      case 'PANEL4':
        return buildDivSize(
          configuration.size,
          <Panel4
            title={configuration.title}
            tagLeft={configuration.tagLeft}
            tagRight={configuration.tagRight}
            description={configuration.paragraph1}
            description2={configuration.paragraph2}
            url={configuration.link}
            img={configuration.image}
          />
        );
      case 'PANEL5':
        return buildDivSize(
          configuration.size,
          <Panel5
            title={configuration.title}
            tagLeft={configuration.tagLeft}
            tagRight={configuration.tagRight}
            description={configuration.paragraph1}
            description2={configuration.paragraph2}
            url={configuration.link}
            img={configuration.image}
          />
        );
      default:
        return buildDivSize(
          configuration.size,
          <PanelDefault title='title' description='descriptions' />
        );
    }
  };
  const buildDivSize = (size: string, children: any) => {
    let classSize = 'w-full mt-4';

    if (fullWidth || size === '100') {
      classSize += '';
    } else if (size === '50') {
      classSize += ' lg:w-1/2';
    } else if (size === '30') {
      classSize += ' lg:w-4/12';
    }

    return (
      <div key={uniqueId()} className={classSize}>
        {children}
      </div>
    );
  };

  return <Fragment>{buildControl()}</Fragment>;
};

export default DynamicControlTemplate;
