import React from 'react';
import './index.css';

interface ISplashScreenProps {
  logo?: string;
}

const SplashScreen = ({ logo }: ISplashScreenProps) => {
  return (
    <div id='splash-screen-evo'>
      <div className='center'>
        {logo && (
          <div className='logo'>
            <img src={logo} alt='logo' />
          </div>
        )}
        <div className='spinner-wrapper'>
          <div className='spinner'>
            <div className='inner'>
              <div className='gap' />
              <div className='left'>
                <div className='half-circle' />
              </div>
              <div className='right'>
                <div className='half-circle' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SplashScreen);
