
export enum EnumValidatorType {
  Value,
  Length
}

export interface IValidationPatten {
  value: RegExp;
  message: string;
}

export interface IValidation {
  required: boolean;
  message?: string;
  pattern?: IValidationPatten;
  where?: {
    fieldParent: string,
    value: any,
    required: boolean,
    message?: string;
    pattern?: IValidationPatten;
    validationType: EnumValidatorType;
  }
}

export interface IValidatorField {
  field: string;
  validations: IValidation;
}
