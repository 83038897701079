import i18next from 'i18next';
import {
  regexAlphanumericWithSpace,
  regexEmail,
} from '../../../constants/Regex';
import { IdentificationTypeDto } from '../../../dto/admin/types';
import {
  EnumValidatorType,
  IValidatorField,
} from '../../../hooks/validation/useValidator/types';

export const validationsStep0 = (
  identificationtype: IdentificationTypeDto
): IValidatorField[] => {
  const validation: IValidatorField[] = [
    {
      field: 'userType.id',
      validations: {
        required: true,
      },
    },
    {
      field: 'personType.id',
      validations: {
        required: true,
      },
    },
    {
      field: 'identificationType.id',
      validations: {
        required: true,
      },
    },
    {
      field: 'fistName',
      validations: {
        required: true,
        pattern: {
          value: /^.{3,}$/,
          message: i18next.t('message.validation.minimum_chars', {
            value: '3',
          }),
        },
      },
    },
    {
      field: 'lastName',
      validations: {
        required: true,
        pattern: {
          value: /^.{3,}$/,
          message: i18next.t('message.validation.minimum_chars', {
            value: '3',
          }),
        },
      },
    },
    {
      field: 'middleName',
      validations: {
        required: false,
        where: {
          value: 1,
          fieldParent: 'middleName',
          validationType: EnumValidatorType.Length,
          required: true,
          pattern: {
            value: /^.{3,}$/,
            message: i18next.t('message.validation.minimum_chars', {
              value: '3',
            }),
          },
        },
      },
    },
    {
      field: 'middlelastName',
      validations: {
        required: false,
        where: {
          value: 1,
          fieldParent: 'middlelastName',
          validationType: EnumValidatorType.Length,
          required: true,
          pattern: {
            value: /^.{3,}$/,
            message: i18next.t('message.validation.minimum_chars', {
              value: '3',
            }),
          },
        },
      },
    },
    {
      field: 'company',
      validations: {
        required: false,
        pattern: {
          value: regexAlphanumericWithSpace,
          message: i18next.t('message.validation.incorrect_format'),
        },
        where: {
          fieldParent: 'personType.id',
          validationType: EnumValidatorType.Value,
          value: 2,
          required: true,
        },
      },
    },
  ];

  const validationIdentificationNumber: IValidatorField = {
    field: 'identificationNumber',
    validations: {
      required: (identificationtype.id !== 'Auto'),
    },
  };

  if (identificationtype.formatRegex) {
    validationIdentificationNumber.validations = {
      required: true,
      pattern: {
        value: new RegExp(identificationtype.formatRegex),
        message: i18next.t('message.validation.incorrect_format'),
      },
    };
  }

  validation.push(validationIdentificationNumber);
  return validation;
};

export const validationsStep1 = () : IValidatorField[] => [
  {
    field: 'address',
    validations: {
      required: true,
    },
  },
  {
    field: 'phone',
    validations: {
      required: true,
      pattern: {
        value: /^.{7,}$/,
        message: i18next.t('message.validation.minimum_chars', {
          value: '7',
        }),
      },
    },
  },
  {
    field: 'poste',
    validations: {
      required: false,
      where: {
        value: 1,
        fieldParent: 'poste',
        validationType: EnumValidatorType.Length,
        required: true,
        pattern: {
          value: /^.{3,}$/,
          message: i18next.t('message.validation.minimum_chars', {
            value: '3',
          }),
        },
      },
    },
  },
];

export const validationsStep2 = () : IValidatorField[] => [
  {
    field: 'email',
    validations: {
      required: true,
      pattern: {
        value: regexEmail,
        message: i18next.t('message.validation.invalidEmail'),
      },
    },
  },
  {
    field: 'nickName',
    validations: {
      required: true,
      pattern: {
        value: /^.{5,}$/,
        message: i18next.t('message.validation.minimum_chars', {
          value: '5',
        }),
      },
    },
  },
];

export const validations = (
  identificationtype: IdentificationTypeDto
): IValidatorField[] => {
  const validations = [
    ...validationsStep0(identificationtype),
    ...validationsStep1(),
    ...validationsStep2(),
  ];
  return validations;
};
