import { API_BASE } from '../api/constants';

export const isExternal = (url: string) => url.startsWith('http') || url.startsWith('https');

export const isUrlInternal = (url: string) => url.startsWith('/');

export const getUrl = (url: string) => {
  if(!url) return '';
  if (isUrlInternal(url)) return API_BASE + (`/files/${url}`).replaceAll('//','/');
  return url;
};

export const getEmbedVideoUrl = (url: string) => {
  if(!url) return '';
  return url.replace('/watch?v=', '/embed/');
}
