import React, { Fragment, useContext } from 'react';
import clsx from 'clsx';
import contextApp from '../../hooks/contexts/contextApp';
import { default as Footer1 } from './Type1';
import { default as Footer2 } from './Type2';
import { default as Footer3 } from './Type3';
import { default as Footer4 } from './Type4';
import { default as FooterDefault } from './Default';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: `${theme.palette.primary.main}`,
    minHeight: '20rem',
  },
  backgroundDefault: {
    background: `${theme.palette.background.default}`,
  },
  backgroundPaper: {
    background: `${theme.palette.background.paper}`,
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  highlight: {
    backgroundColor: `${theme.palette.background.paper}`,
    boxShadow: '0 0 7px 3px rgb(0 0 0 / 25%)',
    marginLeft: '15%',
    marginRight: '15%',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const contextApplication = useContext(contextApp);
  const { footer } = contextApplication;

  const buildFooter = () => {
    switch (footer?.type) {
      case 1:
        return <Footer1 footer={footer!} />;
      case 2:
        return <Footer2 footer={footer!} />;
      case 3:
        return <Footer3 footer={footer!} />;
      case 4:
        return <Footer4 footer={footer!} />
      default:
        return <FooterDefault />;
    }
  };

  return (
    <Fragment>
      {footer?.highlight ? (
        <footer
          className={clsx(classes.footer, 'footer relative pt-1')}
        >
          <div className={clsx(classes.highlight,'bg-white rounded-xl overflow-hidden h-full -mt-32 py-8')}>
            <div>{buildFooter()}</div>
          </div>
        </footer>
      ) : (
        <footer className={clsx(classes.footer, 'footer relative pt-1 flex items-center')}>
          {buildFooter()}
        </footer>
      )}
    </Fragment>
  );
};
export default Footer;
