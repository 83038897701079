import React from 'react';
import { useTranslation } from 'react-i18next';

const NoAccess = () => {
  const { t } = useTranslation();
  return (
    <div className='flex h-full'>
      <div className='m-auto'>
        <h3 className='text-2xl'>{t('pages.noAccess.title')}</h3>
      </div>
    </div>
  );
};

export default NoAccess;
