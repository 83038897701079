import { IDocumentDescriptionDto } from '../../../dto/Reports';

export enum typeDocument {
  pqrs,
  other,
  process
}
export interface MyDocumentsDto extends IDocumentDescriptionDto {
  type: typeDocument
}

export enum enumTypeFiltreDocument {
  withAttachment,
  withAnswer
}

export interface TypeFiltreDocumentDto {
  type: enumTypeFiltreDocument,
  text: string
}

export interface Filtres {
  dateInitial?: Date,
  dateFinal?: Date,
  documentNumber?: string,
  year?: number,
  withAttachment?: boolean,
  withAnswer?: boolean,
}