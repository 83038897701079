import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { FiltersPanel } from '../../components';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
    backgroundPrincipal: {
      backgroundColor: theme.palette.primary.main,
    },
    textSecondary: {
      color: theme.palette.text.secondary,
    },
  })
);

interface IFiltreProps {
  types: string[];
  onSelectType: (type: string[]) => void;
}

const FiltrePrimary = ({ types, onSelectType }: IFiltreProps) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<string[]>([]);
  const { t } = useTranslation();

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onSelectType(newChecked);
  };

  const reset = () => {
    setChecked([]);
    onSelectType([]);
  };
  return (
    <FiltersPanel>
      <List className={clsx(classes.root, '')}>
        <ListItem button onClick={reset}>
          <ListItemIcon>
            <RotateLeftIcon />
          </ListItemIcon>
          <ListItemText primary={t('pages.politics.reset')} />
        </ListItem>
        {types.map((value) => {
          const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem
              key={value}
              role={undefined}
              dense
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  style={{
                    color: 'gray',
                  }}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
      </List>
    </FiltersPanel>
  );
};
export default FiltrePrimary;
