import CustomDataGrid from '../../components/CustomDataGrid';
import { getMetaDataTableConfig } from '../../api/metaDataTableConfig';
import { MetaDataTableConfigDto } from '../../dto/MetadataTableConfigDto';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import { forwardRef, memo, useImperativeHandle, useState, useEffect } from "react";
import { HandleDocumentMetaDataChild, ResultDocumentMetaDataChild, TypeControl } from "./types";

interface IDataGridMemoProps {
  ref: any;
  value?: any[];
  error?: string;
  metaData: MetaDataConfigurationDocumentaryTypeDto;
}

const DataGridMemo = memo(
    forwardRef(
      (
        props: IDataGridMemoProps,
        ref: React.Ref<HandleDocumentMetaDataChild | undefined>
      ) => {
        const {
          value,
          metaData: { isRequired, label, id, initial, dicSec, toolTip, attributeFormId },
        } = props;
        const [valueLocal, setValueLocal] = useState<any[]>(value || []);
        const [metadataConfigTable, setMetadataConfigTable] = useState<MetaDataTableConfigDto[]>([]);

        useEffect(() => {
            const fetch = async () => {
                var result = await getMetaDataTableConfig(id);
                setMetadataConfigTable(result);
            };
            fetch();
        }, [id]);
  
        const onChange = (value: any[]) => setValueLocal(value);
  
        useImperativeHandle(ref, () => ({
          getData() {
            return {
              id,
              value: JSON.stringify(valueLocal),
              text: '',
              typeElement: TypeControl.dataGrid,
              label: label,
              initial: initial,
              dicSec: dicSec,
              attributeFormId,
            } as ResultDocumentMetaDataChild;
          },
        }));
  
        return (
          <CustomDataGrid
            label={label}
            textHelp={toolTip}
            onChange={onChange}
            isRequired={isRequired}
            metadataConfigTable={metadataConfigTable}
          />
        );
      }
    )
);

export default DataGridMemo;
