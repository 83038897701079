import { IRoutesAccess } from '../containers/Authorization/types';
import RouteNavigation from '../constants/RouteNavigation';
import {
  UserProfilePage,
  UserRegisterPage,
} from '../pages';
import Roles from '../constants/Roles';

export const routesUser : IRoutesAccess[] = [
  { url: RouteNavigation.UserRegister, component: UserRegisterPage, allowAnonymous: true },
  { url: RouteNavigation.UserAccount, component: UserProfilePage, allowAnonymous: false, rolesAccesss:[Roles.GuestVUV] },
];
