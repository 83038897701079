export enum TypeControlData {
  text = 'A',
  date = 'F',
  number = 'N',
  currency = 'M',
  email = '@',
  file = '$',
}

export enum TypeControl {
  text = 1,
  list = 2,
  file = 3,
  multipleSelect = 4,
  dataGrid = 5
}

export interface DocumentMetaDataState {
  [key: string]: {
    type: TypeControl,
    value: any,
    text?: string
    label: string;
    initial: string;
    dicSec: number;
    attributeFormId: string;
  }
}

export interface ResultDocumentMetaDataChild {
  id: number,
  value: any;
  text: string;
  typeElement: TypeControl;
  label: string;
  initial: string;
  dicSec: number;
  attributeFormId: string;
}

export interface HandleDocumentMetaDataChild {
  getData: () => ResultDocumentMetaDataChild;
}

export interface RelationListWithChild {
  originInitial: string,
  destinationInitial: string,
  fieldDependentDestination: string,
  valueDependentDestination: string | undefined
}
