import React from 'react';
import { PagePrincipal } from '../../containers';

const ForceLogin = () => {
  return (
    <PagePrincipal>
      <div></div>
    </PagePrincipal>
  );
};
export default ForceLogin;
