import { API_ECM } from '../constants';
import { postFormData } from '../_base';

const baseEcm = `${API_ECM}/v2/Attachment`;

const url = {
  addAttachment: `${baseEcm}/FormFiles`,
};

export const addAttachment = async (
  idEncrypted: string,
  files: File[]
): Promise<boolean | undefined> => {
  const _url = `${url.addAttachment}`;
  const formData = new FormData();

  for (const file of files) {
    formData.append('files', file, file.name);
  }
  formData.set('idEncrypted', idEncrypted);
  formData.set('module', "1");

  const result = await postFormData<boolean>(_url, formData);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};
