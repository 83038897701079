import clsx from 'clsx';
import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Dialog,
  DialogContent,
  Divider,
  Icon,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RouteNavigation from '../../constants/RouteNavigation';
import { userContext } from '../../hooks/contexts/userContext';
import { KeyCodes } from '../../constants/KeyCode';

const useStyles = makeStyles((theme) => ({
  backgroundPrincipal: {
    backgroundColor: theme.palette.primary.main,
  },
  backgroundPaper: {
    backgroundColor: theme.palette.background.paper,
  },
  textSecondary: {
    color: theme.palette.text.secondary
  }
}));

interface IQuickAccessProps {
  showAsDialgo?: boolean;
  isDialogOpened?: boolean;
  onCloseDialog?: () => void;
}

const QuickAccess = ({
  showAsDialgo,
  isDialogOpened,
  onCloseDialog,
}: IQuickAccessProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { userAuth } = useContext(userContext);

  const renderAsDialog = () => (
    <Dialog open={isDialogOpened || false} onClose={onCloseDialog} >
      <DialogContent className='flex flex-col m-8'>
        {renderBody()}
      </DialogContent>
    </Dialog>
  );

  const redirect = (url: string) => (e: any) => history.push(`/${url}`);

  const onKeyDown = (url: string) => (e: any) => {
    if (e.keyCode === KeyCodes.Enter) history.push(`/${url}`);
  };

  const renderBody = () => (
    <div className='w-full'>
      <div
        className={clsx(
          classes.backgroundPrincipal,
          'p-4 text-center align-middle font-bold rounded rounded-b-none flex'
        )}
      >
        <span className={clsx('w-full text-base', classes.textSecondary)}>
          {t('components.quickAccess.title')}
        </span>
      </div>

      <List
        component='nav'
        className={clsx(classes.backgroundPaper, 'border')}
        aria-label='process'
      >
        <ListItem button className='border-black' onKeyDown={onKeyDown(RouteNavigation.Calendar)}>
          <ListItemIcon onClick={redirect(RouteNavigation.Calendar)}>
            <Icon color='secondary' className=''>
              book_online
            </Icon>
          </ListItemIcon>
          <ListItemText
            onClick={redirect(RouteNavigation.Calendar)}
            disableTypography
            primary={
              <Typography color='textPrimary' className='text-center'>
                {t('components.quickAccess.item1')}
              </Typography>
            }
          />
        </ListItem>
        <Divider />
        <ListItem button className='border-black' onKeyDown={onKeyDown(RouteNavigation.Questions)}>
          <ListItemIcon onClick={redirect(RouteNavigation.Questions)}>
            <Icon color='secondary' className=''>
              question_answer
            </Icon>
          </ListItemIcon>
          <ListItemText
            onClick={redirect(RouteNavigation.Questions)}
            disableTypography
            primary={
              <Typography color='textPrimary' className='text-center'>
                {t('components.quickAccess.item2')}
              </Typography>
            }
          />
        </ListItem>
        <Divider />
        <ListItem button className='border-black' onKeyDown={onKeyDown(RouteNavigation.ServiceChannels)}>
          <ListItemIcon onClick={redirect(RouteNavigation.ServiceChannels)}>
            <Icon color='secondary' className=''>
              computer
            </Icon>
          </ListItemIcon>
          <ListItemText
            onClick={redirect(RouteNavigation.ServiceChannels)}
            disableTypography
            primary={
              <Typography color='textPrimary' className='text-center'>
                {t('components.quickAccess.item3')}
              </Typography>
            }
          />
        </ListItem>
        <Divider />
        <ListItem button className='border-black' onKeyDown={onKeyDown(RouteNavigation.MapSite)}>
          <ListItemIcon   onClick={redirect(RouteNavigation.MapSite)}>
            <Icon color='secondary' className=''>
              map
            </Icon>
          </ListItemIcon>
          <ListItemText
            onClick={redirect(RouteNavigation.MapSite)}
            disableTypography
            primary={
              <Typography color='textPrimary' className='text-center'>
                {t('components.quickAccess.item4')}
              </Typography>
            }
          />
        </ListItem>
        <Divider />
        {userAuth && (
          <Fragment>
            <ListItem button className='border-black' onKeyDown={onKeyDown(`${RouteNavigation.PortfolioMyExpedients}/1`)}>
              <ListItemIcon  onClick={redirect(`${RouteNavigation.PortfolioMyExpedients}/1`)}>
                <Icon color='secondary' className=''>
                  book
                </Icon>
              </ListItemIcon>
              <ListItemText
                onClick={redirect(`${RouteNavigation.PortfolioMyExpedients}/1`)}
                disableTypography
                primary={
                  <Typography color='textPrimary' className='text-center'>
                    {t('components.quickAccess.item5')}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem button className='border-black' onKeyDown={onKeyDown(`${RouteNavigation.PortfolioMyExpedients}/0`)}>
            <ListItemIcon  onClick={redirect(`${RouteNavigation.PortfolioMyExpedients}/0`)}>
                <Icon color='secondary' className=''>
                  perm_media
                </Icon>
              </ListItemIcon>
              <ListItemText
                onClick={redirect(`${RouteNavigation.PortfolioMyExpedients}/0`)}
                disableTypography
                primary={
                  <Typography color='textPrimary' className='text-center'>
                    {t('components.quickAccess.item6')}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            {/* <ListItem button className='border-black'>
              <ListItemIcon>
                <Icon color='secondary' className='animate-bounce'>
                  notification_important
                </Icon>
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography color='textPrimary' className='text-center'>
                    {t('components.quickAccess.item7')}
                  </Typography>
                }
              />
            </ListItem> */}
          </Fragment>
        )}
      </List>
    </div>
  );

  return <Fragment>{showAsDialgo ? renderAsDialog() : renderBody()}</Fragment>;
};
export default QuickAccess;
