import { INavigationItem } from './types';
import RouteNavigation from '../constants/RouteNavigation';

export const navigationSite: INavigationItem = {
  id: '5',
  keyTranslate: 'site',
  allowAnonymous: true,
  hide: true,
  hideOnSiteMap: true,
  children: [
    {
      id: '5.1',
      keyTranslate: 'siteMap',
      url: RouteNavigation.MapSite,
      allowAnonymous: true,
      hide: true,
    },
    {
      id: '5.2',
      keyTranslate: 'calendar',
      url: RouteNavigation.Calendar,
      allowAnonymous: true,
      hide: true,
    },
    {
      id: '5.3',
      keyTranslate: 'serviceChannels',
      url: RouteNavigation.ServiceChannels,
      allowAnonymous: true,
      hide: true,
    },
    {
      id: '5.4',
      keyTranslate: 'questions',
      url: RouteNavigation.Questions,
      allowAnonymous: true,
      hide: true,
    },
  ],
};
