import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { default as TextFieldM } from '@material-ui/core/TextField';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { convertDateToString, convertToDate } from '../utilitys/date';
import Required from './Required';

interface ITextFieldDateProps {
  label: string;
  isRequired?: boolean;
  error?: string;
  value?: Date;
  isOutlined?: boolean;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange?: (value: Date) => void;
  onChangeValueFormatISO?: (value: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    marginTop: '20px',
  },
  text: {
    width: '100%',
    color: theme.palette.text.primary,
  },
}));

const TextFieldDate = ({ isRequired, label, error, value, isOutlined, min, max, disabled, onChange, onChangeValueFormatISO }: ITextFieldDateProps) => {
  const classes = useStyles();

  const hadleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value }  } = event;
    const date =  convertToDate(value);
    if (onChange) onChange(convertToDate(value));
    if(onChangeValueFormatISO) onChangeValueFormatISO(convertDateToString(date))
  };

  const minDate = min && convertDateToString(min).substring(0, 10);
  const maxDate = max && convertDateToString(max).substring(0, 10);
  const valueDate = value && convertDateToString(value).substring(0, 10);

  const hasError = !isEmpty(error);

  return (
    <Fragment>
      <InputLabel className={classes.text}>
        {label} {isRequired && <Required />}
      </InputLabel>
      {isOutlined ? (
        <TextFieldM
          inputProps={{
            min: minDate,
            max: maxDate,
          }}
          variant='outlined'
          type='date'
          error={hasError}
          className={classes.root}
          value={valueDate}
          helperText={error}
          onChange={hadleOnChange}
          disabled={disabled}
          InputProps={{
            className: classes.text,
          }}
        />
      ) : (
        <TextFieldM
          inputProps={{
            min: minDate,
            max: maxDate,
          }}
          variant='standard'
          type='date'
          error={hasError}
          className={classes.root}
          value={valueDate}
          helperText={error}
          onChange={hadleOnChange}
          disabled={disabled}
          InputProps={{
            className: classes.text,
          }}
        />
      )}
    </Fragment>
  );
};

export default TextFieldDate;
