import { Typography, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorDisplays, PagePrincipal } from '../../../containers';
import useValidator from '../../../hooks/validation/useValidator/useValidator';
import {
  validations,
  validationsStep0,
  validationsStep1,
  validationsStep2,
} from '../common/validations';
import Form from './form';
import { mapStateToDto } from './mapper';
import { ContactMetaDataState } from '../../../containers/ContactMetaData/types';
import { IValidatorField } from '../../../hooks/validation/useValidator/types';
import { registrerUserVUVApi } from '../../../api/user';
import { Alert, Loading } from '../../../components';
import { IAlertTypes } from '../../../components/Alert';
import { delay } from '../../../utilitys/delay';
import { useHistory } from 'react-router-dom';
import RouteNavigation from '../../../constants/RouteNavigation';
import { IErrorApiBase } from '../../../api/_base/types';
import { IFormState } from '../common/types';
import { hasMetaData } from '../../../api/contact';

const defaultState: IFormState = {
  userType: { id: 0, name: '' },
  country: { id: '169', name: '' },
  department: { id: '', name: '', countryId: '' },
  city: { id: '', name: '', departmentId: '' },
  identificationType: { id: '', description: '', personTypeId: '' },
  personType: { id: 1, description: '' },
  treatment: { id: '', description: '' },
  identificationNumber: '',
  company: '',
  fistName: '',
  middleName: '',
  lastName: '',
  middlelastName: '',
  poste: '',
  phone: '',
  phone2: '',
  address: '',
  address2: '',
  address3: '',
  email: '',
  email2: '',
  nickName: '',
  metaData: {},
};

const Create = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [validateMetaData, setValidateMetaData] = useState<number>(0);
  const [errorValidation, setErrorValidation] = useState<
    IErrorApiBase[] | undefined
  >();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [showMetaData, setShowMetaData] = useState<boolean>(false);
  const [currentStep, setcurrentStep] = useState<number>(0);
  const [formState, setFormState] = useState<IFormState>(defaultState);
  const [formStateError, startValidations] = useValidator<IFormState>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showGoToStepFinal, setShowGoToFinal] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      const result = await hasMetaData(formState.personType.id);
      setShowMetaData(result);
    };

    fetch();
  }, [formState.personType.id]);

  const handleOnUpdateState = (newState: IFormState) => {
    setFormState(newState);
  };

  const handleOnValidationMetaData = (
    hasError: boolean,
    data: ContactMetaDataState,
    validations: IValidatorField[]
  ) => {
    if (hasError) return;
    const formStateClone = { ...formState };
    formStateClone.metaData = data;
    setFormState(formStateClone);
    
    if(showGoToStepFinal) {
      setcurrentStep(3);
      setShowGoToFinal(false);
    }
  };
    
  const handleValidationSteps = (step: number, nextStep: number) => {
    setShowGoToFinal(false);
    if (nextStep < step) {
      setcurrentStep(nextStep);
      return;
    }
    switch (step) {
      case 0:
        if (startValidations(formState, validationsStep0(formState.identificationType)))
          setcurrentStep(nextStep);
        break;
      case 1:
        if (startValidations(formState, validationsStep1()))
          setcurrentStep(nextStep);
        break;
      case 2:
        setShowGoToFinal(true);
        setValidateMetaData(validateMetaData + 1);
        break;
      case 3:
        if (startValidations(formState, validationsStep2()))
          setcurrentStep(nextStep);
        break;
      default:
        if (startValidations(formState, validations(formState.identificationType))) setcurrentStep(nextStep);
        break;
    }
  };

  const handleOnSubmit = async () => {
    if (startValidations(formState, validations(formState.identificationType))) {
      setIsLoading(true);
      const dto = mapStateToDto(formState);
      const [suceess, errors] = await registrerUserVUVApi(dto);

      if (suceess) {
        setOpenAlert(true);
        await delay(6000);
        history.push(RouteNavigation.Home);
        return;
      }
      setIsLoading(false);
      setErrorValidation(errors);
    }
  };

  return (
    <PagePrincipal>
      <div>
        <div className='mt-5'>
          <div className='text-center'>
            <Typography variant='h4' color='primary'>
              {t('pages.user.register.title')}
            </Typography>
            <br />
            <br />
          </div>
          <div className=''>
            <div className='space-y-6 flex justify-center'>
              <Paper className='p-20 m-0 w-full sm:ml-10 sm:mr-10 md:ml-16 md:mr-16 lg:max-w-4xl'>
                <Form
                  formState={formState}
                  formStateError={formStateError}
                  onUpdateState={handleOnUpdateState}
                  startValidations={handleValidationSteps}
                  onSubmit={handleOnSubmit}
                  validateMetaData={validateMetaData}
                  onValidationMetaData={handleOnValidationMetaData}
                  currentStep={currentStep}
                  showMetaData={showMetaData}
                />
              </Paper>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
        <Loading loading={isLoading} />
        <Alert open={openAlert} type={IAlertTypes.success}>
          <span className='text-lg'>
            {t('message.validation.useRegister.messageSuccess')}
          </span>
        </Alert>
        <ErrorDisplays errors={errorValidation} module='useRegister' />
      </div>
    </PagePrincipal>
  );
};
export default Create;
