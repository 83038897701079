import React from 'react';
import { PagePrincipal, SearchPQRSD } from '../../../containers';
import { useTranslation } from 'react-i18next';

const PqrsdTracking = () => {
  const { t } = useTranslation();
  return (
    <PagePrincipal
      withContainer
      title={t('pages.pqrs.tracking.title')}
      subTitle={t('pages.pqrs.tracking.subTitle')}
    >
      <div className='w-full mt-10 lg:pr-80 lg:pl-80'>
        <SearchPQRSD hideTitle />
      </div>
      <br />
      <br />
      <br />
      <br />
    </PagePrincipal>
  );
};
export default PqrsdTracking;
