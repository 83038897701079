import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { default as PaginationM } from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginTop: theme.spacing(2),
      }
    },
    pagination: {
      '& button:focus': {
        outline: 'none'
      }
    }
  })
);

interface IPaginationProps {
  recordsPerPage: number;
  page: number;
  total: number;
  onChangePage: (page: number) => void;
  hideLabel?: boolean;
  size?: 'small' | 'large'
}

const Pagination = ({
  page,
  total,
  recordsPerPage,
  onChangePage,
  hideLabel,
  size
}: IPaginationProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const quantity =  Math.ceil(total / recordsPerPage);

  const handleChange = (e: React.ChangeEvent<unknown>, value: number) => {
    onChangePage(value);
  };

  return (
    <div className={classes.root}>
      {hideLabel && (
        <Typography>
          {t('components.pagination.page')}: {page}
        </Typography>
      )}
      <div className='flex'>
        <PaginationM
          className={classes.pagination}
          count={quantity}
          page={page}
          onChange={handleChange}
          size={size}
          color='secondary'
        />
      </div>
    </div>
  );
};

export default Pagination;
