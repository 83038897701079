import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ProcessTagDto } from '../../../dto/Process';
import { uniq, uniqueId } from 'lodash';
import { Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FiltersPanel, SearchBar } from '../../../components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      maxHeight: '80vh',
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      '&::-webkit-scrollbar': { width: '.3rem' },
      '&::-webkit-scrollbar-track': { background: lighten(theme.palette.primary.main, .8) },
      '&::-webkit-scrollbar-thumb': { backgroundColor: theme.palette.primary.main }
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    selectedItem: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    selectedFather: {
      color: theme.palette.primary.main,
    },
  })
);

interface IFiltreProps {
  tags: ProcessTagDto[];
  onFiltre: (search: string, types: ProcessTagDto | undefined) => void;
}

const Filtre = ({ tags, onFiltre }: IFiltreProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentItemOpen, setCurrentItemOpen] = useState<string>('');
  const [textSearch, setTextSearch] = useState<string>('');
  const [childSelected, setChildSelected] = useState<ProcessTagDto>({
    father: '',
    son: '',
  });

  const father = uniq(tags.map((p) => p.father.toLowerCase()));

  const handleClick = (fatherName: string) => {
    if (fatherName === currentItemOpen) setCurrentItemOpen('');
    else setCurrentItemOpen(fatherName);
    onSelectChild(fatherName, '');
  };

  const getChildenByFather = (fatherName: string) => {
    const data = uniq(
      tags
        .filter((c) => c.father.toUpperCase() === fatherName.toUpperCase() && c.son)
        .map((p) => p.son)
    );
    return data;
  };

  const hasChildrenByFather = (fatherName: string) => {
    return tags.some(c => c.father.toUpperCase() === fatherName.toUpperCase() && c.son);
  }

  const onSelectChild = (father: string, son: string) => {
    onFiltre(textSearch, { father, son });
    setChildSelected({ father, son });
  };

  const isItemSelected = (father: string, son: string) =>
    childSelected.son === son && childSelected.father === father;

  const isFatherSelected = (father: string) => childSelected.father === father;

  const restart = () => {
    setTextSearch('');
    setChildSelected({ father: '', son: '' });
    setCurrentItemOpen('');
    onFiltre('', undefined);
  };

  const onSearch = (value: string) => {
    setTextSearch(value);
    onFiltre(value, childSelected);
  };

  return (
    <FiltersPanel>
      <Fragment>
        <SearchBar placeholder={t('components.filtersPanel.search')} onSearch={onSearch} />
        <br />
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          className={classes.root}
        >
          <ListItem button onClick={restart}>
            <ListItemText primary={t('components.filtersPanel.all')} onClick={restart} />
          </ListItem>
          {father.map((p) => {
            const hasChildren = hasChildrenByFather(p);
            return (
              <Fragment key={uniqueId()}>
                <ListItem button onClick={() => handleClick(p)}>
                  <ListItemText
                    className={clsx(
                      isFatherSelected(p) ? classes.selectedFather : '',
                      'capitalize'
                    )}
                    primary={p}
                  />
                  {hasChildren && (currentItemOpen === p ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                {
                  hasChildren &&
                  (
                    <Collapse in={currentItemOpen === p} timeout='auto' unmountOnExit>
                      <List component='div' disablePadding className='capitalize'>
                        {getChildenByFather(p).map((c) => (
                          <div key={uniqueId()} onClick={() => {
                            onSelectChild(p, c);
                          }}>
                            <ListItem
                              button
                              className={clsx(
                                classes.nested,
                                isItemSelected(p, c) ? classes.selectedItem : ''
                              )}
                            >
                              <ListItemText
                                primary={c}
                                onClick={() => {
                                  onSelectChild(p, c);
                                }}
                              />
                            </ListItem>
                          </div>
                        ))}
                      </List>
                    </Collapse>
                  )
                }
                <hr />
              </Fragment>
            )
          })}
        </List>
      </Fragment>
    </FiltersPanel>
  );
};
export default Filtre;
