import React, { Fragment, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  MenuItem,
  IconButton,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RouteNavigation from '../../constants/RouteNavigation';
import { convertDatetoDatetimeFormatted } from '../../utilitys/date';
import {
  getMyNotificationsByRead,
  setNotificationAsRead,
} from '../../api/notifications';
import { NotificationDto } from '../../dto/NotificationDto';
import { NotificationSourceEnum } from '../../constants/NotificationSourceEnum';
import contextApp from '../../hooks/contexts/contextApp';

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  seeMore: {
    justifyContent: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const NotificationBell = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const contextApplication = useContext(contextApp);
  const [notifications, setNotifications] = useState<NotificationDto[]>([]);

  useEffect(() => {
    let isMounted = true;
    const fetch = async () => {
      const data = await getMyNotificationsByRead();
      if (isMounted) setNotifications(data || []);
    };
    fetch();
    return () => { isMounted = false };
  }, [location.pathname]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickMenu = async (notif?: NotificationDto) => {
    setAnchorEl(null);
    if (notif) {
      await setNotificationAsRead(notif);
      const data = await getMyNotificationsByRead();
      setNotifications(data || []);
      
      const { source, redirectInfo } = notif;
      const route = source === NotificationSourceEnum.pqrsd ? RouteNavigation.pqrsTrackingDocument : `${RouteNavigation.PortfolioMyExpedients}/1`;
      history.push(`/${route}/${redirectInfo}`);
    } else {
      history.push(`/${RouteNavigation.notifications}`);
    }
  };

  return (
    <Fragment>
      <IconButton onClick={handleMenu}>
        {notifications.length > 0 ? (
          <Badge
            badgeContent={notifications.length}
            color='primary'
            className='animate-bounce'
          >
            <NotificationsIcon className='text-red-500' />
          </Badge>
        ) : (
          <NotificationsIcon />
        )}
      </IconButton>

      <Menu
        id='menu-notifications'
        style={{ position: contextApplication.layout === 0 ? 'fixed' : 'absolute' }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem className={clsx(classes.title)}>
          <Typography variant='inherit'>
            {t('pages.notifications.title')}
          </Typography>
        </MenuItem>
        {notifications.length === 0 && (
          <MenuItem>
            <Typography variant='inherit'>
              {t('pages.notifications.noUnreadNotifications')}
            </Typography>
          </MenuItem>
        )}
        {notifications.slice(0, 5).map((notif: NotificationDto) => {
          return (
            <MenuItem
              key={notif.id}
              className={classes.menuItem}
              onClick={() => onClickMenu(notif)}
            >
              <small>{notif.originalDocRad || notif.caseNumber}</small>
              <Typography variant='inherit'>
                {notif.description.slice(0, 50)}
                {notif.description.length > 50 && <span>...</span>}
              </Typography>
              <div className='w-full'>
                <QueryBuilderIcon fontSize='small' />
                &nbsp;
                <small>
                  {convertDatetoDatetimeFormatted(notif.creationDate)}
                </small>
              </div>
            </MenuItem>
          );
        })}

        <Divider />
        <MenuItem
          className={classes.seeMore}
          onClick={() => onClickMenu()}
        >
          <Typography variant='inherit'>
            {t('pages.notifications.seeMore')}
          </Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default NotificationBell;
