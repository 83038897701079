enum RouteNavigation {
  Home = '/',
  ExternalLink = 'external',
  FocerLogin = 'loginF',
  NoAccess = 'noAccess',
  Questions = 'questions',
  Calendar = 'calendar',
  ServiceChannels = 'serviceChannels',
  MapSite = 'mapSite',
  UserGuide = 'userGuide',
  notifications = 'notifications',
  
  pqrsReg = 'pqrs/reg',
  pqrsTracking = 'pqrs/tracking',
  pqrsTrackingReg = 'pqrs/trackingReg',
  pqrsTrackingDocument = 'pqrs/trackingDocument',
  pqrsdAnonyme = 'pqrs/anonyme',
  pqrsdNonReg = 'pqrs/nonReg',
  pqrsInformation = 'pqrs/information',
  
  Process = 'process',
  ProcessDetail = 'process/details',
  ProcessBpm = 'bpm',
  
  OurSiteManual = 'manual',
  OurSiteRegulation = 'novativity',
  OurSiteTechnicalRequirements = 'technicalRequirements',
  OurSitePolitics = 'politics',
  OurSearch = 'search',
  
  Portfolio = 'portfolio',
  PortfolioMyDocuments = 'portfolio/mydocuments',
  PortfolioMyExpedients = 'portfolio/myExpedients',
  PortfolioMyNotifications = 'portfolio/mynotifications',
  
  RecoverPassword = 'recoverPassword',
  
  UserRegister = 'user/register',
  UserRecovery = 'user/recovery',
  UserAccount = 'user/account',
  UserChangePassword = 'user/changePassword',
}
export default RouteNavigation;
