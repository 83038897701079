import { useHistory } from 'react-router';
import { SocialNetwork } from '../../../dto/ContextAppDto';
import { IconButton, makeStyles, Icon } from '@material-ui/core';
import clsx from 'clsx';
import { openWindow } from '../utils';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Fragment } from 'react';

interface ITextFooterProps {
  socialNetwork: SocialNetwork;
  textColor?: string;
  footerType?: number;
}

const SocialNetworkIcon = ({ socialNetwork, textColor, footerType }: ITextFooterProps) => {
  const useStyles = makeStyles((theme) => ({
    btnCircle: {
      border: `1px solid ${textColor}`,
      color: `${textColor}`,
      padding: '7px',
      '&:hover': {
        background: `${textColor}`,
        color: `${theme.palette.primary.main}`
      }
    }
  }));

  const history = useHistory();
  const classes = useStyles();

  const buildIcon = (): JSX.Element => {
    const { icon } = socialNetwork;
    switch(icon) {
      case 'facebook': return <FacebookIcon/>;
      case 'instagram': return <InstagramIcon/>;
      case 'twitter': return <TwitterIcon/>;
      case 'youtube': return <YouTubeIcon/>;
      default: return <Icon>{ icon }</Icon>;
    }
  }

  return (
    <Fragment>
      <IconButton
        size='small'
        className={clsx(classes.btnCircle)}
        onClick={() => { openWindow(history, socialNetwork.url!); }}
      >
        { buildIcon() }
      </IconButton>
      {footerType === 4 &&
        <span
          onClick={() => { openWindow(history, socialNetwork.url!); }}
          className='text-md cursor-pointer'
          style={{color: `${textColor}`, marginLeft: '2px'}}
        >
          {socialNetwork.name}
        </span>
      }
    </Fragment>
  );
};

export default SocialNetworkIcon;
