import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import * as dateFns from 'date-fns';
import { convertDatetoDateFormatted } from '../../utilitys/date';
import { DatePicker, BasicModal } from '..';
import { InputLabel, makeStyles } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { KeyCodes } from '../../constants/KeyCode';

const useStyles = makeStyles((theme) => ({
  bakgroundSecondary: {
    backgroundColor: theme.palette.background.paper,
  },
  primaryText: {
    color: theme.palette.text.primary,
  },
}));

interface IRangeDatesProps {
  label: string;
  labelTo: string;
  labelFrom: string;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  endDate?: Date;
  onChangeDate: (startDate?: Date, endDate?: Date) => void;
}

const RangeDates = ({
  label,
  labelTo,
  labelFrom,
  minDate,
  maxDate,
  onChangeDate,
  startDate,
  endDate
}: IRangeDatesProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Date | undefined>(startDate);
  const [dateTo, setDateTo] = useState<Date | undefined>(endDate);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setDateFrom(startDate);
    setDateTo(endDate);
  }, [startDate, endDate]);

  const { t } = useTranslation();
  const getLabelDates = () => {
    if (dateTo && dateFrom) {
      return `${convertDatetoDateFormatted(
        dateFrom
      )} - ${convertDatetoDateFormatted(dateTo)}`;
    }
    return '';
  };

  const onAcceptDateStart = (date: Date | undefined, hasErrorDate: boolean) => {
    if (!date) setDateTo(undefined);
    setHasError(hasErrorDate);
    setDateFrom(date);
    if (!dateTo && date) setDateTo(dateFns.addDays(date, 1));
  };

  const onAcceptDateEnd = (date: Date | undefined, hasErrorDate: boolean) => {
    setDateTo(date);
    setHasError(hasErrorDate);
  };

  const getValueTo = () => {
    if (dateTo) return dateTo;
    return dateFrom ? dateFns.addDays(dateFrom, 1) : undefined;
  };

  const getValueFrom = () => {
    if (dateFrom) return dateFrom;
    return dateFrom ? dateFns.addDays(dateFrom, 1) : undefined;
  };

  const getDateFormatted = (dateToFormat: Date | undefined) =>
    dateToFormat
      ? `${dateFns.getDate(dateToFormat)} ${t(
          `month.${dateFns.getMonth(dateToFormat)}`
        )} ${dateFns.getYear(dateToFormat)}`
      : '';

  const onClickAccept = () => {
    if (!hasError) {
      setOpen(false);
      onChangeDate(dateFrom, dateTo);
    }
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === KeyCodes.Enter) setOpen(true);
  }

  return (
    <div>
      <div>
        <div className='h-10'>
          <InputLabel className={classes.primaryText}>{label}</InputLabel>
        </div>
        <div className={'relative h-16'}>
          <input
            readOnly
            type='text'
            value={getLabelDates()}
            className={clsx(
              classes.bakgroundSecondary,
              classes.primaryText,
              'w-full h-full pl-4 pr-10 py-3 rounded-md border-2 border-gray-300'
            )}
            onClick={() => {
              setOpen(true);
            }}
            onKeyDown={onKeyDown}
          />
          {getLabelDates() && (
            <div
              className='absolute top-3 right-6 px-3 py-2'
              onClick={() => {
                setDateTo(undefined);
                setDateFrom(undefined);
                onChangeDate(undefined, undefined);
              }}
            >
              <ClearIcon fontSize='small' />
            </div>
          )}
          <div
            className='absolute top-3 right-0 px-3 py-2'
            onClick={() => {
              setOpen(true);
            }}
            onKeyDown={onKeyDown}
          >
            <DateRangeIcon color='secondary' fontSize='small' />
          </div>
        </div>
      </div>
      <BasicModal
        open={open}
        title={label}
        showAcceptButton
        titleButtonAccept='Aceptar'
        onClickAccept={onClickAccept}
      >
        <div>
          <div className='p-10'>
            <div className={clsx(classes.primaryText, 'text-lg')}>
              Seleccione Fechas Entre:{' '}
              <span className='italic'>
                {getDateFormatted(minDate)} y {getDateFormatted(maxDate)}
              </span>
            </div>
          </div>
          <div className='flex'>
            <div className='p-10 pt-0'>
              <DatePicker
                initalDate={getValueFrom()}
                minDate={minDate}
                maxDate={maxDate && dateFns.addDays(maxDate, -1)}
                label={labelFrom}
                onAcceptDate={onAcceptDateStart}
              />
            </div>
            <div className='p-10  pt-0'>
              <DatePicker
                label={labelTo}
                onAcceptDate={onAcceptDateEnd}
                disabled={dateFrom === undefined}
                minDate={dateFrom}
                maxDate={maxDate}
                initalDate={getValueTo()}
              />
            </div>
          </div>
        </div>
      </BasicModal>
    </div>
  );
};
export default RangeDates;
