import { Fragment, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { BasicModal } from '../../components';
import { ErrorDisplays } from '../../containers';
import {
  Collapse,
  createStyles,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { getFileDocument, getFileAttachment, getFileDocumentByAccessCode, getFileAttachmentByAccessCode } from '../../api/file';
import { getDocumentsApi } from '../../api/reports';
import { IDocumentDescriptionDto, IFileDto } from '../../dto/Reports';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { delay } from '../../utilitys/delay';
import { isEmpty, uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IErrorApiBase } from '../../api/_base/types';
import { typeDocument } from '../portfolio/myDocuments/types';
import { userContext } from '../../hooks/contexts/userContext';
import { FileRequestAccessCode, FileRequestModuleAccessCode } from '../../api/file/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(10),
    },
  })
);

interface DownLoadDocumentsProps {
  docId: string;
  documentNumber: string;
  accessCode?: string;
  reload: number;
  type: typeDocument;
}
const DownLoadDocuments = ({
  docId,
  documentNumber,
  accessCode,
  reload,
  type
}: DownLoadDocumentsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [openAttachements, setOpenAttachements] = useState(false);
  const [openAnswers, setOpenAnswers] = useState(false);
  const [errorValidation, setErrorValidation] = useState<
    IErrorApiBase[] | undefined
  >();
  const { userAuth } = useContext(userContext);

  const [
    documentDescription,
    setDocumentDescriptionDto,
  ] = useState<IDocumentDescriptionDto>({
    documentNumber,
    attachments: [],
    answers: [],
    docId: '',
    label: '',
    hasAnswers: false,
    hasAttachments: false
  });

  useEffect(() => {
    const fetch = async () => {
      const result = await getDocumentsApi(docId, type);
      if (result) setDocumentDescriptionDto(result);
    };
    fetch();
  }, [docId, reload, type]);

  const onClickAttachements = () => {
    setOpenAttachements(!openAttachements);
  };

  const onClickAnswers = () => {
    setOpenAnswers(!openAnswers);
  };

  const downloadOriginal = async (docIdDownLoad: string, fileName: string) => {
    setIsDownloading(true);
    await delay(1000);
    if (userAuth){
      const [success, errors]  = await getFileDocument(docIdDownLoad, fileName);
      if(!success) setErrorValidation(errors);
    } else {
      const request: FileRequestAccessCode = { docId: docIdDownLoad, documentNumber, accessCode };
      const [success, errors]  = await getFileDocumentByAccessCode(request, fileName);
      if(!success) setErrorValidation(errors);
    }
    setIsDownloading(false);
  };

  const downloadFile = async (name: string, attachmentId: string) => {
    setIsDownloading(true);
    await delay(1000);
    if (userAuth){
      const [success, errors] = await getFileAttachment(docId, attachmentId, name);
      if(!success) setErrorValidation(errors);
    } else {
      const request: FileRequestModuleAccessCode = { id: docId, attachmentId, documentNumber, accessCode };
      const [success, errors]  = await getFileAttachmentByAccessCode(request, name);
      if(!success) setErrorValidation(errors);
    }
    setIsDownloading(false);
  };

  const buildDocList = (files: IFileDto[], isAnswer: boolean) =>
    files.map((p) => (
      <List component='div' disablePadding key={uniqueId()}>
        <ListItem className={clsx(classes.nested)}>
          <ListItemText primary={p.description} className='truncate' />
          <ListItemSecondaryAction
            onClick={() =>
              isAnswer
                ? downloadOriginal(p.docId, p.description)
                : downloadFile(p.description, p.attachmentId)
            }
          >
            <IconButton
              edge='end'
              aria-label='donwload'
              color='secondary'
              disabled={isDownloading}
            >
              <CloudDownloadIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    ));

  return (
    <Fragment>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant='contained'
        color='primary'
        endIcon={<PictureAsPdfIcon fontSize='large' />}
      >
        {t('pages.pqrs.downloadDocuments.title')}
      </Button>
      <BasicModal
        open={open}
        title={t('pages.pqrs.downloadDocuments.title')}
        showCancelButton
        titleButtonCancel={t('pages.pqrs.downloadDocuments.close')}
        onClickCancel={() => {
          setOpen(false);
        }}
        maxWidth='lg'
      >
        {isDownloading && <LinearProgress /> }
        <div className='w-96'>
          <List>
            <ListItem>
              <ListItemText primary={t('pages.pqrs.downloadDocuments.principal')} />
              <ListItemSecondaryAction
                onClick={() => downloadOriginal(docId, `${documentNumber}.pdf`)}
              >
                <IconButton
                  edge='end'
                  aria-label='donwload'
                  color='secondary'
                  disabled={isDownloading}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {!isEmpty(documentDescription.answers) && (
              <Fragment>
                <ListItem button onClick={onClickAnswers}>
                  <ListItemText primary={t('pages.pqrs.downloadDocuments.answer')} />
                  {openAnswers ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={openAnswers} timeout='auto' unmountOnExit>
                  {buildDocList(documentDescription.answers, true)}
                </Collapse>
              </Fragment>
            )}
            {!isEmpty(documentDescription.attachments) && (
              <Fragment>
                <ListItem button onClick={onClickAttachements}>
                  <ListItemIcon>
                    <AttachFileIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('pages.pqrs.downloadDocuments.attachments')} />
                  {openAttachements ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openAttachements} timeout='auto' unmountOnExit>
                  {buildDocList(documentDescription.attachments, false)}
                </Collapse>
              </Fragment>
            )}
          </List>
        </div>
        
      </BasicModal>
      <ErrorDisplays errors={errorValidation} module='downloadDoc' />
    </Fragment>
  );
};
export default DownLoadDocuments;
