import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Breadcrumbs, Footer, HeaderHorizontal } from '../../containers';
import ButtonFontSize from '../../containers/ButtonAccesibility';
import ScrollToTop from '../../components/ScrollToTop';

const useStyles = makeStyles((theme) => ({
  backgroundDefault: {
    background: `${theme.palette.background.default}`,
  },
}));

interface ILayoutSecondaryProps {
  themeSelected: string;
  onChangeTheme: (theme: string) => void;
  onChangeStatusAuth: (token: string) => void;
  onChangeFontSize: (more: boolean) => void;
  onOpenLogin: () => void;
  children: React.ReactNode;
}

const LayoutSecondary = ({
  children,
  themeSelected,
  onChangeFontSize,
  onChangeTheme,
  onChangeStatusAuth,
  onOpenLogin,
}: ILayoutSecondaryProps) => {
  const classes = useStyles();

  return (
    <div className='min-h-screen'>
      <div className={clsx(classes.backgroundDefault, 'flex flex-col')}>
        <HeaderHorizontal
          onChangeTheme={onChangeTheme}
          onChangeStatusAuth={onChangeStatusAuth}
          themeSelected={themeSelected}
          onOpenLogin={onOpenLogin}
        />
        <Breadcrumbs />
        <main className='mb-40'>{children}</main>
        <ButtonFontSize
          onChangeSize={onChangeFontSize}
          onChangeTheme={onChangeTheme}
          themeSelected={themeSelected}
        />
        <ScrollToTop />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};
export default LayoutSecondary;
