export const getDatePagination = <T>(
  data: T[],
  currentPage: number,
  recordParPage: number
): T[] => {

  const begin = (currentPage - 1) * recordParPage;
  const end = begin + recordParPage;
  return data.slice(begin, end) as T[];
};
