import { INavigationItem } from './types';
import RouteNavigation from '../constants/RouteNavigation';

export const navigationAccount: INavigationItem = {
  id: '6',
  keyTranslate: 'account',
  allowAnonymous: true,
  hide: true,
  urlDefaultSiteMap: 'user/account',
  children: [
    {
      id: '6.1',
      keyTranslate: 'recoveryPw',
      url: RouteNavigation.UserChangePassword,
      allowAnonymous: false,
      hide: true,
    },
    {
      id: '6.2',
      keyTranslate: 'myAccount',
      url: RouteNavigation.UserAccount,
      allowAnonymous: false,
      hide: true,
    },
    {
      id: '6.3',
      keyTranslate: 'register',
      url: RouteNavigation.UserRegister,
      allowAnonymous: false,
      hide: true,
    },
    {
      id: '6.4',
      keyTranslate: 'recoveryPw',
      url: RouteNavigation.RecoverPassword,
      allowAnonymous: true,
      hide: true,
    },
  ],
};
