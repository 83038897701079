import { forwardRef, useState, useImperativeHandle } from 'react';

interface Time {
  h: number;
  m: number;
  s: number;
}

export interface HandleTimerCountDown {
  stop: () => void;
  startTimer: (newSecond: number) => void;
}

interface TimerCountDownProps {
  counterFinished: () => void;
}

let timer: NodeJS.Timeout | undefined;

const TimerCountDown = forwardRef(
  (
    props: TimerCountDownProps,
    ref: React.Ref<HandleTimerCountDown | undefined>
  ) => {
   
    let seconds = 0;

    const [time, setTime] = useState<Time>({
      h: 0,
      m: 0,
      s: 0,
    });

    const secondsToTime = (secs: number): Time => {
      const hours = Math.floor(secs / (60 * 60));
      const divisor_for_minutes = secs % (60 * 60);
      const minutes = Math.floor(divisor_for_minutes / 60);
      const divisor_for_seconds = divisor_for_minutes % 60;
      const seconds = Math.ceil(divisor_for_seconds);
      const obj = {
        h: hours,
        m: minutes,
        s: seconds,
      };
      return obj;
    };

    useImperativeHandle(ref, () => ({
      startTimer(newSecond) {
        seconds = newSecond;
        if (!timer && seconds > 0) {
          setTime(secondsToTime(seconds));
          timer = setInterval(countDown, 1000);
        }
      },
      stop() {
        if(timer) clearInterval(timer);
        timer = undefined;
      },
    }));

    const countDown = () => {
      seconds = seconds - 1;
      setTime(secondsToTime(seconds));
      if (seconds === 0) {
        props.counterFinished();
        if(timer) clearInterval(timer);
        timer = undefined;
      }
    };

    return (
      <div>
       {<span> {time.m} m {time.s} s </span>}
      </div>
    );
  }
);

export default TimerCountDown;
