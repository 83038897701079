import { uniq } from 'lodash';
import {
  MetaDataConfigurationDocumentaryTypeDto,
  MetadataDependencyDto,
} from '../../dto/MetadataDto';
import { RelationListWithChild } from './types';

export const buildRelationListWithChild = (
  relation: RelationListWithChild[],
  initial: string,
  selectedValue: string | undefined
) => {
  const _relationListWithChild = relation.find(
    (p) => p.originInitial === initial
  );
  if (_relationListWithChild) {
    const newRelationListWithChild = {
      ..._relationListWithChild,
      valueDependentDestination: selectedValue,
    };
    const listClone = [...relation.filter((p) => p.originInitial !== initial)];
    listClone.push(newRelationListWithChild);
    return listClone;
  }

  return relation;
};

export const getVisibilityControls = (
  controlsVisibles: string[],
  metadata: MetaDataConfigurationDocumentaryTypeDto[],
  initial: string,
  valueSelected: string | undefined
): string[] => {
  let finalControls = [...controlsVisibles];

  const metadataToHide = getMetadataToHide(metadata, initial);
  finalControls = finalControls.filter((p) => !metadataToHide.includes(p));

  const metadatatoShow = getMetadataToShow(metadata, initial, valueSelected);
  finalControls = finalControls.concat(metadatatoShow);
  return uniq(finalControls);
};

const getMetadataToShow = (
  metadata: MetaDataConfigurationDocumentaryTypeDto[],
  initial: string,
  valueSelected: string | undefined
): string[] => {
  let list: string[] = [];
  metadata
    .filter((p) => p.initial === initial)
    .forEach((m) => {
      m.metadataDependency.forEach((child) => {
        if (child.valueList === valueSelected)
          list = list.concat(child.dependentInitials.filter((p) => p !== ''));
      });
    });
  return uniq(list);
};

const getMetadataToHide = (
  metadata: MetaDataConfigurationDocumentaryTypeDto[],
  initial: string
) => {
  const metaDataFiltred = [...metadata].find((p) => p.initial === initial);
  if (!metaDataFiltred) return [];

  const listFinalDependecy: string[] = [];
  return uniq(
    getMetadataToHideChild(
      metadata,
      metaDataFiltred.initial,
      listFinalDependecy
    )
  );
};

const getMetadataToHideChild = (
  metadata: MetaDataConfigurationDocumentaryTypeDto[],
  initialParent: string,
  listFinalDependecy: string[]
) => {
  const dep = metadata.find((p) => p.initial === initialParent)
    ?.metadataDependency;
  if (!dep) return [];

  const depChild: string[] = dep.reduce(contactDependentInitials, []);
  listFinalDependecy = listFinalDependecy.concat(depChild);
  for (let index = 0; index < depChild.length; index++) {
    listFinalDependecy = listFinalDependecy.concat(
      getMetadataToHideChild(metadata, depChild[index], listFinalDependecy)
    );
  }
  return uniq(listFinalDependecy);
};

const contactDependentInitials = (
  final: string[],
  next: MetadataDependencyDto
): string[] =>
  uniq(final.concat(next.dependentInitials.filter((p) => p !== '')));
