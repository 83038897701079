import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { IPageSimpleProps } from './types';
import { ButtonAdd } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minHeight: theme.spacing(15),
    backgroundColor: `${theme.palette.primary.main}`,
  },
  body: {
    margin: theme.spacing(3),
  },
  actions: {
    display: 'flex',
    '& button': {
      marginLeft: 'auto'
    }
  },
}));

const PageSimple = ({
  showButtonAdd,
  nameUrlBack,
  namePage,
  children,
  onClickAdd,
}: IPageSimpleProps) => {
  const classes = useStyles();

  const handleOnClickAdd = () => {
    if (onClickAdd) onClickAdd();
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.actions}>
          {namePage && (
            <Typography color='textSecondary' variant='h4'>
              {namePage}
            </Typography>
          )}
          {showButtonAdd && <ButtonAdd onClick={handleOnClickAdd} text='' />}
        </div>
      </div>

      <div className={classes.body}>{children}</div>
    </div>
  );
};
export default PageSimple;
