import React, { useEffect, useState } from 'react';
import { getPersonTypeApi } from '../../../api/AdmInquiries';
import { PersonTypeDto } from '../../../dto/admin/types';
import { useTranslation } from 'react-i18next';
import AutocompleteStyled from '../../../components/AutoComplete';

interface IPersonTypeAutocompleteProps {
  idPersonType?: string;
  idSelected?: number;
  isRequired?: boolean,
  error?: string;
  isOutlined?: boolean;
  disabled?:boolean;
  textHelp?: string;
  onChange: (value: PersonTypeDto) => void;
}


const defaultcity: PersonTypeDto = {
  id: 0,
  description: '',
};


const PersonTypeAutocomplete = ({
  idSelected,
  idPersonType,
  onChange,
  isRequired,
  isOutlined,
  disabled,
  error,
  textHelp
}: IPersonTypeAutocompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<PersonTypeDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
        const result = await getPersonTypeApi();
        setOptions(result);
    };
    callApi();
  }, [idPersonType]);

  const getOptionSelected = (): PersonTypeDto => {
    if (idSelected) {
      const selected = options.find((p) => p.id === idSelected);
      return selected ? selected : defaultcity;
    }
    return defaultcity;
  };

  const handlerOnChange = (e: any, value: any | null) => {
    if (value) {
      onChange(value as PersonTypeDto);
    }
  };

  return (
    <AutocompleteStyled
      disabled={disabled}
      isOutlined={isOutlined}
      error={error}
      isRequired={isRequired}
      options={options}
      value={getOptionSelected()}
      onChange={handlerOnChange}
      label={t('components.personTypeAutocomplete.label')}
      getOptionLabel={(option: any) => option.description}
      renderInput={() => <></>}
      textHelp={textHelp}
    />
  );
};

export default PersonTypeAutocomplete;
