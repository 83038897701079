import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import { PagePrincipal, TreeViewMap } from '../../containers';


const MapSite = () => {
  const { t } = useTranslation();
 
  return (
    <PagePrincipal title={t('pages.mapSite.title')}>
      <Container>
        <TreeViewMap />
      </Container>
    </PagePrincipal>
  );
};
export default MapSite;
