import React, { useEffect, useState } from 'react';
import { getDeparmentsApi } from '../../../api/AdmInquiries';
import { DepartmentDto } from '../../../dto/admin/types';
import { useTranslation } from 'react-i18next';
import { AutocompleteStyled } from '../../../components';

interface IDepartmentAutoCompleteProps {
  idCountry?: string;
  idSelected?: string;
  error?: string;
  onChange: (value: DepartmentDto) => void;
  isOutlined? :boolean;
  textHelp?: string;
}

const defaultDeparment: DepartmentDto = {
  id: '',
  name: '',
  countryId: '',
};

const DepartmentAutocomplete = ({
  idSelected,
  idCountry,
  onChange,
  error,
  isOutlined,
  textHelp,
}: IDepartmentAutoCompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<DepartmentDto[]>([]);

  useEffect(() => {
    const callApi = async () => {
      if (idCountry) {
        const result = await getDeparmentsApi(idCountry!);
        setOptions(result);
      }
    };
    callApi();
  }, [idCountry]);

  const getOptionSelected = (): DepartmentDto => {
    if (idSelected) {
      const selected = options.find((p) => p.id === idSelected);
      return selected ? selected : defaultDeparment;
    }
    return defaultDeparment;
  };

  const handlerOnChange = (e: any, value: any | null) => {
    if (value) {
      onChange(value as DepartmentDto);
    }
  };

  return (
    <AutocompleteStyled
      isOutlined={isOutlined}
      error={error}
      options={options}
      label={t('components.departmentAutocomplete.label')}
      value={getOptionSelected()}
      onChange={handlerOnChange}
      getOptionLabel={(option: any) => option.name}
      renderInput={() => <></>}
      textHelp={textHelp}
    />
  );
};

export default DepartmentAutocomplete;
