import { getData } from '../_base';
import { API_ECM } from '../constants';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import { getCurrentLanguageDto } from '../../utilitys/language';

const base = `${API_ECM}/v2/MetaData`;

const url = {
  getMetaData: `${base}/GetMetaDataConfig`,
};

export const getConfigurationMetaData = async (
  documentTypeId: number
): Promise<MetaDataConfigurationDocumentaryTypeDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getMetaData}/${lngDto.id}/${documentTypeId}`;
  const result = await getData<MetaDataConfigurationDocumentaryTypeDto[]>(_url);
  if (result && !result.hasError && result.data) return result.data;
  else return [];
};
