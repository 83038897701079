import { ContextAppDto } from '../dto/ContextAppDto';

export const defaultContextsApp: ContextAppDto = {
  language: 'es',
  icoUrl:"",
  name: 'Sede Electronica',
  layout: 2,
  topBarHeader: {
    isActive: true,
    urlLink: 'https://www.gov.co/home/',
    urlImg: '/logohead.png',
    color: '#36c',
  },
  urlLogoHeader: '/logo.png',
  languageDefault: 'ES',
  imagesHome: [
    { url: '/images/slide/1.png', title: '' },
    { url: '/images/slide/2.png', title: '' },
    { url: '/images/slide/3.jpg', title: '' },
  ],
  relayCenter: {
    show: true,
    urlImg: '',
    urlLink: 'https://centroderelevo.gov.co/632/w3-channel.html'
  },
  colors: {}
};
