import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { INavigationItem } from '../../navigation/types';
import navigationConfig from '../../navigation';
import { useTranslation } from 'react-i18next';
import { Icon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { hasUserAccessMenu } from '../../utilitys/security';
import { userContext } from '../../hooks/contexts/userContext';
import { KeyCodes } from '../../constants/KeyCode';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  url?: string;
  labelIcon: any;
  labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.primary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const history = useHistory();
  const { labelText, labelIcon: LabelIcon, color, bgColor, url, ...other } = props;

  const onKeyDownItem = (url?: string) => (e: any) => {
    if (url && e.keyCode === KeyCodes.Enter) history.push(`/${url}`);
  }

  const onClickItem = (url?: string) => (e: any) => {
    if (url === '/') history.push(url);
    else history.push(`/${url}`);
  }

  return (
    <TreeItem
      onKeyDown={onKeyDownItem(url)}
      label={
        <div className='w-full'>
          {LabelIcon}
          {url ? (
            <span
              className='text-xl underline text-blue-700'
              onClick={onClickItem(url)}
            >
              {labelText}
            </span>
          ) : (
            <span className='text-xl'>{labelText}</span>
          )}
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: clsx(classes.root, 'rounded m-2'),
        content: clsx(classes.content, 'space-y-3'),
      }}
      tabIndex={0}
      {...other}
    />
  );
}
const TreeViewMap = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userAuth } = useContext(userContext);
  
  const hasUserAccess = (menu: INavigationItem) => {
    const _hasAccess = hasUserAccessMenu(menu, userAuth);
    if(userAuth && menu.hideOnAuthenticated) return false;
    return _hasAccess;
  };

  const buildTree = () =>
    navigationConfig.filter(x => !x.hideOnSiteMap).map((node) => {
      if (node.children) {
        return (
          <StyledTreeItem
            key={node.id}
            nodeId={node.id}
            color='white'
            bgColor={theme.palette.secondary.main}
            url={node.url || node.urlDefaultSiteMap}
            labelText={t(`navigation.${node.keyTranslate}`)}
            labelIcon={<Icon className='mr-10 pt-1'>{node.icon}</Icon>}
            >
            {node.children.filter(p => !p.hide && hasUserAccess(p)).map((child) => (
              <StyledTreeItem
              url={child.url}
              key={child.id}
              bgColor='transparent'
              nodeId={child.id}
              labelText={t(`navigation.${child.keyTranslate}`)}
              labelIcon={<Icon className='mr-10 pt-1'>{child.icon}</Icon>}
              />
              ))}
          </StyledTreeItem>
        );
      }
      return (
        <StyledTreeItem
          key={node.id}
          url={node.url}
          bgColor={theme.palette.secondary.main}
          nodeId={node.id}
          labelText={t(`navigation.${node.keyTranslate}`)}
          labelIcon={<Icon className='mr-10 pt-1'>{node.icon}</Icon>}
        />
      );
    });

  return (
    <div className='w-full flex justify-center'>
      <TreeView
        className='w-full md:w-auto'
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        {buildTree()}
      </TreeView>
    </div>
  );
};

export default TreeViewMap;
