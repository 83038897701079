import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import QontoStepIcon from './QontoStepIcon';
import { ISteppersProps } from './types';
import { useTranslation } from 'react-i18next';
import { Captcha } from '../../components';

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);

const Steppers = ({
  steps,
  onChangeStep,
  onConfirmer,
  validerCaptcha,
  currentStep,
}: ISteppersProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(0);
  const stepsTitles = steps.map((p) => p.title);
  const [isCaptchaOk, setIsCaptchaOk] = useState<boolean>(false);
  const getStepTitle = (step: number) => steps[step]?.title;
  const getStepContent = (step: number) => steps[step]?.body;
  const isLastStep = () => activeStep === steps.length - 1;
  
  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  const handleOnChangeCaptcha = async (isValide: boolean) =>
    setIsCaptchaOk(isValide);

  const handleNext = () => {
    setIsCaptchaOk(false);
    onChangeStep(activeStep, activeStep + 1)
  };

  const handleBack = () => {
    setIsCaptchaOk(false);
    onChangeStep(activeStep, activeStep - 1);
  };

  const renderButtonConfirmer = () => (
    <Button
      type='button'
      fullWidth
      variant='contained'
      color='primary'
      onClick={onConfirmer}
    >
      {t('pages.user.register.save')}
    </Button>
  );

  return (
    <div className={classes.root}>
      <div className='flex justify-center mb-4'>
        <span className='text-2xl text-center'>{getStepTitle(activeStep)}</span>
      </div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {stepsTitles.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div className='w-full'>
          {getStepContent(activeStep)}

          {validerCaptcha && isLastStep() && (
            <div className='w-full flex justify-center mt-4'>
              <Captcha onChange={handleOnChangeCaptcha} />
            </div>
          )}

          <div className='flex space-x-5 mt-4'>
            <div className='w-1/2'>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant='outlined'
                fullWidth
              >
                {t('components.stepper.back')}
              </Button>
            </div>
            <div className='w-1/2'>
              {isLastStep() ? (
                <div>
                  {validerCaptcha
                    ? isCaptchaOk && renderButtonConfirmer()
                    : renderButtonConfirmer()}
                </div>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleNext}
                  fullWidth
                >
                  {t('components.stepper.next')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steppers;
